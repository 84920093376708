<!--
  Main simulation page
-->

<template>
  <div class="pa-10">
    <v-card elevation="0" height="850px">
      <div class="mb-5">
        <v-row class="mb-n12">
          <v-col md="6">
            <h1 :style="{ color: $vuetify.theme.themes.light.primary }">
              {{ "Paramétrage de la ligne " + route.name + ", " + day_type + ", " + map_direction[direction_param] }}
            </h1>
          </v-col>
          <v-spacer></v-spacer>
          <v-col md="2">
            <v-select
              outlined
              label="Direction"
              v-model="direction_param"
              :items="directions"
              item-text="text"
              item-value="value"
              @change="updateGraphData"
            ></v-select>
          </v-col>
          <v-col>
            <launch-simulation-dialog />
          </v-col>
        </v-row>
        <h3>{{ titleSuffixWithDates }}</h3>
      </div>
      <travel-time-param />
      <headway-param />
    </v-card>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions, mapGetters } from "vuex";
import HeadwayParam from "./headway_param.vue";
import TravelTimeParam from "./travel_time_param.vue";
import LaunchSimulationDialog from "./launch_simulation_dialog.vue";

export default Vue.component("simulation-parameters", {
  components: {
    HeadwayParam,
    TravelTimeParam,
    LaunchSimulationDialog
  },
  data: () => ({
    directions: [
      { text: "Aller", value: 0 },
      { text: "Retour", value: 1 }
    ],
    map_direction: {
      0: "Aller",
      1: "Retour"
    }
  }),
  computed: {
    ...mapState("capucine_simulation", ["day_type"]),
    ...mapGetters("capucine_simulation", ["route", "titleSuffixWithDates"]),
    direction_param: {
      get() {
        return this.$store.state.capucine_simulation.direction_param;
      },
      set(value) {
        this.setSimulationDirection(value);
      }
    }
  },
  methods: {
    ...mapActions("capucine_simulation", ["setSimulationDirection", "updateGraphData"])
  }
});
</script>
