<!--
    Decide which icon should display depending on property values
-->

<template>
  <div>
    <v-dialog :value="display" max-width="600px" @click:outside="closeDialog()">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ $t("trace.icon_rules.title") }}
        </v-card-title>
        <v-card-text></v-card-text>
        <v-card-text>
          <p>{{ $t("trace.icon_rules.intro1") }}</p>
          <p>{{ $t("trace.icon_rules.intro2") }}</p>
          <p>{{ $t("trace.icon_rules.intro3") }}</p>
        </v-card-text>
        <!-- Select agent type and one of its numeric properties -->
        <v-card-actions>
          <v-col cols="12" sm="4">
            <v-select
              :items="agentTypeList"
              v-model="agentTypeLocal"
              @change="
                agentProp = null;
                changes += 1;
              "
              :label="$t('trace.icon_rules.type')"
            >
            </v-select>
          </v-col>
          <v-col cols="12" sm="5">
            <v-select
              :items="agentTypeNumericProps"
              v-model="agentProp"
              @change="changes += 1"
              :label="$t('trace.icon_rules.property')"
            >
            </v-select>
          </v-col>
          <v-col cols="12" sm="1">
            <v-btn icon rounded @click="increaseNbRules">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="1">
            <v-btn icon rounded :disabled="nb_rules <= 0" @click="decreaseNbRules">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </v-col>
        </v-card-actions>
        <!-- Define intervals of values for which the agent symbol will change -->
        <v-card-text v-for="range in nb_rules" :key="range">
          {{ $t("trace.icon_rules.range") + " n°" + range }}
          <v-row>
            <v-col>
              <v-text-field
                v-model="rules[range - 1]['start']"
                type="number"
                @change="changes += 1"
                :label="$t('trace.icon_rules.start')"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="rules[range - 1]['end']"
                type="number"
                @change="changes += 1"
                :label="$t('trace.icon_rules.end')"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-model="rules[range - 1]['icon']"
                :items="icons"
                @change="changes += 1"
                :label="$t('trace.icon_rules.icon')"
              >
              </v-select>
            </v-col>

            <v-col>
              <v-card-actions>
                <v-btn
                  dark
                  depressed
                  large
                  :outlined="rules[range - 1]['color'] == undefined"
                  :color="rules[range - 1]['color'] || '#000000'"
                  @click="editColor(range)"
                >
                  {{ colorText(range) }}
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()">
            {{ $t("basic_dialogs.cancel") }}
          </v-btn>
          <v-btn color="primary" text :disabled="invalidRules" @click="addRangeRule" @change="changes += 1">
            {{ $t("basic_dialogs.add") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :value="colorDialog"
      @click:outside="
        colorDialog = false;
        editedRange = null;
      "
      max-width="400px"
    >
      <v-card>
        <v-card-actions class="justify-center">
          <v-color-picker :value="editedColor" @input="changeColor($event)"> </v-color-picker>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { ICONS } from "@/global";
import { hexToRGB, add_icons_to_map } from "@/functions-tools";
import { mapState, mapActions } from "vuex";

export default Vue.component("relative-trace-icons", {
  components: {},

  props: {
    display: {
      type: Boolean,
      required: true
    },
    map: {}
  },

  data: function () {
    return {
      agentTypeLocal: null,
      agentProp: null,
      icons: this.computeIconList(),
      nb_rules: 0,
      rules: [],
      changes: 0,
      colorDialog: false,
      editedRange: null
    };
  },
  computed: {
    ...mapState("traces", ["agentTypeList", "allPoints"]),
    editedColor() {
      if (this.editedRange == null || this.nb_rules == 0) {
        return "#000000";
      } else {
        return this.rules[this.editedRange - 1]["color"];
      }
    },
    agentTypeNumericProps() {
      if (this.agentTypeLocal == null) {
        return [];
      }
      let numeric_props = [];
      for (let i = 0; i < this.allPoints.features.length; i++) {
        let f = this.allPoints.features[i];
        if (f.properties.agent_type == this.agentTypeLocal) {
          for (let key in f.properties.information) {
            if (typeof f.properties.information[key].values[0] == "number") {
              numeric_props.push(key);
            }
          }
          break;
        }
      }
      return numeric_props;
    },
    invalidRules() {
      if (this.changes == 0 || this.nb_rules == 0) {
        return true;
      }

      let fields = [this.agentTypeLocal, this.agentProp];

      for (let i = 0; i < this.rules.length; i++) {
        let rule = this.rules[i];
        fields.push(rule.start);
        fields.push(rule.end);
        if (rule.icon == "Original" && rule.color == undefined) {
          return true;
        }
      }
      let empty_fields = fields.filter(value => {
        return [null, undefined, ""].includes(value);
      });
      return empty_fields.length != 0;
    }
  },
  methods: {
    ...mapActions("traces", ["setIconRule"]),
    increaseNbRules() {
      this.rules.push({
        start: null,
        end: null,
        icon: "Original",
        color: undefined
      });
      this.nb_rules += 1;
    },
    decreaseNbRules() {
      this.nb_rules = this.nb_rules - 1;
      this.rules.pop();
      if (this.nb_rules == 0) {
        this.editedRange = null;
      }
    },
    addRangeRule() {
      // set the run on the agent icon
      this.setIconRule({
        key: this.agentTypeLocal,
        value: { prop: this.agentProp, rules: this.rules }
      });

      // add the relevant icons to the map
      this.addRuleIcons();

      // reset the rules
      this.agentTypeLocal = null;
      this.agentProp = null;
      this.nb_rules = 0;
      this.rules = [];
      this.changes = 0;
      this.editedRange = null;

      // close dialog
      this.closeDialog();
    },
    addRuleIcons() {
      let agentTypeLocalIcons = [];

      for (let k = 0; k < this.allPoints.features.length; k++) {
        let f = this.allPoints.features[k];
        if (
          f.properties.agent_type == this.agentTypeLocal &&
          !agentTypeLocalIcons.includes(f.properties.icon_type_save)
        ) {
          agentTypeLocalIcons.push(f.properties.icon_type_save);
        }
      }

      agentTypeLocalIcons = [...new Set(agentTypeLocalIcons)];

      let coloredIcons = [];

      let rule;
      for (let i = 0; i < this.rules.length; i++) {
        rule = this.rules[i];
        if (rule.color != undefined) {
          if (rule.icon == "Original") {
            for (let j = 0; j < agentTypeLocalIcons.length; j++) {
              coloredIcons.push([agentTypeLocalIcons[j], rule.color]);
            }
          } else {
            coloredIcons.push([rule.icon, rule.color]);
          }
        }
      }
      add_icons_to_map(this.map, coloredIcons);
    },
    closeDialog() {
      this.$emit("handleUpdateDialog", false);
    },
    computeIconList() {
      let icon_list = Object.keys(ICONS);
      icon_list.unshift("Original");
      return icon_list;
    },
    // color edition
    editColor(range) {
      if (this.rules[range - 1].color == undefined) {
        this.rules[range - 1].color = "#000000";
      }
      this.colorDialog = true;
      this.editedRange = range;
    },
    changeColor(value) {
      this.rules[this.editedRange - 1].color = value;
      this.changes += 1;
    },
    hexToRGB(hex) {
      return hexToRGB(hex);
    },
    colorText(range) {
      if (this.rules[range - 1].color == undefined) {
        return "Original";
      } else {
        return this.rules[range - 1].color;
      }
    }
  }
});
</script>
