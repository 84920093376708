<!--
  Mixin for layer mapping editors
-->

<script lang="ts">
import { mandatoryRule, positiveRule, isNumber, isBetween0And1 } from "@/validation";

export default {
  props: {
    propsMapping: {
      required: true
    },
    dataProperties: {
      required: true
    }
  },
  data: function () {
    return {
      editionIsValid: null,
      local_options: null,
      size_text_field_rules: [isNumber, mandatoryRule, positiveRule],
      opacity_text_field_rules: [isNumber, isBetween0And1, mandatoryRule]
    };
  },
  watch: {
    propsMapping: {
      handler(new_value) {
        this.local_options = this.copyValuesFromMapping(new_value);
      },
      immediate: true
    }
  },

  computed: {
    dataPropertiesItems() {
      return Object.keys(this.dataProperties).map(key => {
        return { text: this.dataProperties[key], value: key };
      });
    },
    options() {
      return this.propsMapping.mapping_options;
    },
    paintType() {
      return this.propsMapping.paint_type;
    }
  },
  methods: {
    update() {
      if (this.$refs.edit_form && !this.$refs.edit_form.validate()) {
        return;
      }
      try {
        let updated_mapping = this.getUpdatedPropsMapping();
        this.$emit("update", updated_mapping);
      } catch (e) {
        let message = e.message;
        alert({ message, type: "error" });
      }
    },
    copyValuesFromMapping(props_mapping) {
      return props_mapping.getOptionsCopy();
    },
    getUpdatedPropsMapping() {
      let props_mapping = this.propsMapping;
      props_mapping.setMappingValues(this.local_options);
      return props_mapping;
    },
    mandatoryRule,
    positiveRule,
    isNumber
  }
};
</script>
