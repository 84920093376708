<template>
  <div>
    <kite-app-bar logo="/static/logo-kite.png">
      <template v-slot:leftAction>
        <v-tooltip slot="append" bottom>
          <template v-slot:activator="{ on }" v-slot:leftAction>
            <v-btn v-on="on" dark icon :disabled="!isMapFullyLoaded(map)" @click="mainDrawerOpen = !mainDrawerOpen"
              ><v-icon>menu</v-icon></v-btn
            >
          </template>
          <span v-if="mainDrawerOpen"> {{ $t("ux_display.hideNavigationPanel") }} </span>
          <span v-if="!mainDrawerOpen"> {{ $t("ux_display.showNavigationPanel") }} </span>
        </v-tooltip>
      </template>

      <template v-slot:title>
        <span class="hidden-sm-and-down"> KITE </span>
      </template>
      <template v-slot:rightAction1>
        <tools-dialog />
      </template>
    </kite-app-bar>
    <!-- fix width in pixel in order to have the same width in every screen -->
    <kite-drawer>
      <v-tabs
        v-model="mainDrawerTab"
        background-color="primary"
        class="elevation-2"
        dark
        grow
        :slider-size="SLIDER_SIZE"
      >
        <v-tabs-slider :color="SLIDER_COLOR" />
        <v-tab :href="'#welcome'" v-show="true"> <v-icon>home</v-icon> </v-tab>
        <v-tab :href="'#layers'"> {{ $t("map_layers.name") }} </v-tab>

        <v-tab :href="'#flows'">{{ $t("flows.name") }}</v-tab>

        <v-tab :href="'#network'">{{ $t("network.name") }} </v-tab>

        <v-tab :href="'#simulation'"> {{ $t("simulations.name") }} </v-tab>

        <v-tab-item :value="'welcome'" eager>
          <welcome-tab />
        </v-tab-item>
        <v-tab-item :value="'layers'" eager> <layers-page /> </v-tab-item>
        <v-tab-item :value="'flows'" eager><flows-page /></v-tab-item>
        <v-tab-item :value="'network'" eager> <network-page /> </v-tab-item>
        <v-tab-item :value="'simulation'" eager>
          <simulation-page />
        </v-tab-item>
      </v-tabs>
    </kite-drawer>
    <v-main>
      <v-progress-linear
        :color="$vuetify.theme.themes.light.secondary"
        indeterminate
        height="7px"
        v-if="async.generic"
      ></v-progress-linear>
      <v-container class="pa-0 ma-0 .noscroll" fluid>
        <!-- add map with initialised white background and glyphs for symbols used in animation -->
        <KiteMap
          :loading="async.overlay > 0"
          @map:initialised="mapInitialized"
          @map:loaded="mapLoaded"
          geocoder-control
          pitch-control
          map-export-control
        />
        <!-- div map -->
        <div class="navi">
          <legends />
          <timeline v-if="isMapFullyLoaded(map)" :map="map" :key="timeline_key" />
          <agent-planning :map="map" />
          <about :dialog="about_dialog" @handleUpdateDialog="about_dialog = $event" />
          <my-settings :dialog="my_settings_dialog" @handleUpdateDialog="my_settings_dialog = $event" />
          <kite-alert />
          <kite-map-action-dialog />
        </div>
      </v-container>
      <v-progress-linear
        style="position: fixed; bottom: 0px; left: 0px; right: 0px"
        :color="$vuetify.theme.themes.light.secondary"
        indeterminate
        height="7px"
        v-if="async.generic"
      ></v-progress-linear>
      <div class="mapTitle">{{ printMapTitle }}</div>
    </v-main>
  </div>
</template>

<script>
import KiteMap from "@/components/base/kite_map.vue";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { mapActions, mapState, mapGetters } from "vuex";
import { SLIDER_SIZE, SLIDER_COLOR } from "@/global";
import { setup_kite_layers, add_draw_control } from "@/kite_layers";
import LayersPage from "@/components/kite/layers/layers_page.vue";
import AgentPlanning from "@/components/kite/trace/agent_planning.vue";
import Timeline from "@/components/kite/trace/timeline.vue";
import Legends from "@/components/kite/legends/legends.vue";
import KiteAppBar from "@/components/base/kite_app_bar.vue";
import KiteAlert from "@/components/base/kite_alert.vue";
import SimulationPage from "@/components/kite/simulation/simulation_page.vue";
import FlowsPage from "@/components/kite/flows/flows_page.vue";
import NetworkPage from "@/components/kite/network/network_page.vue";
import WelcomeTab from "@/components/kite/base/welcome_tab.vue";
import KiteMapActionDialog from "@/components/kite/map_actions/kite_map_action_dialog.vue";
import ToolsDialog from "@/components/kite/tools/tools_dialog.vue";
import KiteDrawer from "@/components/kite/base/kite_drawer.vue";

export default {
  name: "Main",
  components: {
    KiteMap,
    KiteAppBar,
    KiteAlert,
    AgentPlanning,
    Timeline,
    Legends,
    WelcomeTab,
    FlowsPage,
    NetworkPage,
    SimulationPage,
    LayersPage,
    KiteMapActionDialog,
    ToolsDialog,
    KiteDrawer
  },
  provide: function () {
    return {};
  },
  data: () => ({
    SLIDER_SIZE,
    SLIDER_COLOR,
    // component update keys
    keys: {
      od_plots: 2000
    },
    // layers for menu
    // map object
    map: null,
    test: null,
    overlay: true,
    error: "",
    about_dialog: false,
    my_settings_dialog: false,
    name: ""
  }),
  computed: {
    ...mapState(["async"]),
    ...mapState("layers", ["printMapTitle"]),
    ...mapState("simulations", ["timeline_key"]),
    ...mapGetters("layers", ["getLayer"]),
    mainDrawerOpen: {
      get() {
        return this.$store.state.mainDrawerOpen;
      },
      set(value) {
        this.$store.commit("UPDATE_MAIN_DRAWER", value);
      }
    },
    mainDrawerTab: {
      get() {
        return this.$store.state.mainDrawerTab;
      },
      set(value) {
        this.$store.commit("UPDATE_MAIN_DRAWER_TAB", value);
      }
    }
  },
  created() {
    if (this.$route.query.overlay === undefined) {
      this.overlay = true;
    } else {
      this.overlay = this.$route.query.overlay === "false";
    }

    // check screen size and show dialog if needed
    let window_width = window.innerWidth;
    let window_height = window.innerHeight;
    if (window_width <= 1800 || window_height <= 900) {
      let message =
        (window_width <= 1000 ? this.$t("warnings.screen_size_small") : this.$t("warnings.screen_size_medium")) +
        " " +
        this.$t("warnings.burger_recommandation");
      alert({
        message,
        type: "warning",
        timeout: -1
      });
    }
  },
  mounted() {
    this.$whale.user._features = [
      "CAPUCINE_TELLAE",
      "FLOWS",
      "NETWORK",
      "SIMULATION_ODT",
      "SIMULATION_SB_VS",
      "SIMULATION_FF_VS",
      "LAYERS",
      "PROJECT"
    ];
    this.updateApiAvailability();
    this.updateSummaryZoning();
  },
  methods: {
    ...mapActions("flows", ["updateSummaryZoning"]),
    ...mapActions(["asyncEnd", "updateApiAvailability"]),
    // init map
    mapInitialized(map) {
      this.map = map;

      // Reset to user last position
      this.map.flyTo({
        pitch: this.$store.state.map.pitch,
        zoom: this.$store.state.map.zoom,
        center: this.$store.state.map.center
      });
      // save map user configuration
      this.map.on("zoomend", () => {
        this.$whale.updateMapPreferences({ zoom: map.getZoom() });
      });
      this.map.on("moveend", () => {
        this.$whale.updateMapPreferences({ center: map.getCenter() });
      });
      this.map.on("pitchend", () => {
        this.$whale.updateMapPreferences({ pitch: map.getPitch() });
      });

      // remember when map is moved for database layers update
      this.map.on("moveend", () => {
        this.$store.commit("layers/SET_MAP_MOVED", true);
      });

      // check if vector flow_selection layer is loaded
      let vm = this;
      map.on("idle", function (e) {
        if (typeof map.getLayer("flow_selection") !== "undefined") {
          vm.$store.state.overlayZoningSelection = false;
        }
      });
    },
    // on map loaded
    async mapLoaded(map) {
      // setup kite layers
      await setup_kite_layers(map);

      // add draw control
      add_draw_control(map);

      // end global loading
      this.asyncEnd({ type: "overlay" });

      this.refresh_map(); // used for removing grey area when switching from Capucine
    },
    // is map fully loaded ?
    // return a boolean
    // used for activating menus
    isMapFullyLoaded(map) {
      if (map !== null) {
        return map.style._loaded;
      } else {
        return false;
      }
    },
    // Log the user out
    logout() {
      this.$whale.logout();
    },
    refresh_map() {
      // detect the map's new width and height and resize it
      this.map.resize();
    }
  }
};
</script>

<style>
.footer {
  color: white;
  text-align: center;
}

.v-overlay__scrim {
  background-color: #3d6482 !important;
}

.full {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.button3D {
  text-decoration: line-through red;
}

@media screen {
  div.mapTitle {
    display: none;
  }
}

/* configuration for print */
@media print {
  /* remove all elements, then keep map */
  body * {
    visibility: hidden;
  }
  /* remove controls */
  .mapboxgl-ctrl-group {
    display: none !important;
  }
  /* remove mapbox logo */
  .mapboxgl-ctrl-logo {
    display: none !important;
  }
  @page {
    size: A3 landscape;
    margin: 1.5cm 0.5cm 1cm 0.5cm;
  }
  #main-map,
  #main-map * {
    visibility: visible;
    /*transform: translateX(-30mm);*/
  }

  .mapboxgl-canvas {
    /*width: 100% !important;*/
  }

  div.mapTitle {
    position: fixed;
    top: 10px;
    left: 10px;
    visibility: visible;
    font-size: 24px;
    font-weight: bold;
  }
}
</style>

<style scoped>
::v-deep ::-webkit-scrollbar {
}
</style>
