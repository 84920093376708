<!--
  Chart and table for travel time
-->

<template>
  <div>
    <kpi-card title="Temps de parcours" :suffix="titleSuffixWithDates" :subtitle="subtitle">
      <v-overlay opacity="0.5" color="grey lighten-1" absolute :value="loading">
        <div class="d-flex flex-column align-center">
          <h1>Chargement</h1>
          <v-progress-linear color="primary" indeterminate></v-progress-linear>
        </div>
      </v-overlay>

      <div v-if="data === undefined"><b>Pas de données</b></div>
      <div v-else>
        <v-card-actions>
          <v-row>
            <v-col cols="2">
              <v-select
                prepend-icon="tune"
                label="Direction"
                v-model="travelTimeDirection"
                :items="directions"
                item-text="text"
                item-value="value"
                :disabled="loading"
                :loading="loading"
                @change="update"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-select label="Représentation" v-model="representation" :items="representations"></v-select>
            </v-col>
            <v-col cols="8">
              <travel-time-section
                :loading="loading"
                :direction="travelTimeDirection"
                :origin="travel_time_origin"
                :destination="travel_time_destination"
                @update="updateSelectedSection"
              />
              <travel-time-filter @update="fetchTravelTime" :loading="loading" />
              <travel-time-buffers
                @update="updateBuffers"
                @update:realised="updateRealisedPercentage"
                @update:theoretical="updateTheoreticalPercentage"
                @cancel="cancelBufferUpdate"
                :loading="loading"
                :buffers="travel_time_buffers"
              />
            </v-col>
          </v-row>
        </v-card-actions>
        <div v-if="representation == 'Graphique'">
          <travel-time-plots :plots="plots" :title="plotsTitle" />
        </div>
        <div v-if="representation == 'Tableau'" align="center">
          <capucine-table
            :headers="headers"
            :items="table_data"
            :loading="loading"
            :title="OdTitle"
            :card-width="table_width"
            :tableProps="{ dense: true, 'fixed-header': true, height: 475 }"
            :exportFileName="export_table_filename"
          >
          </capucine-table>
        </div>
      </div>
    </kpi-card>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions, mapGetters } from "vuex";
import {
  CHART_OPTIONS_BASE,
  CHART_OPTIONS_TRAVEL_TIME,
  filterEmptyXAndAddLabels,
  createTravelTimePlots,
  buffered_travel_time,
  getPlotName,
  transformTableKeys,
  transformPlotKeys,
  removeFewCountsTableValues,
  removeFewCountsGraphicValues,
  getDefaultTravelTimeBuffers
} from "@/capucine_utils";
import AnalysisKpiMixin from "./analysis_kpi_mixin.vue";
import TravelTimePlots from "./travel_time/travel_time_plots.vue";
import TravelTimeSection from "./travel_time/travel_time_section.vue";
import TravelTimeFilter from "./travel_time/travel_time_filters.vue";
import TravelTimeBuffers from "./travel_time/travel_time_buffers.vue";

const plot_order = [
  "mesures",
  "mesures_non",
  "theo_contractuel",
  "theo",
  "realise",
  "realise_median",
  "buffered_pX",
  "buffered_realise",
  "buffered_theo",
  "buffered_std"
];

export default Vue.component("travel_time", {
  components: { TravelTimePlots, TravelTimeSection, TravelTimeFilter, TravelTimeBuffers },
  mixins: [AnalysisKpiMixin],
  data: () => ({
    kpi_name: "travel_time",
    representation: "Graphique",
    representations: ["Graphique", "Tableau"],
    plots_data: {},
    table_data: [],
    proportion_validated: null,
    travel_time_plots_visibility: {
      mesures: true,
      mesures_non: "legendonly",
      theo_contractuel: "legendonly",
      theo: true,
      realise: true,
      realise_median: "legendonly",
      buffered_theo: "legendonly",
      buffered_realise: "legendonly",
      buffered_std: "legendonly",
      buffered_pX: "legendonly"
    }
  }),
  computed: {
    ...mapState("capucine_analysis", {
      points_data: (state: any) => state.data.travel_time_points,
      travel_time_origin: (state: any) => state.travel_time.section.origin,
      travel_time_destination: (state: any) => state.travel_time.section.destination,
      travel_time_buffers: (state: any) => state.travel_time.buffers,
      travel_time_plots: (state: any) => state.travel_time.plots
    }),
    ...mapGetters("capucine_analysis", ["titleSuffixWithDates"]),
    travelTimeDirection: {
      get() {
        return this.$store.state.capucine_analysis.travel_time.direction;
      },
      set(val) {
        this.$store.commit("capucine_analysis/SET_TRAVEL_TIME_DIRECTION", val);
      }
    },
    OdTitle() {
      if (this.travelTimeDirection == "0") {
        return this.sectionsNames[this.travel_time_origin] + " → " + this.sectionsNames[this.travel_time_destination];
      } else {
        return this.sectionsNames[this.travel_time_destination] + " → " + this.sectionsNames[this.travel_time_origin];
      }
    },
    plotsTitle() {
      let proportion_text;
      if (this.proportion_validated) {
        proportion_text = (100 * this.proportion_validated).toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 1
        });
        proportion_text = `(${proportion_text}% de mesures retenues)`;
      } else {
        proportion_text = "(Pas de données)";
      }
      return this.OdTitle + "<br><sup>" + proportion_text + "</sup>";
    },
    headers() {
      let headers = [
        { text: "Tranche horaire", value: "période", align: "left", width: "15%" },
        this.travelTimeDataHeader("theo"),
        this.travelTimeDataHeader("realise"),
        { text: "Réalisé - Théorique", value: "écart", align: "right", format: "-", width: "10%" },
        this.travelTimeDataHeader("realise_median"),
        this.travelTimeDataHeader("buffered_pX"),
        this.travelTimeDataHeader("buffered_realise"),
        this.travelTimeDataHeader("buffered_theo"),
        this.travelTimeDataHeader("buffered_std")
      ];

      if (this.hasContractualTimes) {
        headers.push(this.travelTimeDataHeader("theo_contractuel"));
        headers.push({
          text: "Réalisé - Théorique contractuel",
          value: "écart_contractuel",
          align: "right",
          format: "-",
          width: "22%"
        });
      }
      return headers;
    },
    table_width() {
      let table_width = "100vh";
      if (this.hasContractualTimes) {
        table_width = "130vh";
      }
      return table_width;
    },
    plots() {
      return plot_order.map(plot_key => this.travel_time_plots[plot_key]).filter(v => !!v);
    },
    hasContractualTimes() {
      return Object.keys(this.table_data[0]).includes("écart_contractuel");
    }
  },
  mounted() {
    if (this.data !== undefined) {
      if (Object.keys(this.data["graphic"]["0"]).length > 0) {
        this.update();
      }
    }
  },
  watch: {
    data() {
      this.update();
    },
    route() {
      this.restoreBaseBufferParams();
    },
    day_type: {
      handler() {
        this.restoreBaseBufferParams();
      },
      immediate: true
    },
    lot() {
      this.restoreBaseBufferParams();
    }
  },
  methods: {
    ...mapActions("capucine_analysis", ["getTravelTime", "updateSavedFilter"]),
    updateSelectedSection(new_stops) {
      this.$store.commit("capucine_analysis/SET_TRAVEL_TIME_SECTION", new_stops);
      this.updateSavedFilter();
      this.fetchTravelTime();
    },
    fetchTravelTime() {
      this.saveVisible();
      this.getTravelTime();
    },
    updateBuffers(new_buffers) {
      let saved_params = JSON.parse(JSON.stringify(this.travel_time_buffers));
      this.$store.commit("capucine_analysis/SET_TRAVEL_TIME_BUFFERS", new_buffers);
      // call api only if change in pX
      if (
        new_buffers.pX !== saved_params.pX ||
        new_buffers.std_3_6 !== saved_params.std_3_6 ||
        new_buffers.std_6_10 !== saved_params.std_6_10 ||
        new_buffers.std_10_more !== saved_params.std_10_more
      ) {
        this.fetchTravelTime();
      }
      this.updateRealisedPercentage(this.travel_time_buffers.percentage);
      this.updateTheoreticalPercentage(this.travel_time_buffers.percentage_theoretical);
    },
    cancelBufferUpdate() {
      this.updateRealisedPercentage(this.travel_time_buffers.percentage);
      this.updateTheoreticalPercentage(this.travel_time_buffers.percentage_theoretical);
    },
    updateRealisedPercentage(value) {
      this.update_buffer_plot("buffered_realise", value);
    },
    updateTheoreticalPercentage(value) {
      this.update_buffer_plot("buffered_theo", value);
    },
    saveVisible() {
      let plot;
      for (let plot_key of plot_order) {
        plot = this.travel_time_plots[plot_key];
        if (plot) {
          this.travel_time_plots_visibility[plot_key] = plot.visible;
        }
      }
    },
    readDisplayedData() {
      // get table data
      let table_data = transformTableKeys(this.data.table[this.travelTimeDirection], this.travel_time_buffers);
      removeFewCountsTableValues(table_data);

      // get labels and travel time values. Ignore points where all travel time values are empty
      let plots_data = transformPlotKeys(this.data.graphic[this.travelTimeDirection], this.travel_time_buffers);
      plots_data = filterEmptyXAndAddLabels(plots_data);
      removeFewCountsGraphicValues(plots_data);

      // get travel time points
      let validated_points = this.points_data.filter(
        value => value.validé == true && value.direction == this.travelTimeDirection
      );
      plots_data["mesures_x"] = validated_points.map(x => x.réalisé / 3600);
      plots_data["mesures_y"] = validated_points.map(x => x.temps_réalisé / 60);

      let non_validated_points = this.points_data.filter(
        value => value.validé == false && value.direction == this.travelTimeDirection
      );
      plots_data["mesures_non_x"] = non_validated_points.map(x => x.réalisé / 3600);
      plots_data["mesures_non_y"] = non_validated_points.map(x => x.temps_réalisé / 60);

      const all_points = this.points_data.filter(value => value.direction == this.travelTimeDirection);
      this.proportion_validated = all_points.length > 0 ? validated_points.length / all_points.length : null;

      // evaluate buffered data
      plots_data["buffered_realise"] = plots_data["realise"].map(v =>
        buffered_travel_time(v, this.travel_time_buffers.percentage)
      );
      plots_data["buffered_theo"] = plots_data["theo"].map(v =>
        buffered_travel_time(v, this.travel_time_buffers.percentage_theoretical)
      );

      for (let i = 0; i < this.table_data.length; i++) {
        table_data[i]["buffered_realise"] = buffered_travel_time(
          table_data[i]["realise"],
          this.travel_time_buffers.percentage
        );
        table_data[i]["buffered_theo"] = buffered_travel_time(
          table_data[i]["theo"],
          this.travel_time_buffers.percentage_theoretical
        );
      }
      this.table_data = table_data;
      this.plots_data = plots_data;
    },
    update_buffer_plot(plot_key: string, buffer: number) {
      // update buffer percentage serie with updating directly plots object used by plotly
      let plot = this.travel_time_plots[plot_key];
      if (!plot) {
        return;
      }
      let buffer_key = plot_key == "buffered_realise" ? "percentage" : "percentage_theoretical";

      plot.y = this.plots_data[plot_key].map(v => buffered_travel_time(v, buffer));
      plot.name = getPlotName(plot_key, { [buffer_key]: buffer });

      for (let i = 0; i < this.table_data.length; i++) {
        this.table_data[i][plot_key] = plot.y[i];
      }
    },

    update() {
      if (this.data !== undefined) {
        this.readDisplayedData();
        createTravelTimePlots(this.plots_data, this.travel_time_plots_visibility);
      }
    },
    travelTimeDataHeader(key) {
      return {
        text: getPlotName(key),
        value: key,
        align: "right",
        format: "",
        width: "10%"
      };
    },
    restoreBaseBufferParams() {
      this.$store.commit("capucine_analysis/SET_TRAVEL_TIME_BUFFERS", getDefaultTravelTimeBuffers());
    }
  }
});
</script>
