/* network.store.js */
import { getInstance } from "@/api/index";
import i18n from "@/plugins/lang";
import { NetworkView } from "@/models";

function networkStats(raw_statistics) {
  return [
    {
      data: raw_statistics.filter(i => i.indicator == "num_routes_active_on_sample_date")[0]["value"],
      name: i18n.t("statistics.network.num_routes")
    },
    {
      data: raw_statistics.filter(i => i.indicator == "num_trips_active_on_sample_date")[0]["value"],
      name: i18n.t("statistics.network.num_trips")
    },
    {
      data: raw_statistics.filter(i => i.indicator == "num_stops_active_on_sample_date")[0]["value"],
      name: i18n.t("statistics.network.num_stops")
    }
  ];
}

function routeStats(raw_statistics) {
  let stats = [];
  stats.push({
    data: raw_statistics["route_short_name"],
    name: i18n.t("statistics.network.route_name")
  });
  stats.push({
    data: i18n.t("network.route_type." + raw_statistics["route_type"]),
    name: i18n.t("statistics.network.route_type")
  });
  stats.push({
    data: i18n.t("boolean." + String(Boolean(raw_statistics["is_bidirectional"]))),
    name: i18n.t("statistics.network.is_bidirectional")
  });
  stats.push({
    data: raw_statistics["num_trips"],
    name: i18n.t("statistics.network.num_trips")
  });
  stats.push({
    data: raw_statistics["num_variants"],
    name: i18n.t("statistics.network.num_variants")
  });
  stats.push({
    data: raw_statistics["start_time"],
    name: i18n.t("statistics.network.start_time")
  });
  stats.push({
    data: raw_statistics["end_time"],
    name: i18n.t("statistics.network.end_time")
  });
  return stats;
}

function routeAdvancedStats(raw_statistics) {
  let advanced_stats = [];
  advanced_stats.push({
    data: Math.round(raw_statistics["mean_trip_distance"] * 10) / 10 + " km",
    name: i18n.t("statistics.network.mean_trip_distance")
  });
  advanced_stats.push({
    data: Math.round(raw_statistics["mean_trip_duration"] * 60) + " min",
    name: i18n.t("statistics.network.mean_trip_duration")
  });
  advanced_stats.push({
    data: Math.round(raw_statistics["min_headway"] * 10) / 10 + " min",
    name: i18n.t("statistics.network.min_headway")
  });
  advanced_stats.push({
    data: Math.round(raw_statistics["max_headway"] * 10) / 10 + " min",
    name: i18n.t("statistics.network.max_headway")
  });
  advanced_stats.push({
    data: Math.round(raw_statistics["service_distance"] * 10) / 10 + " km",
    name: i18n.t("statistics.network.service_distance")
  });
  advanced_stats.push({
    data: raw_statistics["service_duration"].toFixed(1) + " h",
    name: i18n.t("statistics.network.service_duration")
  });
  advanced_stats.push({
    data: Math.round(raw_statistics["service_speed"] * 10) / 10 + " km/h",
    name: i18n.t("statistics.network.service_speed")
  });
  return advanced_stats;
}

const initialState = () => ({
  dialog: {
    add: false,
    variants: false,
    isochrones: false
  },
  networkViews: [],
  currentNetworkView: null,
  nbViews: 0,
  networkRouteData: {
    travel_time: {
      0: [],
      1: []
    },
    headway: {
      0: [],
      1: []
    },
    statistics: [],
    sequence: { 0: [], 1: [] }
  },
  selectedRouteId: null,
  selectedRouteShortName: null,
  statistics: {
    tab: "tab-0",
    items: {
      network: [],
      route: [],
      advanced: []
    }
  },
  networkPlotsData: {
    theoretical_travel_time: { 0: [], 1: [] },
    real_travel_time: { 0: [], 1: [] },
    headway: { 0: [], 1: [] },
    terminus: {
      0: { start_stop: null, end_stop: null },
      1: { start_stop: null, end_stop: null }
    }
  },
  plot: {
    series: [
      {
        name: "",
        type: "line",
        data: []
      }
    ],
    options: {
      title: {
        text: "",
        align: "left"
      },
      stroke: {
        width: 2
      },
      markers: {
        size: 3,
        colors: ["#000000", "#ff0000"]
      },
      colors: ["#000000", "#ff0000"],
      xaxis: {
        type: "datetime",
        tooltip: {
          enabled: false
        }
      },
      legend: {
        y: 18
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          }
        }
      },
      tooltip: {
        shared: true,
        intersect: false,
        x: {
          format: "HH:mm"
        }
      }
    }
  },
  isochrones: {
    stop_id: null,
    stop_name: null
  },
  isAdvancedStatisticsAvailable: true,
  isRealTravelTimeAvailable: false,
  variantsTable: [],
  selectedVariants: {
    0: null,
    1: null
  }
});
const state = initialState();

export default {
  // State object
  namespaced: true,
  state,

  // Getter functions
  getters: {},
  // Actions
  actions: {
    removeNetworkView(context, network_view: NetworkView) {
      // remove view from list
      context.commit("REMOVE_NETWORK_VIEW", network_view);

      // case when selected network view is removed
      if (network_view.id == context.state.currentNetworkView.id) {
        context.dispatch("selectNetworkview", null);
      }
    },

    async selectNetworkview(context, network_view: NetworkView) {
      let whale = getInstance();

      if (network_view != null) {
        // start async action
        context.dispatch("asyncStart", "gtfsDraw", { root: true });

        try {
          // set empty geojson to make layer visible in table whie loading
          let empty_geojson = {
            type: "FeatureCollection",
            features: []
          };
          context.dispatch("layers/setLayersData", { ids: "gtfs-lines", data: empty_geojson }, { root: true });
          context.dispatch("layers/setLayersData", { ids: "gtfs-stops", data: empty_geojson }, { root: true });

          // draw network layers
          let data = await whale.getNetworkGeometry(
            network_view.gtfs_index,
            network_view.date.value,
            network_view.has_all_stops
          );

          // TODO : do something more transparent
          context.commit("RESET_STORE");

          // update network stats
          context.dispatch("updateNetworkStatistics", data.statistics);

          // setup route and stops layers
          console.log("setLayersData and setLayersVisibility");
          context.dispatch("layers/setLayersData", { ids: "gtfs-lines", data: data.lines }, { root: true });
          context.dispatch("layers/setLayersData", { ids: "gtfs-stops", data: data.stops }, { root: true });
          context.dispatch(
            "layers/setLayersVisibility",
            { ids: ["gtfs-lines", "gtfs-stops"], isVisible: true },
            { root: true }
          );

          // TODO setup map listeners here instead of in network page
        } catch (e) {
          console.log(e);
          let message = i18n.t("network.gtfs.error_loading");
          alert({ message, type: "error" });
        } finally {
          context.dispatch("asyncEnd", "gtfsDraw", { root: true });
        }
      } else {
        context.commit("RESET_STORE");

        // setup route and stops layers
        context.dispatch("layers/setLayersData", { ids: ["gtfs-lines", "gtfs-stops"], data: null }, { root: true });
        context.dispatch(
          "layers/setLayersVisibility",
          { ids: ["gtfs-lines", "gtfs-stops"], isVisible: false },
          { root: true }
        );
      }

      // update selected view
      context.commit("SELECT_NETWORK_VIEW", network_view);
    },

    async selectRoute(context, route_id) {
      let whale = getInstance();
      let selected_network = context.state.currentNetworkView.gtfs_index;
      let selected_date = context.state.currentNetworkView.date.value;

      // update store
      context.commit("UPDATE_SELECTED_ROUTE_ID", route_id);

      // stop if id is null
      if (route_id == null) {
        return;
      }

      // fetch route variants
      let variants = await whale.getNetworkRouteVariants(selected_network, selected_date, route_id);
      context.commit("UPDATE_VARIANTS_TABLE", variants);

      // fetch real travel time availability
      let real_travel_time_available = await whale.isRealTravelTimeAvailable(selected_network, selected_date, route_id);
      context.commit("UPDATE_REAL_TRAVEL_TIME_AVAILABILITY", real_travel_time_available);

      // open variants dialog
      context.commit("UPDATE_DIALOG", { dialog: "variants", value: true });
    },

    async selectRouteVariants(context, variants) {
      let whale = getInstance();

      // update store
      context.commit("UDPATE_SELECTED_VARIANTS", variants);

      // fetch and update route data
      let route_data = await whale.getNetworkRouteData(
        context.state.currentNetworkView.gtfs_index,
        context.state.currentNetworkView.date.value,
        context.state.selectedRouteId,
        JSON.stringify(variants)
      );
      context.commit("UPDATE_ROUTE_DATA", route_data);

      // update route stats
      context.dispatch("updateRouteStatistics");

      // reset plots data
      context.dispatch("resetNetworkPlotsData");
    },

    updateNetworkStatistics(context, raw_statistics) {
      let stats = networkStats(raw_statistics);
      context.commit("UPDATE_STATISTICS", { subject: "network", data: stats });
      context.commit("SET_STATS_TAB", "tab-0");
    },

    updateRouteStatistics(context) {
      let stats = [];
      let advanced_stats = [];
      if (context.state.networkRouteData.statistics.length > 0) {
        let raw_statistics = context.state.networkRouteData.statistics[0];
        context.commit("UPDATE_SELECTED_ROUTE_SHORT_NAME", raw_statistics["route_short_name"]);
        stats = routeStats(raw_statistics);
        advanced_stats = routeAdvancedStats(raw_statistics);
      }
      context.commit("UPDATE_STATISTICS", { subject: "route", data: stats });
      context.commit("UPDATE_STATISTICS", { subject: "advanced", data: advanced_stats });
      context.commit("SET_STATS_TAB", "tab-1");
    },

    resetNetworkPlotsData(context) {
      let route_data = context.state.networkRouteData;
      let new_plot_data = {};
      new_plot_data["theoretical_travel_time"] = route_data["theoretical_travel_time"];
      new_plot_data["real_travel_time"] = { 0: [], 1: [] };
      new_plot_data["headway"] = route_data["headway"];
      let sequence = route_data["sequence"];
      new_plot_data["terminus"] = {
        0: { start_stop: sequence[0][0], end_stop: sequence[0][sequence[0].length - 1] },
        1: { start_stop: sequence[1][0], end_stop: sequence[1][sequence[1].length - 1] }
      };
      context.commit("UPDATE_NETWORK_PLOTS_DATA", new_plot_data);
    },

    resetNetworkStore(context) {
      context.commit("RESET_STORE");
    }
  },
  // Mutations
  mutations: {
    RESET_STORE(state) {
      const newState = initialState();
      Object.keys(newState)
        .filter(x =>
          ["networkRouteData", "isochrones", "selectedRouteId", "isAdvancedStatisticsAvailable", "statistics"].includes(
            x
          )
        )
        .forEach(key => {
          state[key] = newState[key];
        });
    },
    UPDATE_DIALOG(state, { dialog, value }) {
      state.dialog[dialog] = value;
    },
    UPDATE_SELECTED_ROUTE_ID(state, route_id) {
      state.selectedRouteId = route_id;
    },
    UPDATE_SELECTED_ROUTE_SHORT_NAME(state, route_short_name) {
      state.selectedRouteShortName = route_short_name;
    },
    UPDATE_VARIANTS_TABLE(state, variants_table) {
      state.variantsTable = variants_table;
    },
    SET_ISOCHRONES(state, props) {
      state.isochrones.stop_id = props.stop_id;
      state.isochrones.stop_name = props.stop_name;
    },
    UPDATE_REAL_TRAVEL_TIME_AVAILABILITY(state, available) {
      state.isRealTravelTimeAvailable = available;
    },
    UPDATE_ADVANCED_STATS_AVAILABILITY(state, available) {
      state.isAdvancedStatisticsAvailable = available;
    },
    UDPATE_SELECTED_VARIANTS(state, variants) {
      state.selectedVariants = variants;
    },
    UPDATE_ROUTE_DATA(state, route_data) {
      state.networkRouteData = route_data;
    },
    UPDATE_NETWORK_PLOTS_DATA(state, plot_data) {
      state.networkPlotsData = plot_data;
    },
    UPDATE_PLOT(state, plot) {
      state.plot = plot;
    },
    SET_STATS_TAB(state, tab) {
      state.statistics.tab = tab;
    },
    UPDATE_STATISTICS(state, { subject, data }) {
      state.statistics.items[subject] = data;
    },
    ADD_NETWORK_VIEW(state, added_view: NetworkView) {
      state.networkViews.push(added_view);
      state.nbViews += 1;
    },
    REMOVE_NETWORK_VIEW(state, removed_view: NetworkView) {
      state.networkViews = state.networkViews.filter(view => view !== removed_view);
    },
    SELECT_NETWORK_VIEW(state, network_view: NetworkView) {
      state.currentNetworkView = network_view;
    }
  }
};
