<template>
  <kite-map-action
    action="map_export"
    :title="$t('map_actions.map_export.title')"
    :get-result="exportMap"
    :on-open="init_map_export"
    :show-clear="false"
    :clear-content="onClose"
    :disable-validation="!valid"
    :width="430"
  >
    <v-form v-model="valid">
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-select v-model="export_format" :label="$t('basic_dialogs.format')" :items="['PDF', 'PNG']" />
          </v-col>
          <v-col cols="7">
            <v-text-field
              prepend-icon="drive_file_rename_outline"
              v-model="filename"
              :label="$t('basic_dialogs.filename')"
              autofocus
              :suffix="EXPORT_EXTENSIONS[export_format]"
              :rules="[nameRequired, filenameRegexRule]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-if="export_format == 'PDF'">
        <v-text-field
          prepend-icon="title"
          v-model="title"
          :label="$t('map_actions.map_export.map_title')"
        ></v-text-field>
      </v-card-text>
    </v-form>
  </kite-map-action>
</template>

<script>
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import KiteMapAction from "./kite_map_action.vue";
import { MapExport, MapCapture, EXPORT_EXTENSIONS } from "@/map_export";
import { filenameRegexRule, nameRequired } from "@/validation";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

export default Vue.component("kite-map-export", {
  components: {
    KiteMapAction
  },
  data: function () {
    return {
      EXPORT_EXTENSIONS,
      export_format: "PDF",
      filename: "map-export",
      title: "",
      map_capture: null,
      map_export: null,
      main_drawer_tmp: true,
      valid: true
    };
  },
  beforeDestroy() {
    if (this.map_capture) {
      this.map_capture.removeCaptureOverlay();
    }
  },
  computed: {
    ...mapState(["mainDrawerOpen"]),
    ...mapState("layers", ["map"])
  },
  methods: {
    ...mapActions(["asyncStart", "asyncEnd"]),
    filenameRegexRule,
    nameRequired,
    init_map_export() {
      // init map title
      this.title = this.$t("map_actions.map_export.default_map_title").toString();

      // create new MapExport instance
      this.map_capture = new MapCapture(this.map, 240);

      // store main drawer state
      this.main_drawer_tmp = this.mainDrawerOpen;

      // hide main drawer
      this.$store.commit("UPDATE_MAIN_DRAWER", false);

      // display capture overlay
      this.map_capture.displayCaptureOverlay();
    },
    onClose() {
      // remove capture overlay
      this.map_capture.removeCaptureOverlay();

      // restore drawer state
      this.$store.commit("UPDATE_MAIN_DRAWER", this.main_drawer_tmp);
    },
    exportMap() {
      this.asyncStart({ type: "generic" });
      let map_export = new MapExport(this.map, this.map_capture, this.$whale.user.firstName, this.title);
      map_export.exportTo(this.filename, this.export_format).finally(() => {
        this.asyncEnd({ type: "generic" });
      });
    }
  }
});
</script>
