<template>
  <v-card>
    <v-card-text class="pa-8" style="height: 470px">
      <v-row style="height: 400px">
        <v-col md="6">
          <h3 :style="{ color: $vuetify.theme.themes.light.primary }">{{ title }}</h3>
          <slot name="import" :input-state="input_state" :new-input="newInput" :clear-input="clearInput">
            <v-card height="100" color="grey"> </v-card>
          </slot>
          <p />
          <slot name="stats" :data-info="data_info"> </slot>
        </v-col>

        <v-divider vertical />

        <v-col md="6">
          <h3 :style="{ color: $vuetify.theme.themes.light.primary }">{{ $t("add_dialog.titles.name") }}</h3>
          <v-text-field
            v-model="name"
            prepend-icon="drive_file_rename_outline"
            :counter="name_max_size"
            required
            :rules="name_rules"
          ></v-text-field>
          <slot name="config" :add-config="addConfig"> </slot>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="close">
          {{ $t("basic_dialogs.cancel") }}
        </v-btn>
        <v-btn color="primary" text :disabled="!isConfigValid" @click="validateDataForm">
          {{ $t("basic_dialogs.validate") }}
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import i18n from "@/plugins/lang";
import { nameRequired, nameLengthRule, name_max_size } from "@/validation";

export default Vue.component("kite-add-data", {
  props: {
    // add page title
    title: {
      type: String,
      required: true
    },
    // reads data from the import input
    readInputData: {
      type: Function,
      required: true
    },
    // throws an error if data is not valid
    validateData: {
      type: Function
    },
    // evaluates data info
    evaluateDataInfo: {
      type: Function,
      required: true
    },
    // provides a new data name
    newDataName: {
      type: Function,
      required: true
    },
    // creates a new config from the given data
    newConfig: {
      type: Function,
      required: true
    },
    configValidator: {
      type: Function,
      required: true
    },
    // adds data to Kite environment/stores when validating
    addData: {
      type: Function,
      required: true
    },
    // executed when cancelling or after adding data
    close: {
      type: Function,
      required: true
    }
  },

  components: {},

  data() {
    return {
      // import
      input_state: {
        value: null,
        loading: false,
        message: true
      },
      data: null,
      // stats
      data_info: this.evaluateDataInfo(null),
      // name
      name: this.newDataName(),
      name_max_size,
      name_rules: [nameRequired, nameLengthRule],
      // config
      addConfig: this.newConfig(null)
    };
  },

  computed: {
    isNameValid() {
      return this.name_rules
        .map(rule => {
          return rule(this.name);
        })
        .every(item => item === true);
    },
    isConfigValid() {
      return !!this.data && this.isNameValid && this.configValidator(this.addConfig);
    }
  },

  methods: {
    async newInput() {
      this.setInputErrorMessage(true);
      this.setInputLoading(true);
      let data;
      let final_result = null;

      try {
        if (this.input_state.value) {
          // read data from input state
          data = await this.readInputData(this.input_state.value, this.setDataName);
          if (!data) return;

          // data validation
          try {
            if (this.validateData) {
              this.validateData(data);
            }
            final_result = data;
          } catch (error) {
            throw new Error(error.message);
          }
        }
      } catch (error) {
        this.setInputErrorMessage(error.message);
      } finally {
        this.setInputLoading(false);
        this.setData(final_result);
      }
    },

    setData(data) {
      this.data = data;

      this.data_info = this.evaluateDataInfo(this.data);

      this.addConfig = this.newConfig(this.data, this.data_info);
    },

    validateDataForm() {
      try {
        this.addData(this.data, this.name, this.addConfig);
        this.close();
        this.name = this.newDataName();
        this.clearInput();
      } catch (error) {
        console.log(error);
        let message = this.$t("add_dialog.errors.add");
        alert({ message, type: "error" });
      }
    },

    clearInput() {
      this.input_state.value = null;
      this.input_state.message = true;
      this.addConfig = this.newConfig(null);
      this.data_info = this.evaluateDataInfo(null);
    },

    setInputErrorMessage(message) {
      this.input_state.message = message;
    },

    setInputLoading(value) {
      this.input_state.loading = value;
    },

    setDataName(value) {
      this.name = value;
    }
  }
});
</script>
