/* flows.store.js */

import { convertLineStringtoPoints, sortFromList } from "@/functions-tools";
import { getFlowsPlot, starlingStats, flowmapStats, emptyFlowsFilters } from "@/flows";
import { FlowsView } from "@/models";
import { getInstance } from "@/api/index";

const zoning_order = ["reg", "dep", "epci", "com", "iris"];

const initialState = () => ({
  // list of flows displayed in the table
  flowsTable: [],
  // currently selected flow view
  currentFlowsView: null,
  // filtered data (what is displayed)
  viewFilteredData: null,
  // flows plots
  plot: {
    attribute: null,
    type: null,
    interval: 1,
    percentage: true
  },
  plots_key: 0,
  // flows statistics
  statistics: {},
  dialog: {
    add: false
  },
  summaryFlows: [],
  zoningSummaryTable: []
});

const state = initialState();

export default {
  // State object
  namespaced: true,
  state,

  // Getter functions
  getters: {
    allNames: state => {
      return state.flowsTable.map(item => item.name);
    },
    getType: state => {
      return state.currentFlowsView?.type;
    },
    getData: state => {
      return state.currentFlowsView?.data;
    },
    getFilters: state => {
      return state.currentFlowsView?.filters || emptyFlowsFilters();
    },
    getSelectedLocationId: state => {
      if (state.currentFlowsView?.type == "FLOWMAP") {
        return state.currentFlowsView.selectedLocationId;
      } else {
        return undefined;
      }
    },
    getAttributes: state => {
      if (state.currentFlowsView) {
        return state.currentFlowsView.attributes;
      } else {
        return [];
      }
    },
    flowsPlot: (state, getters) => {
      return getFlowsPlot(
        getters.getType,
        state.viewFilteredData,
        state.plot.attribute,
        state.plot.type == "continuous",
        state.plot.percentage,
        state.plot.interval
      );
    }
  },
  // Actions
  actions: {
    /**
     * Set displayed OD and reset OD store (plot, statistics, filter...).
     * Categorise attributes and set a new filter.
     * Finally set layers visibility.
     *
     * @param {*} context
     * @param {FlowsView} flows_view
     */
    displayFlows(context, flows_view) {
      // update the displayed OD
      context.commit("SET_CURRENT_FLOWS_VIEW", flows_view);

      // reset the store and trigger the data update
      context.commit("RESET_STORE");

      // update relevant layers display
      if (flows_view) {
        flows_view.updateDisplayedData();
      } else {
        context.dispatch("updateDisplayedData", undefined);
      }
    },

    /**
     * Update the layer(s) data according to the selected filters
     * @param {*} context
     */
    updateDisplayedData(context, data) {
      // set new filtered data
      context.commit("SET_VIEW_FILTERED_DATA", data);

      switch (context.getters.getType) {
        case "STARLING":
          // remove FlowMap display
          context.dispatch("removeFlowMapDisplay");
          // set scatterplots and arcs data
          context.dispatch(
            "layers/setLayersData",
            {
              ids: ["origin-scatterplot", "destination-scatterplot", "users-arclayer"],
              data: data.features
            },
            { root: true }
          );
          // set heatmap data
          let geojson = convertLineStringtoPoints(data);
          context.dispatch("layers/setLayersData", { ids: "users-heatmap", data: geojson }, { root: true });
          // update layers visibility
          context.dispatch("layers/setLayersVisibility", { ids: ["users-arclayer"], isVisible: true }, { root: true });
          // update stats
          context.dispatch("updateStatistics");
          break;
        case "FLOWMAP":
          // remove Starling display
          context.dispatch("removeStarlingDisplay");
          // set FlowMap data
          context.dispatch(
            "layers/setLayersData",
            {
              ids: ["users-flowmap"],
              data: context.state.currentFlowsView.aggregated_filtered_data
            },
            { root: true }
          );
          // update layer visibility
          context.dispatch("layers/setLayersVisibility", { ids: ["users-flowmap"], isVisible: true }, { root: true });
          // update stats
          context.dispatch("updateStatistics");
          break;
        default:
          // remove Starling and FlowMap display
          context.dispatch("removeFlowMapDisplay");
          context.dispatch("removeStarlingDisplay");
      }
    },

    /**
     * Remove the flowmap layer data and visibility
     * @param {*} context
     */
    removeFlowMapDisplay(context) {
      context.dispatch("layers/setLayersVisibility", { ids: ["users-flowmap"], isVisible: false }, { root: true });
      context.dispatch(
        "layers/setLayersData",
        { ids: "users-flowmap", data: { locations: [], flows: [] } },
        { root: true }
      );
    },

    /**
     * Remove the Starling layers data and visibility
     * @param {*} context
     */
    removeStarlingDisplay(context) {
      context.dispatch(
        "layers/setLayersVisibility",
        {
          ids: ["origin-scatterplot", "destination-scatterplot", "users-arclayer", "users-heatmap"],
          isVisible: false
        },
        { root: true }
      );
      context.dispatch(
        "layers/setLayersData",
        {
          ids: ["origin-scatterplot", "destination-scatterplot", "users-arclayer", "users-heatmap"],
          data: null
        },
        { root: true }
      );
    },

    /**
     * Update the displayed statistics on the currently displayed OD
     * @param {*} context
     */
    updateStatistics(context) {
      let type = context.getters.getType;
      let stats = {};
      if (type == "STARLING") {
        stats = starlingStats(context.state.viewFilteredData);
      } else if (type == "FLOWMAP") {
        stats = flowmapStats(context.state.currentFlowsView.aggregated_filtered_data);
      }
      context.commit("UPDATE_STATISTICS", stats);
    },

    async updateSummaryFlows(context) {
      let whale = getInstance();
      let response = await whale.getFlowsSummary().catch(err => {
        throw err;
      });
      context.commit("UPDATE_SUMMARY_FLOWS", response);
    },

    async updateSummaryZoning(context) {
      let whale = getInstance();
      let response = await whale.getZoningSummary();
      let ordered = sortFromList(response, zoning_order, el => el.zoning).reverse();
      context.commit("UPDATE_SUMMARY_ZONINGS", ordered);
    }
  },

  // Mutations
  mutations: {
    SET_CURRENT_FLOWS_VIEW(state, flows_view) {
      state.currentFlowsView = flows_view;
    },
    ADD_OD_ITEM(state, item: FlowsView) {
      state.flowsTable.push(item);
    },
    REMOVE_OD_ITEM(state, flows_view) {
      state.flowsTable = state.flowsTable.filter(item => item !== flows_view);
    },
    SET_VIEW_FILTERED_DATA(state, fitlered_data) {
      state.viewFilteredData = fitlered_data;
    },
    SET_PLOT_ATTRIBUTE(state, value) {
      state.plot.attribute = value;
      let filters = state.currentFlowsView?.filters || {};
      state.plot.type = filters.attributes.continuous
        .map(attribute_filter => attribute_filter.attribute)
        .includes(value)
        ? "continuous"
        : "discrete";
    },
    SET_PLOT_INTERVAL(state, value) {
      state.plot.interval = value;
    },
    SET_PLOT_PERCENTAGE(state, value) {
      state.plot.percentage = value;
    },
    UPDATE_SUMMARY_FLOWS(state, summary_flows) {
      state.summaryFlows = summary_flows;
    },
    UPDATE_SUMMARY_ZONINGS(state, summary_zoning) {
      state.zoningSummaryTable = summary_zoning;
    },
    UPDATE_STATISTICS(state, value) {
      state.statistics = value;
    },
    // TODO : put these in a same object
    RESET_STORE(state) {
      const newState = initialState();
      Object.keys(newState)
        .filter(x => ["filters", "plot", "statistics"].includes(x))
        .forEach(key => {
          state[key] = newState[key];
        });
    },
    UPDATE_DIALOG(state, payload) {
      state.dialog[payload.dialog] = payload.value;
    }
  }
};
