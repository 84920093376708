<template>
  <div>
    <v-switch label="Premier / dernier arrêt uniquement" v-model="first_last"></v-switch>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.component("first-last-switch", {
  computed: {
    first_last: {
      get() {
        return this.$store.state.capucine_analysis.first_last;
      },
      set(value) {
        this.$store.commit("capucine_analysis/SET_FIRST_LAST", value);
      }
    }
  }
});
</script>
