<!--
  Filters for flows
-->

<template>
  <div id="flows_filters_div">
    <v-row class="mt-n6 ml-0 mr-0 mb-5" align="center">
      <v-btn text color="primary" @click="validate" :loading="loading" :disabled="!filterChanged"> Valider </v-btn>
      <v-btn text color="primary" @click="reset" :disabled="!filterChanged"> Annuler </v-btn>
      <v-spacer>
        <span class="red--text" v-if="filterChanged">Vous avez des filtres non validés</span>
      </v-spacer>
    </v-row>
    <v-card v-if="getType == 'FLOWMAP' && selectedLocation" class="mb-10">
      <v-card-text>
        <v-card-actions>
          <div>
            {{ $t("flows.filters.selected_location") }}
            <strong>
              {{ selectedLocation.name }}
            </strong>
          </div>
          <v-spacer />
          <v-icon @click="unselectLocation"> clear </v-icon>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <v-row class="mt-4 ml-0 mr-0">
      <v-slider
        v-if="getType == 'FLOWMAP'"
        :label="$t('flows.filters.flowmap_proportion')"
        :validate-on-blur="true"
        :value="local_filters.proportion"
        @end="local_filters.proportion = $event"
        min="0"
        max="100"
        step="1"
        ticks
        thumb-label="always"
        :thumb-size="25"
      />
    </v-row>
    <v-row align="center" v-if="getType == 'FLOWMAP'">
      <v-col cols="4">
        <v-text-field
          v-model="local_filters.minimumFlow"
          type="number"
          :label="$t('flows.filters.flowmap_minimum')"
          min="0"
        ></v-text-field>
      </v-col>
    </v-row>
    <div v-for="attribute_filter in local_filters.attributes.discrete" :key="attribute_filter.attribute">
      <v-select
        v-model="attribute_filter.selected_values"
        :label="formatText(attribute_filter.attribute)"
        :items="attribute_filter.values"
        multiple
      >
        <template slot="selection" slot-scope="data">
          {{ formatText(data.item) }}
        </template>
        <template slot="item" slot-scope="data">
          {{ formatText(data.item) }}
        </template>
      </v-select>
    </div>
    <div v-for="attribute_filter in local_filters.attributes.continuous" :key="attribute_filter.attribute">
      <p></p>
      <v-range-slider
        :value="attribute_filter.selected_min_max"
        @end="attribute_filter.selected_min_max = $event"
        :label="formatText(attribute_filter.attribute)"
        :min="attribute_filter.min"
        :max="attribute_filter.max"
        step="1"
        ticks="always"
        thumb-label="always"
        :thumb-size="25"
      ></v-range-slider>
    </div>
    <v-row v-if="getType == 'STARLING'">
      <v-col>
        <v-text-field
          v-model="local_filters.proportion"
          type="number"
          :label="$t('flows.filters.starling_proportion')"
          min="1"
          max="100"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field v-model="local_filters.seed" :label="$t('flows.filters.seed')"></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters, mapState, mapActions } from "vuex";
import { formatText } from "@/functions-tools";
import { emptyFlowsFilters } from "@/flows";

export default Vue.component("flows-filters", {
  data: function () {
    return {
      local_filters: emptyFlowsFilters(),
      loading: false
    };
  },
  watch: {
    currentFlowsView: {
      handler() {
        this.reset();
      },
      immediate: true
    }
  },
  computed: {
    ...mapState("flows", ["currentFlowsView"]),
    ...mapGetters("flows", ["getType", "getFilters"]),
    filterChanged() {
      return JSON.stringify(this.getFilters) !== JSON.stringify(this.local_filters);
    },
    selectedLocation() {
      let selectedLocationId = this.currentFlowsView.selectedLocationId;
      if (selectedLocationId !== null) {
        let correspondingLocations = this.currentFlowsView.data.locations.filter(el => {
          return el.id == selectedLocationId;
        });
        if (correspondingLocations.length != 1) {
          throw new Error("Error while getting selected location");
        }
        return correspondingLocations[0];
      } else {
        return undefined;
      }
    }
  },

  methods: {
    formatText,
    unselectLocation() {
      this.currentFlowsView.setSelectedLocationId(null);
    },
    validate() {
      this.loading = true;
      this.currentFlowsView.setFilters(JSON.parse(JSON.stringify(this.local_filters)));
      this.loading = false;
    },
    reset() {
      this.local_filters = JSON.parse(JSON.stringify(this.getFilters));
    }
  }
});
</script>

<style>
#flows_filters_div {
  padding-left: 0px;
  padding-right: 20px;
  padding-top: 20px;
}
</style>
