<!--
  Page to display analysis
-->

<template>
  <div>
    <v-tabs-items v-model="tab" :vertical="true">
      <v-tab-item v-for="(item, index) in analysis_tab_items" :value="item.key" :key="index">
        <component :is="item.key.replace(/_/g, '-')" :key="item.key" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import KccLoss from "./kcc_loss.vue";
import CommercialSpeed from "./commercial_speed.vue";
import BufferTime from "./buffer_time.vue";
import Robustness from "./robustness.vue";
import Traffic from "./traffic.vue";
import VehicleLoad from "./vehicle_load.vue";
import Serpentary from "./serpentary.vue";
import TravelTime from "./travel_time.vue";
import CumulativeTravelTime from "./cumulative_travel_time.vue";
import Conformity from "./conformity.vue";
import Production from "./production.vue";
import AnalysisSynthesis from "./analysis_synthesis.vue";
import Presentation from "./presentation.vue";

import { mapState } from "vuex";
import { ANALYSIS_KPIS } from "@/capucine_utils";

export default Vue.component("analysis-page", {
  components: {
    KccLoss,
    CommercialSpeed,
    BufferTime,
    Robustness,
    Traffic,
    VehicleLoad,
    Serpentary,
    TravelTime,
    CumulativeTravelTime,
    Conformity,
    Production,
    AnalysisSynthesis,
    Presentation
  },
  data: () => ({}),
  computed: {
    ...mapState("capucine_analysis", ["tab"]),
    analysis_tab_items() {
      return [{ key: "presentation" }, { key: "analysis_synthesis" }, ...ANALYSIS_KPIS];
    }
  }
});
</script>
