<template>
  <v-dialog :value="dialog" max-width="800px" @click:outside="closeDialog()">
    <v-card>
      <v-card-title class="headline grey lighten-2">{{ $t("trace.paths.title") }}</v-card-title>
      <v-card-text>
        <v-row align="center">
          <!-- Agent type selection -->
          <v-col class="d-flex" cols="12" sm="4">
            <v-select
              v-model="selectedAgentTypes"
              :items="agentTypeList"
              :label="$t('trace.paths.agent_type')"
              multiple
              @change="selectedIds = []"
            ></v-select>
          </v-col>
          <!-- Agent ids selection -->
          <v-col class="d-flex" cols="12" sm="4">
            <v-autocomplete
              v-model="selectedIds"
              :items="idList"
              :label="$t('trace.paths.agent_id')"
              multiple
              disable-lookup
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0"> {{ selectedIds.length + " " + $t("trace.paths.selected_suffix") }} </span>
              </template>
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggle">
                  <v-list-item-action>
                    <v-icon :color="selectedIds.length > 0 ? 'indigo darken-4' : ''">
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("trace.paths.select_all") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-autocomplete>
          </v-col>
          <!-- Layer name input -->
          <v-col class="d-flex" cols="12" sm="3">
            <v-text-field v-model="layerNameInput" :label="$t('trace.paths.layer_name')" clearable></v-text-field>
          </v-col>
          <!-- Layer add button -->
          <v-col class="d-flex" cols="12" sm="1">
            <v-btn icon color="indigo darken-4" :disabled="invalidInput" @click="newAgentPathsLayer">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { agentTypeIds } from "@/functions-tools";
import { create_kite_layer, KiteLineLayer } from "@/kite_layers";
import { mapState, mapGetters } from "vuex";

export default Vue.component("paths-layers", {
  props: ["dialog"],

  data: function () {
    return {
      layerNameInput: "PathLayer 0",
      layerNameCounter: 0,
      selectedAgentTypes: [],
      selectedIds: []
    };
  },
  computed: {
    ...mapState("traces", ["agentTypeList", "pathsData"]),
    idList() {
      if (this.selectedAgentTypes.length == 0) {
        return [];
      } else {
        return agentTypeIds(this.pathsData, this.selectedAgentTypes);
      }
    },
    invalidInput() {
      return this.selectedIds.length == 0 || this.layerNameInput == "" || this.layerNameInput == null;
    },
    icon() {
      if (this.selectedIds.length == this.idList.length) return "mdi-close-box";
      if (this.selectedIds.length != 0) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  methods: {
    newAgentPathsLayer() {
      // create the agent paths layer
      let layerId = "pathlayer:" + this.layerNameInput;
      let selected_features = this.pathsData.features.filter(d => this.selectedIds.includes(d.properties.agent_id));
      let editAttributes = {
        size: 3,
        color: "#000000",
        opacity: 1
      };

      let layer_constructor = {
        layer_class: KiteLineLayer,
        id: layerId,
        name: this.layerNameInput,
        category: "trace",
        display_mode: "user",
        startsVisible: true,
        data: {
          type: "FeatureCollection",
          features: selected_features
        },
        editable: true,
        editAttributes: editAttributes,
        dataProperties: ["agent_id", "agent_type", "icon_type"]
      };
      create_kite_layer(layer_constructor, true);

      // reset input fields
      this.layerNameCounter += 1;
      this.layerNameInput = this.defaultLayerName();
      this.selectedAgentTypes = [];

      // close dialog
      this.closeDialog();
    },
    // selection function associated to the "Select all" button
    toggle() {
      this.$nextTick(() => {
        if (this.selectedIds.length == this.idList.length) {
          this.selectedIds = [];
        } else {
          this.selectedIds = this.idList.slice();
        }
      });
    },
    defaultLayerName() {
      return "PathLayer " + this.layerNameCounter;
    },
    closeDialog() {
      this.$emit("handleUpdateDialog", false);
    }
  }
});
</script>
