<!--
  Statistics on network (GTFS)
-->

<template>
  <div>
    <v-overlay opacity=".9" color="grey lighten-1" absolute :value="async.overlay" z-index="12">
      <div class="d-flex flex-column align-center">
        <h1>{{ $t("visualisation.loading") }}</h1>
        <v-progress-circular :width="6" color="primary" indeterminate />
      </div>
    </v-overlay>
    <v-tabs
      v-model="statistics.tab"
      background-color="secondary"
      height="40px"
      class="elevation-2"
      dark
      grow
      hide-slider
    >
      <v-tab :key="0" :href="`#tab-0`">
        {{ $t("statistics.network.tabs.network") }}
      </v-tab>
      <v-tab-item :key="0" :value="'tab-0'">
        <v-data-table
          :headers="headers.network"
          :items="statistics.items.network"
          disable-pagination
          hide-default-header
          hide-default-footer
          dense
        ></v-data-table>
        <div id="advanced_statistics_div" v-if="isAdvancedStatisticsAvailable" align="center">
          <v-btn :small="true" @click="updateAdvancedGtfsStatistics()" disabled>
            {{ $t("statistics.network.more") }}</v-btn
          >
        </div>
      </v-tab-item>
      <v-tab :key="1" :href="`#tab-1`" :disabled="selectedRouteId == null">
        {{ $t("statistics.network.tabs.route") }}
      </v-tab>
      <v-tab-item :key="1" :value="'tab-1'">
        <v-data-table
          :headers="headers.route"
          :items="statistics.items.route"
          disable-pagination
          hide-default-header
          hide-default-footer
        ></v-data-table>
      </v-tab-item>
      <v-tab :key="2" :href="`#tab-2`" :disabled="selectedRouteId == null">
        {{ $t("statistics.network.tabs.advanced") }}
      </v-tab>
      <v-tab-item :key="2" :value="'tab-2'">
        <v-data-table
          :headers="headers.advanced"
          :items="statistics.items.advanced"
          disable-pagination
          hide-default-header
          hide-default-footer
        ></v-data-table>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import i18n from "@/plugins/lang";
import { mapState, mapActions } from "vuex";

export default Vue.component("network-statitics", {
  data: function () {
    return {
      headers: {
        network: [
          {
            text: "Donnée",
            align: "left",
            sortable: false,
            value: "name"
          },
          { text: "Valeur", align: "right", sortable: false, value: "data" }
        ],
        route: [
          {
            text: "Donnée",
            align: "left",
            sortable: false,
            value: "name"
          },
          { text: "Valeur", align: "right", sortable: false, value: "data" }
        ],
        advanced: [
          {
            text: "Donnée",
            align: "left",
            sortable: false,
            value: "name"
          },
          { text: "Valeur", align: "right", sortable: false, value: "data" }
        ]
      }
    };
  },

  computed: {
    ...mapState("network", ["selectedRouteId", "isAdvancedStatisticsAvailable", "statistics"]),
    ...mapState(["async"]),
    statistics: {
      get() {
        return this.$store.state.network.statistics;
      },
      set(newValue) {
        this.$store.state.network.statistics = newValue;
      }
    },
    isAdvancedStatisticsAvailable: {
      get() {
        return this.$store.state.network.isAdvancedStatisticsAvailable;
      },
      set(newValue) {
        this.$store.commit("network/UPDATE_ADVANCED_STATS_AVAILABILITY", newValue);
      }
    }
  },
  methods: {
    async updateAdvancedGtfsStatistics() {
      let data = await this.$whale.getAdvancedNetworkStatistics(
        this.currentNetworkView.gtfs_index,
        this.currentNetworkView.date
      );
      let raw_statistics = data.statistics;
      this.isAdvancedStatisticsAvailable = false;
      this.statistics.network.items.push({
        data: raw_statistics["num_estimated_vehicles"],
        name: i18n.t("statistics.network.num_estimated_vehicles")
      });
      this.statistics.network.items.push({
        data: raw_statistics["services_distance"] + " km",
        name: i18n.t("statistics.network.services_distance")
      });
      this.statistics.network.items.push({
        data: raw_statistics["services_duration"] + " min",
        name: i18n.t("statistics.network.services_duration")
      });
      this.statistics.network.items.push({
        data: raw_statistics["services_speed"] + " km/h",
        name: i18n.t("statistics.network.services_speed")
      });
    }
  }
});
</script>

<style>
#advanced_statistics_div {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
