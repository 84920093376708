<!--
  Apex chart with vehicle load
-->

<template>
  <kpi-card title="Serpentaire de charge" :suffix="surveyDate" :subtitle="subtitle">
    <div v-if="series[0]['data'].length == 0"><b>Pas de données</b></div>
    <v-card-actions>
      <v-row>
        <v-col cols="3">
          <v-select
            prepend-icon="tune"
            label="Direction"
            v-model="direction"
            :items="directions"
            @change="updateChart"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select label="Période" v-model="period" :items="periods" @change="updateChart"></v-select>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card elevation="0">
      <v-overlay opacity="0.5" color="grey lighten-1" absolute :value="loading">
        <div class="d-flex flex-column align-center">
          <h1>Chargement</h1>
          <v-progress-linear color="primary" indeterminate></v-progress-linear>
        </div>
      </v-overlay>
      <apexchart height="650" ref="chart" :options="chartOptions" :series="series"></apexchart>
    </v-card>
  </kpi-card>
</template>

<script lang="ts">
import Vue from "vue";
import KpiCard from "@/components/capucine/kpi_card.vue";
import AnalysisKpiMixin from "./analysis_kpi_mixin.vue";

export default Vue.component("serpentary", {
  components: { KpiCard },
  mixins: [AnalysisKpiMixin],
  data: () => ({
    kpi_name: "serpentary",
    period: "Journée",
    periods: ["HPM", "HPS", "Journée"],
    series: [
      {
        name: "Montée",
        type: "column",
        data: []
      },
      {
        name: "Descente",
        type: "column",
        data: []
      },
      {
        name: "Charge",
        type: "line",
        data: []
      }
      // {
      //   name: "Capacité",
      //   type: "line",
      //   data: []
      // }
    ],
    chartOptions: {
      chart: {
        type: "line",
        toolbar: {
          show: true
        }
      },
      stroke: {
        curve: "smooth",
        width: [1, 1, 5],
        dashArray: [0, 0, 0]
      },
      colors: ["#3d6482", "#85c287", "#61a3bc"],
      plotOptions: {
        bar: {
          columnWidth: "50%"
        }
      },
      legend: {
        position: "top"
      },
      xaxis: {
        tooltip: {
          enabled: false
        },
        labels: {
          rotate: -60,
          rotateAlways: true,
          style: {
            fontSize: "10px"
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value.toFixed(0);
          }
        },
        min: 0
      }
    }
  }),
  mounted() {
    if (Object.keys(this.data).length > 0) {
      this.updateChart();
    }
  },
  watch: {
    data() {
      this.updateChart();
    }
  },
  computed: {},
  methods: {
    updateChart() {
      let series = this.series;
      series[0].data = this.data[this.direction][this.period]["up"];
      series[1].data = this.data[this.direction][this.period]["down"];
      series[2].data = this.data[this.direction][this.period]["vehicle_load"];
      // series[3].data = Array(this.data[id]["stops"].length).fill(this.capacity);
      this.$refs.chart.updateSeries(series);
      let chartOptions = this.chartOptions;
      chartOptions["labels"] = this.data[this.direction]["stops"];
      this.$refs.chart.updateOptions(chartOptions);
    }
  }
});
</script>
