<template>
  <v-card :class="classprop" height="100%" tile>
    <slot />
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.component("kite-drawer-content", {
  components: {},
  props: {
    classprop: {
      type: String,
      required: false,
      default: "overflow-y pr-0 pb-4 pl-0"
    }
  },
  data: function () {
    return {};
  }
});
</script>
