<template>
  <kite-add-data
    :title="title"
    :read-input-data="readInputData"
    :new-data-name="newName"
    :evaluate-data-info="evaluateDataInfo"
    :new-config="newConfig"
    :config-validator="isConfigValid"
    :addData="addDataToKite"
    :close="closeDialog"
  >
    <template v-slot:import="{ inputState, newInput }">
      <project-data-selector
        v-model="inputState.value"
        attribute="gtfs"
        :add-file-button="true"
        add-file-extension=".zip"
        @change="newInput"
        :loading="inputState.loading"
        :rules="[inputState.message]"
      />
      <span v-html="$t('network.add.project.intro')"></span>
    </template>
    <template v-slot:config="{ addConfig }">
      <h3 :style="{ color: $vuetify.theme.themes.light.primary }">{{ $t("add_dialog.titles.config") }}</h3>
      <v-select
        v-model="addConfig.date"
        :items="addConfig.available_dates"
        prepend-icon="event"
        label="Date"
        :no-data-text="$t('add_dialog.basics.no_data')"
      >
      </v-select>
      <v-switch v-model="addConfig.not_has_all_stops" :label="$t('network.add.config.has_all_stops')" />
    </template>
  </kite-add-data>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { dateListToItems, parseDate, removeExtensionFromFilename } from "@/functions-tools";
import KiteAddData from "@/components/kite/base/kite_add_data.vue";
import ProjectDataSelector from "@/components/projects/project_data_selector.vue";
import { NetworkView } from "@/models";

export default Vue.component("network-from-project", {
  components: {
    KiteAddData,
    ProjectDataSelector
  },

  data: function () {
    return {
      title: this.$t("network.add.project.title")
    };
  },

  computed: {
    ...mapState(["async", "language"])
  },

  methods: {
    async readInputData(input, setDataName) {
      setDataName(input.display_name);
      let data = {
        index: input.index,
        gtfs: input.display_name,
        dates: dateListToItems(await this.$whale.getNetworkDates(input.index), this.language)
      };
      return data;
    },

    newName() {
      return "";
    },

    evaluateDataInfo(data) {
      if (data) {
        return {};
      } else {
        return {};
      }
    },

    newConfig(data) {
      if (data) {
        return {
          available_dates: data.dates,
          date: data.dates[0].value,
          not_has_all_stops: true
        };
      } else {
        return {
          available_dates: [],
          date: null,
          not_has_all_stops: true
        };
      }
    },

    isConfigValid(config) {
      return !!config.date;
    },

    addDataToKite(data, name, config) {
      let network_view = new NetworkView(
        data.index,
        {
          value: config.date,
          text: parseDate(config.date).toLocaleString(this.language, { dateStyle: "short" })
        },
        !config.not_has_all_stops,
        name
      );
      network_view.addToKite();
    },
    closeDialog() {
      this.$store.commit("network/UPDATE_DIALOG", { dialog: "add", value: false });
    }
  }
});
</script>
