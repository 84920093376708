<!--
  Component with 3 v-selects to choose lot, route and day type
-->

<template>
  <div>
    <lot-selector
      v-model="lot"
      :loading="loading.lot || loading.synthesis"
      :disabled="loading.lot || loading.synthesis || loading.kpis > 0"
    />
    <v-row>
      <v-col md="10">
        <route-selector
          v-model="route_id"
          :routes="routes"
          :loading="loading.lot"
          :disabled="loading.lot || loading.kpis > 0"
        />
      </v-col>
      <v-col md="2" class="ml-n3">
        <section-legend :disabled="route.isAllRoutes" :route="route" :data-batch="data_batch_id"></section-legend>
      </v-col>
    </v-row>
    <data-batch-selector
      v-model="data_batch_id"
      :items="route_data_batches"
      :loading="loading.lot"
      :disabled="loading.lot || loading.kpis > 0"
    />
    <day-type-selector
      v-model="day_type"
      :day-types="day_types"
      :disabled="route.isAllRoutes || loading.lot || loading.kpis > 0"
      :loading="loading.lot"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import SectionLegend from "../section_legend.vue";
import LotSelector from "../lot_selector.vue";
import RouteSelector from "../route_selector.vue";
import DataBatchSelector from "../data_batch_selector.vue";
import DayTypeSelector from "../day_type_selector.vue";

export default Vue.component("data-setup", {
  components: { SectionLegend, LotSelector, RouteSelector, DataBatchSelector, DayTypeSelector },
  data: () => ({}),
  computed: {
    ...mapState("capucine_analysis", ["routes", "loading", "route_data_batches"]),
    ...mapGetters("capucine_analysis", ["route", "day_types"]),
    lot: {
      get() {
        return this.$store.state.capucine_analysis.lot;
      },
      set(value) {
        this.changeLot(value);
      }
    },
    route_id: {
      get() {
        return this.$store.state.capucine_analysis.route_id;
      },
      async set(value) {
        await this.changeRoute(value);
      }
    },
    day_type: {
      get() {
        return this.$store.state.capucine_analysis.day_type;
      },
      set(value) {
        this.changeDayType(value);
      }
    },
    data_batch_id: {
      get() {
        return this.$store.state.capucine_analysis.data_batch_id;
      },
      set(value) {
        this.changeDataBatch(value);
      }
    }
  },

  methods: {
    ...mapActions("capucine_analysis", ["changeDayType", "changeRoute", "changeDataBatch", "changeLot"])
  }
});
</script>
