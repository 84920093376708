<!--
  Apex chart with section travel time
-->

<template>
  <kpi-card
    title="Temps de parcours des tronçons"
    :suffix="titleSuffixWithDates"
    :subtitle="SUBTITLES.section_travel_time"
  >
    <v-card-actions>
      <v-row>
        <v-col cols="3">
          <v-select
            prepend-icon="tune"
            label="Direction"
            v-model="direction"
            :items="directions"
            item-text="text"
            item-value="value"
            @change="
              section = 'total';
              updateChart();
            "
          ></v-select>
        </v-col>
        <v-col v-if="!first_last" cols="3">
          <v-select
            label="Tronçon"
            v-model="section"
            :items="travel_time_sections"
            item-text="text"
            item-value="value"
            @change="updateChart"
          ></v-select>
        </v-col>
        <v-col v-if="!first_last">
          <section-legend :route="route" :data-batch="scenario_data_batch"></section-legend>
        </v-col>
      </v-row>
    </v-card-actions>
    <capucine-sim-tp-plot height="650" :series="series"></capucine-sim-tp-plot>
  </kpi-card>
</template>

<script lang="ts">
import Vue from "vue";
import KpiCard from "@/components/capucine/kpi_card.vue";
import {
  SUBTITLES,
  ALL_DAYS,
  CHART_OPTIONS_BASE,
  CHART_OPTIONS_SECTION_TRAVEL_TIME,
  updateSectionTravelTimeSeries,
  computeSectionPairsItems
} from "@/capucine_utils";
import { mapState, mapGetters } from "vuex";

export default Vue.component("section_travel_time", {
  components: { KpiCard },
  data: () => ({
    SUBTITLES,
    direction: "0",
    directions: [
      { text: "Aller", value: "0" },
      { text: "Retour", value: "1" }
    ],
    section: "total",
    series: [],
    chartOptions: Object.assign({}, CHART_OPTIONS_BASE, CHART_OPTIONS_SECTION_TRAVEL_TIME)
  }),
  mounted() {
    this.updateChart();
  },
  watch: {
    day_type(value: string) {
      if (value != ALL_DAYS) {
        // redraw
        this.updateChart();
      }
    },
    route(value) {
      if (!value.isAllRoutes) {
        // reset section
        this.section = "total";
        if (this.day_type != ALL_DAYS) {
          // redraw
          this.updateChart();
        }
      }
    }
  },
  computed: {
    ...mapState("capucine_simulation", ["first_last", "scenario_data_batch"]),
    ...mapState("capucine_results", ["data", "day_type"]),
    ...mapGetters("capucine_results", ["route", "segmentsPairs", "titleSuffixWithDates", "sections"]),
    travel_time_sections() {
      return computeSectionPairsItems(this.sections, this.segmentsPairs, this.direction, true);
    }
  },
  methods: {
    updateChart() {
      this.series = updateSectionTravelTimeSeries(
        this.direction,
        this.section,
        this.data[this.route.id][this.day_type]["section_travel_time"]
      );
    }
  }
});
</script>
