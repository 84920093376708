<!--
  Plots for network (GTFS) 
-->

<template>
  <div>
    <v-overlay opacity=".9" color="grey lighten-1" absolute :value="async.overlay" z-index="12">
      <div class="d-flex flex-column align-center">
        <h1>{{ $t("visualisation.loading") }}</h1>
        <v-progress-circular :width="6" color="primary" indeterminate />
      </div>
    </v-overlay>
    <v-tabs v-model="tab" background-color="secondary" height="40px" class="elevation-2" dark grow hide-slider>
      <v-tab :key="0" :href="`#tab-0`">
        {{ $t("plots.network.one_way") }}
      </v-tab>
      <v-tab-item :key="0" :value="'tab-0'"> </v-tab-item>
      <v-tab :key="1" :href="`#tab-1`">
        {{ $t("plots.network.back") }}
      </v-tab>
      <v-tab-item :key="1" :value="'tab-1'"> </v-tab-item>
    </v-tabs>
    <div>
      <b>{{ $t("plots.network.line") }}: </b>
      {{ selectedRouteShortName }} <br /><b>Date: </b>{{ currentNetworkView.date.text }}
      <v-row>
        <v-col class="d-flex align-center justify-center" cols="12" sm="6">
          <v-select v-model="selectedPlots" :items="typesPlots" label="Type" @change="updateSeriesData()">
            <template slot="item" slot-scope="data">
              {{ $t("plots.network.type." + data.item) }}
            </template>
            <template slot="selection" slot-scope="data">
              {{ $t("plots.network.type." + data.item) }}
            </template>
          </v-select>
        </v-col>
        <v-col class="d-flex justify-center mt-4" cols="12" sm="4">
          <v-tooltip slot="append" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small color="primary" v-bind="attrs" @click="getRouteRealTravelTime()" disabled v-on="on">
                {{ $t("plots.network.travel_time_historic") }}
              </v-btn>
            </template>
            <span> {{ $t("plots.network.historic") }} </span>
          </v-tooltip>
        </v-col>
        <v-col class="d-flex justify-center mt-4" cols="12" sm="2">
          <v-tooltip slot="append" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small color="primary" v-bind="attrs" @click="$refs.plots.updateSeries(plot.series)" v-on="on">
                <v-icon dark> refresh </v-icon>
              </v-btn>
            </template>
            <span> {{ $t("plots.network.update") }} </span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex align-center justify-center mt-n10" cols="12" sm="6">
          <v-select
            :items="sequence"
            :label="$t('plots.network.start')"
            v-model="startStop"
            item-text="stop_name"
            return-object
            @change="
              resetRouteRealTravelTime();
              getRouteTheoreticalTravelTime();
            "
          ></v-select>
        </v-col>
        <v-col class="d-flex align-center justify-center mt-n10" cols="12" sm="6">
          <v-select
            :items="sequence"
            :label="$t('plots.network.end')"
            v-model="endStop"
            item-text="stop_name"
            return-object
            @change="
              resetRouteRealTravelTime();
              getRouteTheoreticalTravelTime();
            "
          ></v-select>
        </v-col>
      </v-row>
    </div>
    <apexchart type="line" ref="plots" :options="plot.options" :series="plot.series"></apexchart>
  </div>
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import { mapState, mapActions } from "vuex";
import { dateListToItems } from "@/functions-tools";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

export default Vue.component("network-plots", {
  components: {},
  data: () => ({
    tab: null,
    typesPlots: ["theoretical_travel_time", "headway"],
    selectedPlots: "theoretical_travel_time",
    sequence: [],
    startStop: null,
    endStop: null
  }),
  watch: {
    tab() {
      this.updateSeriesData();
    },
    networkPlotsData: {
      handler: function () {
        this.updateSeriesData();
      },
      deep: true
    }
  },
  computed: {
    ...mapState("network", [
      "currentNetworkView",
      "networkRouteData",
      "selectedRouteId",
      "selectedRouteShortName",
      "selectedVariants",
      "isRealTravelTimeAvailable",
      "networkPlotsData"
    ]),
    ...mapState(["async"]),
    plot: {
      get() {
        return this.$store.state.network.plot;
      },
      set(newValue) {
        this.$store.commit("network/UPDATE_PLOT", newValue);
      }
    }
  },
  methods: {
    updateSeriesData() {
      let direction_id = this.tab == "tab-0" ? 0 : 1;
      this.sequence = this.networkRouteData["sequence"][direction_id];
      this.startStop = this.networkPlotsData.terminus[direction_id]["start_stop"];
      this.endStop = this.networkPlotsData.terminus[direction_id]["end_stop"];
      let plot = this.plot;
      let series = plot.series.slice(0, 1);
      series[0].name = this.$t("plots.network.series." + this.selectedPlots);
      series[0].data = this.networkPlotsData[this.selectedPlots][direction_id];
      if (
        (this.networkPlotsData["real_travel_time"][direction_id].length > 0) &
        (this.selectedPlots == "theoretical_travel_time")
      ) {
        series.push({
          name: this.$t("plots.network.series.real_travel_time"),
          type: "line",
          data: this.networkPlotsData["real_travel_time"][direction_id]
        });
      }
      plot.series = series;
      this.plot = plot;
    },
    resetRouteRealTravelTime() {
      let direction_id = this.tab == "tab-0" ? 0 : 1;
      let networkPlotsData = this.networkPlotsData;
      networkPlotsData["real_travel_time"][direction_id] = [];
      networkPlotsData.terminus[direction_id]["start_stop"] = this.startStop;
      networkPlotsData.terminus[direction_id]["end_stop"] = this.endStop;
      this.$store.commit("network/UPDATE_NETWORK_PLOTS_DATA", networkPlotsData);
    },
    async getRouteRealTravelTime() {
      let direction_id = this.tab == "tab-0" ? 0 : 1;
      let data = await this.$whale.getRealTravelTime(
        this.currentNetworkView.gtfs_index,
        this.currentNetworkView.date.value,
        this.selectedRouteId,
        direction_id,
        this.startStop["stop_id"],
        this.endStop["stop_id"]
      );
      let networkPlotsData = this.networkPlotsData;
      networkPlotsData["real_travel_time"][direction_id] = data["real_travel_time"];
      this.$store.commit("network/UPDATE_NETWORK_PLOTS_DATA", networkPlotsData);
    },
    async getRouteTheoreticalTravelTime() {
      let direction_id = this.tab == "tab-0" ? 0 : 1;
      let data = await this.$whale.getTheoreticalTravelTime(
        this.currentNetworkView.gtfs_index,
        this.currentNetworkView.date.value,
        this.selectedRouteId,
        direction_id,
        JSON.stringify(this.selectedVariants),
        this.startStop["stop_id"],
        this.endStop["stop_id"]
      );
      let networkPlotsData = this.networkPlotsData;
      networkPlotsData["theoretical_travel_time"][direction_id] = data["theoretical_travel_time"];
      networkPlotsData["headway"][direction_id] = data["headway"];
      networkPlotsData["terminus"][direction_id] = { start_stop: this.startStop, end_stop: this.endStop };
      this.$store.commit("network/UPDATE_NETWORK_PLOTS_DATA", networkPlotsData);
    },
    dateListToItems(date) {
      return dateListToItems([date], this.$store.state.language)[0].text;
    }
  }
});
</script>
