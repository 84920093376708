<!--
  Dialog to configure isochrones parameters
-->

<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn color="primary" rounded large left dark v-on="on" :disabled="point_selection" @click="dialog = true">
          <v-icon left>mdi-plus</v-icon>{{ $t("network.road_isochrones.button") }}
        </v-btn>
      </template>
      <span>{{ $t("network.road_isochrones.tooltip") }}</span>
    </v-tooltip>
    <v-dialog v-model="dialog" max-width="650px">
      <v-card>
        <v-overlay opacity=".9" color="grey lighten-1" absolute :value="async.overlay" z-index="4">
          <div class="d-flex flex-column align-center">
            <h1>{{ $t("visualisation.loading") }}</h1>
            <v-progress-circular :width="6" color="primary" indeterminate />
          </div>
        </v-overlay>
        <v-card-title class="headline grey lighten-2">{{ $t("network.road_isochrones.title") }}</v-card-title>
        <v-card-text class="px-6 pt-6 pb-0">
          <v-form ref="form" v-model="form_valid">
            <v-row>
              <v-col md="4">
                <v-select
                  v-model="params.direction"
                  :items="available_directions"
                  :label="$t('basic_transport.direction')"
                >
                  <template slot="item" slot-scope="data">
                    {{ $t("basic_transport.directions." + data.item) }}
                  </template>
                  <template slot="selection" slot-scope="data">
                    {{ $t("basic_transport.directions." + data.item) }}
                  </template>
                </v-select>
              </v-col>
              <v-col md="8">
                <point-selector
                  v-model="params.point"
                  :label="$t('network.road_isochrones.point')"
                  @selection:update="
                    dialog = !$event;
                    point_selection = $event;
                  "
                  :rules="[mandatoryRule]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="5">
                <v-select
                  v-model="params.mode"
                  :items="available_modes"
                  :label="$t('basic_transport.mode')"
                  @change="setDefaultSpeed"
                >
                  <template slot="item" slot-scope="data">
                    <v-icon> {{ mode_icons[data.item] }} </v-icon>
                    <v-spacer />
                    {{ $t("basic_transport.modes." + data.item) }}
                  </template>
                  <template slot="selection" slot-scope="data">
                    <v-icon class="pr-3"> {{ mode_icons[data.item] }} </v-icon>
                    {{ $t("basic_transport.modes." + data.item) }}
                  </template>
                </v-select>
              </v-col>
              <v-col md="5">
                <v-text-field
                  v-model="params.max_time"
                  :label="$t('network.road_isochrones.max_travel_time')"
                  type="number"
                  suffix="minutes"
                  hide-spin-buttons
                  :rules="[mandatoryRule, positiveRule]"
                ></v-text-field>
              </v-col>
              <v-col v-if="params.mode != 'car'" md="2">
                <v-text-field
                  v-model="params.speed"
                  :label="$t('basic_transport.speed')"
                  type="number"
                  suffix="km/h"
                  hide-spin-buttons
                  :rules="[mandatoryRule, positiveRule]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end">
              <v-btn color="primary" text @click="dialog = false">{{ $t("basic_dialogs.cancel") }}</v-btn>
              <v-btn
                color="primary"
                text
                @click="$whale.runIfHasAccess('NETWORK', drawIsochrones)"
                :disabled="!form_valid"
              >
                {{ $t("basic_dialogs.execute") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import PointSelector from "@/components/kite/base/point_selector.vue";
import { addCustomLayer } from "@/kite_layers";
import { positiveRule, mandatoryRule } from "@/validation";

export default Vue.component("road-isochrones", {
  components: {
    PointSelector
  },
  data: function () {
    return {
      dialog: false,
      point_selection: false,
      form_valid: false,
      params: {
        point: null, // isochrone center
        direction: "departure", // travel direction
        mode: "car", // travel mode
        max_time: 30, // max time in minutes
        speed: 4, // speed in km/h for non car modes
        center_radius: 50 // center circle radius in meters
      },
      available_modes: ["car", "electrical_bike", "bike", "pedestrian"],
      available_directions: ["departure", "arrival"],
      mode_icons: {
        pedestrian: "directions_walk",
        bike: "directions_bike",
        electrical_bike: "electric_bike",
        car: "directions_car"
      },
      default_speeds: {
        pedestrian: 4,
        bike: 8,
        electrical_bike: 12,
        car: ""
      }
    };
  },
  computed: {
    ...mapState(["async", "language"])
  },
  methods: {
    ...mapActions("layers", ["setLayersData", "setLayersVisibility"]),
    mandatoryRule,
    positiveRule,
    setDefaultSpeed(mode) {
      this.params.speed = this.default_speeds[mode];
    },
    async drawIsochrones() {
      // close dialog
      this.dialog = false;

      // get isochrones as a geojson
      this.$whale
        .getRoadIsochrones(this.params)
        .then(data => {
          // add new layer from geojson
          addCustomLayer({
            layer_class_name: "KiteGeojsonLayer",
            data,
            name: this.$t("network.road_isochrones.layer_name", {
              mode: this.$t("basic_transport.modes." + this.params.mode),
              max_time: this.params.max_time
            })
          });
        })
        .catch(() => {
          let message = this.$t("api_errors.server_error");
          alert({ message, type: "error" });
        });
    }
  }
});
</script>
