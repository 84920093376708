// Kite mail contact
const KITE_CONTACT = "kite@tellae.fr";

// main colors
const COLORS = {
  primary: "#3d6482",
  secondary: "#85c287",
  grey: "#aaaaaa"
};

// zoning year for layers
const ZONING_YEAR = 2023;
const IRIS_ZONING_YEAR = 2022;

// default map zoom
const MAPZOOM = 5;
// default map pitch
const MAPPITCH = 0;
// city centers
const MAPCENTER = [2.571, 46.815];

// keys in trace input file in geojson properties
const TRACE_INPUT_KEYS = [
  {
    version: 0,
    keys: ["agent_id", "duration"]
  },
  {
    version: 1,
    keys: ["agent_id", "timestamps"]
  }
];

// maximum number of moving agents in trace
const MAX_MOVING_AGENTS = 1000;

// header for table of scenarios
const TABHEADERS = {
  numeric_kpi_table: [
    {
      text: "KPI",
      value: "KPI"
    },
    {
      text: "Somme",
      value: "sum",
      sortable: false,
      align: "end"
    },
    {
      text: "Moyenne",
      value: "mean",
      sortable: false,
      align: "end"
    },
    {
      text: "Écart-type",
      value: "sd",
      sortable: false,
      align: "end"
    },
    {
      text: "Min",
      value: "min",
      sortable: false,
      align: "end"
    },
    {
      text: "Max",
      value: "max",
      sortable: false,
      align: "end"
    }
  ],
  string_kpi_table: [
    {
      text: "KPI",
      value: "KPI"
    },
    {
      text: "Valeur",
      value: "value",
      sortable: false,
      groupable: false,
      align: "end"
    },
    {
      text: "Décompte",
      value: "count",
      sortable: false,
      groupable: false,
      align: "end"
    },
    {
      text: "Pourcentage",
      value: "percentage",
      sortable: false,
      groupable: false,
      align: "end"
    }
  ],
  agent_planning: [
    {
      text: "Heure",
      value: "hour",
      width: "25%",
      align: "start",
      sortable: true
    },
    {
      text: "Événement",
      value: "status",
      width: "75%",
      align: "end",
      sortable: true
    }
  ],
  list_scenario: [
    {
      text: "",
      value: "selected",
      // align: "start",
      sortable: false,
      align: "center",
      width: "10%"
    },
    {
      text: "Scénario",
      value: "scenario_name",
      // align: "start",
      sortable: true,
      width: "50%"
    },
    // {
    //   text: "Modèle",
    //   value: "model",
    //   //width: "20%",
    //   // align: "start",
    //   sortable: true
    // },
    // {
    //   text: "Véhicule",
    //   value: "vehicle",
    //   // width: "5%",
    //   // align: "start",
    //   sortable: true
    // },
    {
      text: "Statut",
      value: "status",
      // align: "start",
      sortable: false,
      align: "center"
    },
    {
      text: "Date",
      value: "_creationDate",
      // align: "start",
      sortable: true,
      align: "center"
    },
    // {
    //   text: "Groupe",
    //   value: "group",
    //   width: "15%",
    //   align: "start",
    //   sortable: true
    // },
    {
      text: "",
      value: "action",
      // align: "start",
      sortable: false,
      align: "center"
    }
  ],
  territory_kpis: [
    {
      text: "city",
      align: "start",
      value: "NOM",
      width: "150px",
      sortable: false,
      epci_only: true
    },
    {
      text: "code",
      align: "start",
      value: "INSEE_COM",
      width: "100px",
      sortable: false,
      epci_only: true
    },
    {
      text: "cityGroup",
      align: "start",
      value: "EPCI_NAME",
      width: "200px",
      sortable: false
    },
    {
      text: "population",
      align: "end",
      value: "P21_POP",
      width: "100px",
      sortable: false
    },
    {
      text: "density_population",
      align: "end",
      value: "P21_POP_DENSITY",
      width: "100px",
      sortable: false
    },
    {
      text: "population_previous",
      align: "end",
      value: "P15_POP",
      width: "100px",
      sortable: false
    },
    {
      text: "population_growth",
      align: "end",
      value: "Tx_ACC_ANNUEL_MOY_POP1521",
      width: "100px",
      sortable: false
    },
    {
      text: "unemployment",
      align: "end",
      value: "PercP21_CHOMEUR1564",
      width: "100px",
      sortable: false
    },
    {
      text: "occupied_worker",
      align: "end",
      value: "PercP21_ACTOCC1564",
      width: "100px",
      sortable: false
    },
    {
      text: "non_occupied_worker",
      align: "end",
      value: "PercP21_INACT1564",
      width: "100px",
      sortable: false
    },
    {
      text: "less15yrs",
      align: "end",
      value: "PercP21_POP0014",
      width: "100px",
      sortable: false
    },
    {
      text: "more64yrs",
      align: "end",
      value: "PercP21_POP6499",
      width: "100px",
      sortable: false
    },
    {
      text: "poverty",
      align: "end",
      value: "TP6021",
      width: "100px",
      sortable: false
    },
    {
      text: "employment",
      align: "end",
      value: "P21_EMPLT",
      width: "100px",
      sortable: false
    },
    {
      text: "employment_previous",
      align: "end",
      value: "P15_EMPLT",
      width: "100px",
      sortable: false
    },
    {
      text: "employment_growth",
      align: "end",
      value: "Tx_ACC_ANNUEL_MOY_EMPL1521",
      width: "100px",
      sortable: false
    },
    {
      text: "employment_rate_worker",
      align: "end",
      value: "Tx_EMPL_ACT",
      width: "100px",
      sortable: false
    },
    {
      text: "companies10p",
      align: "end",
      value: "SIRENE_10P",
      width: "100px",
      sortable: false
    },
    {
      text: "companies50p",
      align: "end",
      value: "SIRENE_50P",
      width: "100px",
      sortable: false
    },
    {
      text: "modeshare_dt_pt",
      align: "end",
      value: "MODE21_PT",
      width: "100px",
      sortable: false
    },
    {
      text: "modeshare_dt_car",
      align: "end",
      value: "MODE21_CAR",
      width: "100px",
      sortable: false
    },
    {
      text: "modeshare_dt_bike",
      align: "end",
      value: "MODE21_BIKE",
      width: "100px",
      sortable: false
    },
    {
      text: "modeshare_dt_walk",
      align: "end",
      value: "MODE21_WALK",
      width: "100px",
      sortable: false
    },
    {
      text: "household",
      align: "end",
      value: "P21_MEN",
      width: "100px",
      sortable: false
    },
    {
      text: "household_size",
      align: "end",
      value: "P21_PMEN",
      width: "100px",
      sortable: false
    },
    {
      text: "household_park",
      align: "end",
      value: "PercP21_RP_GARL",
      width: "100px",
      sortable: false
    },
    {
      text: "household_0car",
      align: "end",
      value: "PercP21_RP_VOIT0",
      width: "100px",
      sortable: false
    },
    {
      text: "household_1car",
      align: "end",
      value: "PercP21_RP_VOIT1",
      width: "100px",
      sortable: false
    },
    {
      text: "household_2pcar",
      align: "end",
      value: "PercP21_RP_VOIT2P",
      width: "100px",
      sortable: false
    },
    {
      text: "worker_in_zone",
      align: "end",
      value: "PercP21_ACTOCC_ZONE",
      width: "100px",
      sortable: false,
      epci_only: true
    }
  ]
};

const MAKI_ICONS = [
  "aerialway-45",
  "airfield-45",
  "airport-45",
  "alcohol-shop-45",
  "american-football-45",
  "amusement-park-45",
  "animal-shelter-45",
  "aquarium-45",
  "arrow-45",
  "art-gallery-45",
  "attraction-45",
  "bakery-45",
  "bank-45",
  "bank-JP-45",
  "bar-45",
  "barrier-45",
  "baseball-45",
  "basketball-45",
  "bbq-45",
  "beach-45",
  "beer-45",
  "bicycle-45",
  "bicycle-share-45",
  "blood-bank-45",
  "bowling-alley-45",
  "bridge-45",
  "building-45",
  "building-alt1-45",
  "bus-45",
  "cafe-45",
  "campsite-45",
  "car-45",
  "car-rental-45",
  "car-repair-45",
  "casino-45",
  "castle-45",
  "castle-JP-45",
  "caution-45",
  "cemetery-45",
  "cemetery-JP-45",
  "charging-station-45",
  "cinema-45",
  "circle-45",
  "circle-stroked-45",
  "city-45",
  "clothing-store-45",
  "college-45",
  "college-JP-45",
  "commercial-45",
  "communications-tower-45",
  "confectionery-45",
  "construction-45",
  "convenience-45",
  "cricket-45",
  "cross-45",
  "dam-45",
  "danger-45",
  "defibrillator-45",
  "dentist-45",
  "diamond-45",
  "doctor-45",
  "dog-park-45",
  "drinking-water-45",
  "elevator-45",
  "embassy-45",
  "emergency-phone-45",
  "entrance-45",
  "entrance-alt1-45",
  "farm-45",
  "fast-food-45",
  "fence-45",
  "ferry-45",
  "ferry-JP-45",
  "fire-station-45",
  "fire-station-JP-45",
  "fitness-centre-45",
  "florist-45",
  "fuel-45",
  "furniture-45",
  "gaming-45",
  "garden-45",
  "garden-centre-45",
  "gift-45",
  "globe-45",
  "golf-45",
  "grocery-45",
  "hairdresser-45",
  "harbor-45",
  "hardware-45",
  "heart-45",
  "heliport-45",
  "highway-rest-area-45",
  "home-45",
  "horse-riding-45",
  "hospital-45",
  "hospital-JP-45",
  "hot-spring-45",
  "ice-cream-45",
  "industry-45",
  "information-45",
  "jewelry-store-45",
  "karaoke-45",
  "landmark-45",
  "landmark-JP-45",
  "landuse-45",
  "laundry-45",
  "library-45",
  "lighthouse-45",
  "lighthouse-JP-45",
  "lodging-45",
  "logging-45",
  "marker-45",
  "marker-stroked-45",
  "mobile-phone-45",
  "monument-45",
  "monument-JP-45",
  "mountain-45",
  "museum-45",
  "music-45",
  "natural-45",
  "observation-tower-45",
  "optician-45",
  "paint-45",
  "park-45",
  "park-alt1-45",
  "parking-45",
  "parking-garage-45",
  "pharmacy-45",
  "picnic-site-45",
  "pitch-45",
  "place-of-worship-45",
  "playground-45",
  "police-45",
  "police-JP-45",
  "post-45",
  "post-JP-45",
  "prison-45",
  "racetrack-45",
  "racetrack-boat-45",
  "racetrack-cycling-45",
  "racetrack-horse-45",
  "rail-45",
  "rail-light-45",
  "rail-metro-45",
  "ranger-station-45",
  "recycling-45",
  "religious-buddhist-45",
  "religious-christian-45",
  "religious-jewish-45",
  "religious-muslim-45",
  "religious-shinto-45",
  "residential-community-45",
  "restaurant-45",
  "restaurant-bbq-45",
  "restaurant-noodle-45",
  "restaurant-pizza-45",
  "restaurant-seafood-45",
  "restaurant-sushi-45",
  "road-accident-45",
  "roadblock-45",
  "rocket-45",
  "school-45",
  "school-JP-45",
  "scooter-45",
  "shelter-45",
  "shoe-45",
  "shop-45",
  "skateboard-45",
  "skiing-45",
  "slaughterhouse-45",
  "slipway-45",
  "snowmobile-45",
  "soccer-45",
  "square-45",
  "square-stroked-45",
  "stadium-45",
  "star-45",
  "star-stroked-45",
  "suitcase-45",
  "swimming-45",
  "table-tennis-45",
  "teahouse-45",
  "telephone-45",
  "tennis-45",
  "theatre-45",
  "toilet-45",
  "town-45",
  "town-hall-45",
  "triangle-45",
  "triangle-stroked-45",
  "veterinary-45",
  "viewpoint-45",
  "village-45",
  "volcano-45",
  "volleyball-45",
  "warehouse-45",
  "waste-basket-45",
  "watch-45",
  "water-45",
  "waterfall-45",
  "watermill-45",
  "wetland-45",
  "wheelchair-45",
  "windmill-45",
  "zoo-45"
];

const ICONS = {
  "t-user": ["user-dark.svg", "#4c85cc", [20, 43]],
  "t-truck": ["truck.svg", "#5b3c11", [29, 27]],
  "t-odt": ["odt.svg", "#bf2877", [28, 28]],
  "t-odt-blue": ["odt-blue.svg", "#3d6482", [28, 28]],
  "t-odt-green": ["odt-green.svg", "#85c287", [28, 28]],
  "t-odt-grey": ["odt-grey.svg", "#c2c2c2", [28, 28]],
  "t-odt-orange": ["odt-orange.svg", "#ffa800", [28, 28]],
  "t-odt-red": ["odt-red.svg", "#ff0000", [28, 28]],
  "t-bike": ["bike.svg", "#85c287", [30, 18]],
  "t-station": ["station.svg", "#992ea4", [10, 20]],
  "t-station-circle": ["station-circle.svg", "#000000", [25, 25]],
  "t-station-circle-black": ["station-circle-black.svg", "#000000", [25, 25]],
  "t-ufo": ["ufo.svg", "#bfbfbf", [30, 20]],
  "t-car": ["car.svg", "#ff0000", [25, 21]],
  "t-car-grey": ["car-grey.svg", "#c2c2c2", [25, 21]],
  "t-car-green": ["car-green.svg", "#85c287", [25, 21]],
  "t-car-blue": ["car-blue.svg", "#3d6482", [25, 21]],
  "t-kick-scooter": ["kick-scooter-dark.svg", "#85c287", [24, 22]],
  "t-scooter": ["scooter.svg", "#85c287", [29, 17]],
  "t-bus": ["bus-dark.svg", "#bfcb51", [29, 30]],
  "t-tram": ["tram-dark.svg", "#42b126", [24, 36]],
  "t-subway": ["subway.svg", "#0ca86c", [28, 34]],
  "t-train": ["train.svg", "#335a28", [27, 34]],
  "t-stop_point": ["stop_point.svg", "#85c287", [10, 20]],
  "t-school-backpack": ["school-backpack.svg", "#3d6482", [23, 30]],
  "t-school-backback-green": ["school-backpack-green.svg", "#85c287", [22, 29]],
  "t-santa": ["santa.svg", "#000000", [200, 200]],
  "t-gift": ["gift.svg", "#000000", [30, 30]],
  "t-elf": ["elf.svg", "#000000", [70, 200]]
};

// list of icons for objects in map
/**const ICONS = [
  ["user-dark.svg.png", "t-user"],
  ["user-01.svg.png", "t-user-01"],
  ["user-02.svg.png", "t-user-02"],
  ["user-03.svg.png", "t-user-03"],
  ["user-04.svg.png", "t-user-04"],
  ["user-05.svg.png", "t-user-05"],
  ["user-06.svg.png", "t-user-06"],
  ["user-07.svg.png", "t-user-07"],
  ["user-08.svg.png", "t-user-08"],
  ["user-09.svg.png", "t-user-09"],
  ["user-10.svg.png", "t-user-10"],
  ["truck.svg.png", "t-truck"],
  ["odt.svg.png", "t-odt"],
  ["odt-blue.svg.png", "t-odt-blue"],
  ["odt-green.svg.png", "t-odt-green"],
  ["odt-grey.svg.png", "t-odt-grey"],
  ["odt-orange.svg.png", "t-odt-orange"],
  ["odt-red.svg.png", "t-odt-red"],
  ["bike.svg.png", "t-bike"],
  ["station.svg.png", "t-station"],
  ["station-circle.svg.png", "t-station-circle"],
  ["station-circle-black.svg.png", "t-station-circle-black"],
  ["ufo.svg.png", "t-ufo"],
  ["car.svg.png", "t-car"],
  ["car-grey.svg.png", "t-car-grey"],
  ["car-green.svg.png", "t-car-green"],
  ["car-blue.svg.png", "t-car-blue"],
  ["kick-scooter-dark.svg.png", "t-kick-scooter"],
  ["scooter.svg.png", "t-scooter"],
  ["bus-dark.svg.png", "t-bus"],
  ["tram-dark.svg.png", "t-tram"],
  ["subway.svg.png", "t-subway"],
  ["train.svg.png", "t-train"],
  ["stop_point.svg.png", "t-stop_point"],
  ["school-backpack-green.svg.png", "t-school-backback-green"]
];*/

const DECK_PT_COLORS = {
  "t-user": {
    color: [61, 100, 130],
    radius: 3
  },
  "t-bus": {
    color: [133, 194, 135],
    radius: 20
  },
  "t-tram": {
    color: [133, 194, 135],
    radius: 20
  },
  "t-subway": {
    color: [133, 194, 135],
    radius: 20
  },
  "t-train": {
    color: [133, 194, 135],
    radius: 20
  },
  "t-odt": {
    color: [133, 194, 135],
    radius: 20
  },
  "t-bike": {
    color: [133, 194, 135],
    radius: 20
  },
  "t-kick-scooter": {
    color: [133, 194, 135],
    radius: 20
  },
  "t-scooter": {
    color: [133, 194, 135],
    radius: 20
  },
  "t-car": {
    color: [255, 0, 0],
    radius: 20
  },
  "t-station": {
    color: [97, 163, 188],
    radius: 20
  },
  "t-stop_point": {
    color: [97, 163, 188],
    radius: 20
  },
  "t-truck": {
    color: [0, 0, 0],
    radius: 20
  }
};

// Scenario status color
const SCENARIO_STATUS_COLORS = {
  QUEUED: "grey darken-2",
  STARTING: "lime",
  RUNNING: "amber",
  SUCCESS: "green",
  ERROR: "red"
};

// tabs slider
const SLIDER_COLOR = "secondary";
const SLIDER_SIZE = "6";

// icons directory
const ICONS_PATH = "static/icons/";

// layers for menu
const BASE_LAYERS = [
  {
    label: "map_layers.mapnik",
    name: "osm-classic",
    tiles: "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution: "&trade; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
  },
  {
    label: "ign.plan",
    name: "ign-plan",
    display: false,
    visibility: "none",
    tiles:
      "https://data.geopf.fr/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/png" +
      "&LAYER=GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
    attribution: "IGN-F/Geoportail"
  },
  {
    label: "ign.ortho",
    name: "ign-orthophotos",
    tiles:
      "https://data.geopf.fr/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/jpeg" +
      "&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
    attribution: "IGN-F/Geoportail"
  },
  {
    label: "map_layers.transports",
    name: "osm-transport",
    tiles: "https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=c1dc9caf209d4339bc5edf6a6b288574",
    attribution:
      "&trade; <a href='http://www.thunderforest.com/'>Thunderforest</a>, &trade; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
  },
  {
    label: "map_layers.cyclosm",
    name: "osm-cycling",
    tiles: "https://a.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png",
    attribution: "&trade; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
  },
  {
    label: "map_layers.maptiler",
    name: "maptiler-dataviz",
    tiles: "https://api.maptiler.com/maps/dataviz/{z}/{x}/{y}.png?key=ezj7sarQ0SUnnHeyIblg",
    attribution:
      '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
    tileSize: 512
  },
  {
    label: "map_layers.dark_map",
    name: "carto-dark",
    tiles: "https://basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png",
    attribution:
      "&trade; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors &trade; <a href='https://carto.com/attributions'>CARTO</a>"
  }
];

// buttons options for plotly plots
const MODE_BAR_BUTTONS = [["toImage"]];

// line width depending on route type for gtfs routes geometry
const ROUTE_TYPE_LINE_WIDTH = [
  "case",
  ["==", ["get", "route_type"], 0], // tram
  4,
  ["==", ["get", "route_type"], 1], // subway
  4,
  ["==", ["get", "route_type"], 2], // train
  4,
  1.5 // others (bus, ...)
];

// data layers properties labels

const DATA_LAYERS_PROP_LABELS = {
  insee_bpe: {
    SDOM_LIBELLE: "Sous-domaine appartenance équipement",
    TYPEQU_LIBELLE: "Type équipement"
  },
  insee_rp_voit_iris: {
    NOM_COM: "Nom de commune",
    P21_MEN: "Nombre de ménages",
    NOM_IRIS: "Nom IRIS",
    CODE_IRIS: "Code IRIS",
    INSEE_COM: "Code INSEE",
    PercP21_RP_VOIT0: "% 0 voiture",
    PercP21_RP_VOIT1: "% 1 voiture",
    PercP21_RP_VOIT1P: "% 1 voiture et +",
    PercP21_RP_VOIT2P: "% 2 voitures et +"
  },
  insee_rp_voit_commune: {
    NOM: "Nom de commune",
    P21_MEN: "Nombre de ménages",
    INSEE_COM: "Code INSEE",
    PercP21_RP_VOIT0: "% 0 voiture",
    PercP21_RP_VOIT1: "% 1 voiture",
    PercP21_RP_VOIT1P: "% 1 voiture et +",
    PercP21_RP_VOIT2P: "% 2 voitures et +"
  },
  insee_rp_age_iris: {
    NOM_COM: "Nom de commune",
    P21_POP: "Population totale",
    NOM_IRIS: "Nom IRIS",
    CODE_IRIS: "Code IRIS",
    INSEE_COM: "Code INSEE",
    PercP21_POP75P: "Population 75 ans et +",
    PercP21_POP0014: "Population 0-14 ans",
    PercP21_POP1529: "Population 15-29 ans",
    PercP21_POP3044: "Population 30-44 ans",
    PercP21_POP4559: "Population 45-59 ans",
    PercP21_POP6074: "Population 60-74 ans"
  },
  insee_rp_age_commune: {
    NOM: "Nom de commune",
    P21_POP: "Population totale",
    INSEE_COM: "Code INSEE",
    PercP21_POP90P: "Population 90 ans et +",
    PercP21_POP0014: "Population 0-14 ans",
    PercP21_POP1529: "Population 15-29 ans",
    PercP21_POP3044: "Population 30-44 ans",
    PercP21_POP4559: "Population 45-59 ans",
    PercP21_POP6074: "Population 60-74 ans",
    PercP21_POP7589: "Population 75-89 ans"
  },
  insee_rp_activity_iris: {
    NOM_COM: "Nom de commune",
    P21_POP: "Population totale",
    NOM_IRIS: "Nom IRIS",
    CODE_IRIS: "Code IRIS",
    INSEE_COM: "Code INSEE",
    PercC21_POP15P_CS1: "% agriculteurs",
    PercC21_POP15P_CS2: "% artisants",
    PercC21_POP15P_CS3: "% cadres",
    PercC21_POP15P_CS4: "% prof. inter.",
    PercC21_POP15P_CS5: "% employés",
    PercC21_POP15P_CS6: "% ouvriers",
    PercC21_POP15P_CS7: "% retraités"
  },
  insee_rp_activity_commune: {
    NOM: "Nom de commune",
    P21_POP: "Population totale",
    INSEE_COM: "Code INSEE",
    PercC21_POP15P_CS1: "% agriculteurs",
    PercC21_POP15P_CS2: "% artisants",
    PercC21_POP15P_CS3: "% cadres",
    PercC21_POP15P_CS4: "% prof. inter.",
    PercC21_POP15P_CS5: "% employés",
    PercC21_POP15P_CS6: "% ouvriers",
    PercC21_POP15P_CS7: "% retraités"
  },
  insee_rp_population_iris: {
    NOM: "Nom de commune",
    P21_POP: "Population totale",
    NOM_IRIS: "Nom IRIS",
    CODE_IRIS: "Code IRIS",
    INSEE_COM: "Code INSEE",
    P21_POP_DENSITY: "Densité de population (hab./km²)"
  },
  insee_rp_population_commune: {
    NOM: "Nom de commune",
    P21_POP: "Population totale",
    INSEE_COM: "Code INSEE",
    P21_POP_DENSITY: "Densité de population (hab./km²)"
  },
  sirene: {
    siren: "SIREN",
    siret: "SIRET",
    nafLabelLevel2: "Activité",
    nafLabelDetail: "Activité détaillée",
    labelTrancheEffectifs: "Effectifs",
    denominationUniteLegale: "Dénomination"
  }
};

export {
  KITE_CONTACT,
  COLORS,
  MAPCENTER,
  MAPZOOM,
  MAPPITCH,
  TRACE_INPUT_KEYS,
  MAX_MOVING_AGENTS,
  TABHEADERS,
  ICONS,
  ICONS_PATH,
  BASE_LAYERS,
  MODE_BAR_BUTTONS,
  DECK_PT_COLORS,
  MAKI_ICONS,
  ROUTE_TYPE_LINE_WIDTH,
  SCENARIO_STATUS_COLORS,
  SLIDER_COLOR,
  SLIDER_SIZE,
  ZONING_YEAR,
  IRIS_ZONING_YEAR,
  DATA_LAYERS_PROP_LABELS
};
