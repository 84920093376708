<!--
  Dialog to configure isochrones parameters (network)
-->

<template>
  <v-dialog v-model="dialog.isochrones" persistent max-width="450px">
    <v-card>
      <v-overlay opacity=".9" color="grey lighten-1" absolute :value="async.overlay" z-index="4">
        <div class="d-flex flex-column align-center">
          <h1>{{ $t("visualisation.loading") }}</h1>
          <v-progress-circular :width="6" color="primary" indeterminate />
        </div>
      </v-overlay>
      <v-card-title class="headline grey lighten-2">{{ $t("network.pt_isochrones.title") }}</v-card-title>
      <v-card-text class="px-6 pt-6 pb-0">
        <h3>{{ $t("network.pt_isochrones.stop") + ": " }} {{ isochrones.stop_name }}</h3>
        <v-row>
          <v-col md="5">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="time"
                  :label="$t('network.pt_isochrones.start_time')"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu"
                v-model="time"
                full-width
                header-color="primary"
                format="24hr"
                @click:minute="$refs.menu.save(time)"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col md="7">
            <v-text-field
              prepend-icon="transfer_within_a_station"
              v-model="buffer_time"
              :label="$t('network.pt_isochrones.buffer_time')"
              type="number"
              min="1"
              max="60"
              :suffix="$t('network.pt_isochrones.suffix')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card-actions class="justify-end">
          <v-btn color="primary" text @click="dialog.isochrones = false">{{ $t("basic_dialogs.cancel") }}</v-btn>
          <v-btn color="primary" text @click="$whale.runIfHasAccess('NETWORK', drawIsochrones)">
            {{ $t("basic_dialogs.execute") }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapState, mapActions } from "vuex";

export default Vue.component("pt-isochrones", {
  components: {},
  data: function () {
    return {
      buffer_time: 10,
      time: "08:00",
      menu: false
    };
  },
  computed: {
    ...mapState("network", ["dialog", "isochrones", "currentNetworkView"]),
    ...mapState("layers", ["allLayers"]),
    ...mapState(["async", "language"]),
    start_time() {
      const time = this.time.split(":");
      const hour = parseInt(time[0]);
      const minute = parseInt(time[1]);
      return Math.round(hour * 3600 + minute * 60);
    }
  },
  methods: {
    ...mapActions("layers", ["setLayersData", "setLayersVisibility"]),
    /**
     * Call API to build isochrone with selected gtfs, date and parameters using Raptor. Update isochrone public transport layer data.
     */
    async drawIsochrones() {
      // update legend
      let isochrones_layer = this.allLayers["isochrone-public-transport"];
      let new_legend = JSON.parse(JSON.stringify(isochrones_layer.legend));
      new_legend.text = this.$t("props_mapping.layer_specifics.isochrones", {
        stop: this.isochrones.stop_name,
        date: this.currentNetworkView.date.text,
        hour: this.time
      });
      isochrones_layer.legend = new_legend;

      // close the isochrone dialog and show legend
      this.dialog.isochrones = false;

      // get isochrones as a geojson
      let isochrones = await this.$whale.getPublicTransportIsochrones(
        this.currentNetworkView.gtfs_index,
        this.currentNetworkView.date,
        this.isochrones.stop_id,
        this.start_time,
        this.buffer_time
      );

      // set the geojson as the isochrone layer
      this.setLayersData({ ids: "isochrone-public-transport", data: isochrones });
      this.setLayersVisibility({ ids: "isochrone-public-transport", isVisible: true });
    }
  }
});
</script>
