/**
 * This module contains models and functions dedicated to the manipulation of flows.
 *
 * TODO : this module should be reworked using classes, which would help refactor a lot of code
 * and avoid the if/else cases in half the functions.
 * We could also move in the initialisation/validation functions (for instance flowsViewFromFileInput).
 * It would be preferable to avoid moving these functions to the FlowsView classes,
 * in order to keep the data related functions separated from the Kite Views (as we did with the layers views)
 *
 * The processing pipeline of the flows is the following:
 *  - Read flows data (in io.ts, performs some validation)
 *  - Data pre-processing
 *    - [STARLING] : describeUsers
 *  - filters initialisation (initial filters are empty and filtered data is identical to the original)
 *    - filtersFromFlowView
 *  - evaluation of filtered data (at creation and upon filters update. All displays (layers, plots, ..) are related to the filtered data)
 *    - [STARLING] filterStarlingData
 *    - [FLOWMAP]
 *        - filterFlowmapData (apply filters)
 *        - aggregateFlowmapData (aggregate flows with same OD)
 *  - evaluate statistics
 *    - [STARLING] starlingStats
 *    - [FLOWMAP] flowmapStats
 *  - display plots
 *    - getFlowsPlot
 *  - [FLOWMAP] evaluate a summary table with one row per OD
 *    - computeFlowMapSummaryTable
 */

import { getRandomSubarray, geodistanceturf, formatText, groupByBin, sortObjectByKeys } from "@/functions-tools";
import { FlowsView } from "@/models";
import DataFrame from "dataframe-js";
import { COLORS } from "@/global";

// flows models

interface FlowMapData {
  // nodes of the FlowMap graph
  locations: Array<{
    id: any;
    name: string;
    lat: number; // latitude
    lon: number; // longitude
  }>;
  // arcs of the FlowMap graph
  flows: Array<{
    origin: any; // id of the flow's origin
    dest: any; // id of the flow's destination
    count: number; // flow's size
    [key: string]: any; // other attributes
  }>;
}

interface StarlingFlowsData {
  // geojson features
  features: Array<any>;
  [key: string]: any; // additional data
}

interface FlowsFilters {
  // filters on discrete and continuous attribute values
  attributes: {
    discrete: Array<DiscreteAttributeFilter>;
    continuous: Array<ContinuousAttributeFilter>;
  };
  // kept proportion of the flows
  proportion: number;
  // minimum flow valye
  minimumFlow: number;
  // random seed
  seed: string;
  // selected FlowMap location
  selectedLocationId?: any;
}

interface AttributeFilter {
  category?: "continuous" | "discrete";
  attribute: string;
}

interface DiscreteAttributeFilter extends AttributeFilter {
  category?: "discrete";
  values: Array<any>;
  selected_values: Array<any>;
}

interface ContinuousAttributeFilter extends AttributeFilter {
  category?: "continuous";
  min: number;
  max: number;
  selected_min_max: [number, number];
}

// flows data pre processing

/**
 * Compute distance and hour (departure time) for each user
 * Changes are done inplace.
 *
 * @param {StarlingFlowsData} starling_flows starling data
 */
function describeUsers(starling_flows: StarlingFlowsData) {
  for (let i = 0; i < starling_flows.features.length; i++) {
    let row = starling_flows.features[i];
    let geometry = row.geometry;
    // compute hour attribute
    row.properties.hour = Math.trunc(row.properties.origin_time / 3600);
    // compute distance attributes (for statistics)
    row.properties.raw_distance = geodistanceturf(
      geometry.coordinates[0][1],
      geometry.coordinates[0][0],
      geometry.coordinates[1][1],
      geometry.coordinates[1][0]
    );
    row.properties.distance = Math.trunc(row.properties.raw_distance);
  }
}

// flows filters creation

/**
 * Evaluate available filters for the given FlowsView
 *
 * @param flow_view
 * @returns
 */
function filtersFromFlowView(flow_view: FlowsView): FlowsFilters {
  // create empty filters object
  let filters = emptyFlowsFilters();

  // get flows properties
  let attributes = attributesOfFlowsData(flow_view.type, flow_view.data);

  // add attribute filters, either discrete or continuous
  attributes.forEach(attribute => {
    let attribute_filter = categoriseAttribute(attribute, flow_view.data, flow_view.type);
    if (attribute_filter) {
      if (attribute_filter.category == "discrete") {
        filters.attributes.discrete.push(attribute_filter);
      } else if (attribute_filter.category == "continuous") {
        filters.attributes.continuous.push(attribute_filter);
      } else {
        throw new Error("Unknown attribute category");
      }
      delete attribute_filter["category"];
    }
  });
  return filters;
}

/**
 * Create empty flows filters
 * @returns
 */
function emptyFlowsFilters(): FlowsFilters {
  return {
    attributes: {
      discrete: [],
      continuous: []
    },
    proportion: 100,
    minimumFlow: 0,
    seed: "42"
  };
}

/**
 * Get a list of attributes available on the flows.
 * Reserved attributes such as origin, dest, origin_time, proportion, are ignored.
 *
 * @param flows_type
 * @param data
 * @returns
 */
function attributesOfFlowsData(flows_type, data) {
  let attributes = null;
  if (flows_type == "STARLING") {
    attributes = Object.keys(data.features[0].properties);
  } else if (flows_type == "FLOWMAP") {
    attributes = Object.keys(data.flows[0]);
  } else {
    throw new Error("Unknown flow type");
  }
  attributes = attributes.filter(
    x =>
      ![
        "origin",
        "dest",
        "count",
        "agent_id",
        "raw_distance",
        "origin_time",
        "origin_lat",
        "origin_lon",
        "destination_lat",
        "destination_lon",
        "proportion"
      ].includes(x)
  );
  return attributes;
}

/**
 * Detect if custom attribute is discret or continuous
 * Create filter with possible/selected values
 */
function categoriseAttribute(attribute, data, flows_type): DiscreteAttributeFilter | ContinuousAttributeFilter {
  // evaluate set of values taken by the attribute
  let values;
  switch (flows_type) {
    case "STARLING":
      values = [...new Set(data["features"].map(o => o["properties"][attribute]))];
      break;
    case "FLOWMAP":
      values = [...new Set(data.flows.map(o => o[attribute]))];
      break;
    default:
      throw new Error("Unknown flows type");
  }

  // decide if attribute values are discrete or continuous
  // ignore single value attributes and attributes that are not present on all flows
  if (values.length > 1 && !values.includes(undefined)) {
    // look at the first one, attribute values are expected to have all the same type
    let category: "continuous" | "discrete" = typeof values[0] == "number" ? "continuous" : "discrete";
    let attribute_filter;
    if (category == "discrete") {
      attribute_filter = {
        category,
        attribute,
        values: values.sort(compareDiscreteAttributes),
        selected_values: values
      };
    } else {
      let minimum = Math.floor(Math.min.apply(null, values));
      let maximum = Math.ceil(Math.max.apply(null, values));
      attribute_filter = {
        category,
        attribute,
        min: minimum,
        max: maximum,
        selected_min_max: [minimum, maximum]
      };
    }
    return attribute_filter;
  }
}

// sort values
function compareDiscreteAttributes(a, b) {
  // Use toUpperCase() to ignore character casing
  const bandA = formatText(a).toUpperCase();
  const bandB = formatText(b).toUpperCase();

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
}

function attributesFromFilters(filters: FlowsFilters) {
  let attributes = [];
  filters.attributes.discrete.forEach(filter => {
    attributes.push(filter.attribute);
  });
  filters.attributes.continuous.forEach(filter => {
    attributes.push(filter.attribute);
  });
  return attributes;
}

// flows filter application

/**
 * Filter Starling data
 *
 * @param {StarlingFlowsData} starling_flows
 * @param {FlowsFilters} filters
 * @returns filtered starling flows
 */
function filterStarlingData(starling_flows: StarlingFlowsData, filters: FlowsFilters): StarlingFlowsData {
  let features = starling_flows.features;

  // random sampling
  features = getRandomSubarray(features, Math.floor((filters.proportion / 100) * features.length), filters.seed);

  // discrete attributes filter
  let discrete_filters = filters.attributes.discrete;
  for (let i = 0; i < discrete_filters.length; i++) {
    if (discrete_filters[i].selected_values) {
      features = features.filter(x =>
        discrete_filters[i].selected_values.includes(x.properties[discrete_filters[i].attribute])
      );
    }
  }

  // continuous attributes filter
  let continuous_filters = filters.attributes.continuous;
  for (let j = 0; j < continuous_filters.length; j++) {
    let attribute_min = continuous_filters[j].selected_min_max[0];
    let attribute_max = continuous_filters[j].selected_min_max[1];
    features = features.filter(
      x =>
        x.properties[continuous_filters[j].attribute] >= attribute_min &&
        x.properties[continuous_filters[j].attribute] <= attribute_max
    );
  }
  // return a copy of the Starling data with new features
  let result = Object.assign({}, starling_flows);
  result.features = features;
  return result;
}

/**
 * Filter FlowMap data
 *
 * @param {FlowMapData} flowmap_data original flowmap data to be filtered
 * @param {FlowsFilters} filters filters applied to the original data
 * @param {any} selectedLocationId id of the selected location, null otherwise
 *
 * @returns filtered object
 */
function filterFlowmapData(flowmap_data: FlowMapData, filters: FlowsFilters, selectedLocationId): FlowMapData {
  let flows = flowmap_data.flows;

  // discrete attributes filter
  let discrete_filters = filters.attributes.discrete;
  for (let i = 0; i < discrete_filters.length; i++) {
    if (discrete_filters[i].selected_values) {
      flows = flows.filter(f => discrete_filters[i].selected_values.includes(f[discrete_filters[i].attribute]));
    }
  }

  // continuous attributes filter
  let continuous_filters = filters.attributes.continuous;
  for (let j = 0; j < continuous_filters.length; j++) {
    let attribute_min = continuous_filters[j].selected_min_max[0];
    let attribute_max = continuous_filters[j].selected_min_max[1];
    flows = flows.filter(
      f => f[continuous_filters[j].attribute] >= attribute_min && f[continuous_filters[j].attribute] <= attribute_max
    );
  }

  // selected location filter
  if (selectedLocationId !== null) {
    flows = flows.filter(f => selectedLocationId == f["origin"] || selectedLocationId == f["dest"]);
  }

  // evaluate sum of flows by OD
  let od_sums = evaluateFlowmapSumsByOd(flows);

  // add cumulative sum
  flows = addProportion(flows);

  // filter flows based on proportion and minimum flows filters
  flows = flows.filter(
    x => x.proportion >= 100 - filters.proportion && od_sums[`${x.origin}-${x.dest}`] >= filters.minimumFlow
  );
  for (let i = 0; i < flows.length; i++) {
    delete flows[i].proportion;
  }

  // return a copy with the new flows, same locations
  return {
    locations: flowmap_data.locations,
    flows
  };
}

/**
 *
 * @param flows FlowMap flows
 * @returns Object containing { "origin-dest": flows_sum } pairs
 */
function evaluateFlowmapSumsByOd(flows) {
  // create a DataFrame from flows
  let flows_df = new DataFrame(flows);

  // evaluate flows count for origin/destination whatever attributes are
  const grouped_flows_DF = flows_df
    .groupBy("origin", "dest")
    .aggregate(group => group.stat.sum("count"))
    .rename("aggregation", "sum");

  // store results in an object with OD as keys
  let od_sums = {};
  for (let od of grouped_flows_DF.toCollection()) {
    od_sums[`${od.origin}-${od.dest}`] = od.sum;
  }
  return od_sums;
}

/**
 * Add cumulative proportion to FlowMap flows.
 *
 * TODO : clarify how this function works and what kind of
 * proportion is evaluated.
 * Final filter is flowmap_flows.filter(x => x.proportion >= 100 - filters.proportion)
 *
 * @param flowmap_flows flowmap data flows
 */
function addProportion(flowmap_flows) {
  // sort flows by count (inplace)
  flowmap_flows.sort((a, b) => (a.count > b.count ? 1 : b.count > a.count ? -1 : 0));

  // evaluate total flows count
  const total_flows_count = flowmapFlowsSum(flowmap_flows);

  // evaluate proportion for each flow
  let cumulativeSum = 0;
  for (let i = 0; i < flowmap_flows.length; i++) {
    cumulativeSum = flowmap_flows[i]["count"] + cumulativeSum;
    flowmap_flows[i].proportion = (100 / 10000) * Math.round((10000 * cumulativeSum) / total_flows_count);
  }
  return flowmap_flows;
}

// plots and tables

/**
 * Transform aggregated and filtered flowmap data by merging flows and locations.
 *
 * @param {FlowMapData} flowmap_data flowmap data
 * @returns {FlowMapData}
 */
function computeFlowMapSummaryTable(flowmap_data: FlowMapData) {
  let flows = flowmap_data.flows;

  const total_flows_count = flowmapFlowsSum(flows);
  return flows.map(flow => {
    return {
      origin: flowmap_data.locations.filter(l => l.id == flow.origin)[0].name, // substring(0, 30)
      dest: flowmap_data.locations.filter(l => l.id == flow.dest)[0].name,
      percentage: Math.round((1000 * flow.count) / total_flows_count) / 10,
      count: Math.round(flow.count)
    };
  });
}

const plotLayout = {
  height: "350",
  yaxis: {
    title: "",
    autorange: true
  },
  showlegend: false,
  margin: {
    l: 50,
    r: 20,
    b: 30,
    t: 50,
    pad: 0
  },
  xaxis: {
    title: "",
    tickangle: 0,
    tickvals: [],
    ticksuffix: "",
    tickfont: {
      size: 8
    },
    type: undefined
  },
  bargap: 0.02
};

/**
 * Evaluate a barplot describing the distribution of a flows' attribute.
 *
 * @param type FlowsView type
 * @param data flows data, already filtered
 * @param attribute attribute which values are studied
 * @param attributeIsContinuous indicates if the attribute takes continuous values
 * @param percentage indicates if plot unit should be percentages
 * @param interval interval size for continuous values
 *
 * @returns { data, layout } object containing plot contents
 */
function getFlowsPlot(type, data, attribute, attributeIsContinuous, percentage, interval?) {
  if (!attribute) {
    return {
      data: [],
      layout: null
    };
  }

  //group flows by attribute value
  let groupedData = sortObjectByKeys(sumFlowsByAttributeValues(data, type, attribute));
  let x = Object.keys(groupedData);
  let y: number[] = Object.values(groupedData);

  // group values in same size bins
  if (attributeIsContinuous) {
    let values = groupByBin(x, y, interval);
    x = values[0];
    y = values[1];
  }

  // convert y values to percentage
  if (percentage && y.length > 0) {
    const sum = Object.values(y).reduce((a: number, b: number) => a + b);
    y = y.map(x => x / sum);
  }

  let plotData = [
    {
      x: x,
      y: y,
      type: "bar",
      marker: {
        color: COLORS.primary
      }
    }
  ];

  // evaluate plot layout

  // copy layout base, add title
  let layout = JSON.parse(JSON.stringify(plotLayout));
  layout.title = formatText(attribute);

  // setup axis values and units
  if (percentage) {
    layout.yaxis["tickformat"] = ".0%";
    layout.yaxis["title"] = "";
  }
  layout.xaxis.tickvals = x.filter((v, i, a) => a.indexOf(v) === i);
  if (layout.xaxis.tickvals.length > 50) {
    let step = 5;
    if (layout.xaxis.tickvals.length > 100) {
      step = 10;
    }
    let maximumTicks = Math.max(...layout.xaxis.tickvals);
    let newMaximumTicks = (Math.floor(maximumTicks / step) + 1) * step;
    layout.xaxis.tickvals = [];
    for (let i = 0; i <= newMaximumTicks / step; i++) {
      layout.xaxis.tickvals.push(i * step);
    }
  }

  // manually disable plotly axis type autoset to date format
  if (!attributeIsContinuous) {
    layout.xaxis.type = "category";
  }
  return {
    data: plotData,
    layout
  };
}

// statistics

/**
 * Evaluate statistics on Starling flows
 * @param data
 * @returns
 */
function starlingStats(data: StarlingFlowsData) {
  let nb_groups = 0;
  let nb_journeys = 0;
  let average = 0;
  for (let i = 0; i < data.features.length; i++) {
    let feature = data.features[i];
    let group_number = 1;
    if ("number" in feature.properties) {
      group_number = feature.properties.number;
    }
    nb_groups += 1;
    nb_journeys += group_number;
    average += group_number * feature.properties.raw_distance;
  }
  average = average / nb_journeys;

  let stats = {
    volume: nb_journeys,
    groups: nb_groups
  };
  if (!isNaN(average)) {
    stats["average_distance"] = average.toFixed(1);
  }

  return stats;
}

/**
 * Evaluate statistics on FlowMap data
 * @param {FlowMapData} data
 * @returns
 */
function flowmapStats(data: FlowMapData) {
  let sum = Math.round(
    data.flows.reduce(function (sum, flow) {
      return sum + flow.count;
    }, 0)
  );
  let pairs = data.flows.length;

  let stats = {
    volume: sum,
    pairs
  };
  return stats;
}

// utils

/**
 * Sum flows by attribute values.
 *
 * @param {Dict} data data
 * @param {String} source source
 * @param {String} attribute selected attribute
 */
function sumFlowsByAttributeValues(data, source, attribute) {
  let grouped = Object.assign({}, data); // copy
  if (source == "STARLING") {
    grouped = grouped.features.reduce(function (r, o) {
      var key = o.properties[attribute];
      let nb = 1;
      if (o.properties.number) {
        nb = o.properties.number;
      }
      if (!r[key]) {
        r[key] = nb;
      } else {
        r[key] += nb;
      }
      return r;
    }, {});
  } else if (source == "FLOWMAP") {
    grouped = grouped.flows.reduce(function (r, o) {
      // new key with all kept attributes to aggregate rows
      var key = o[attribute];
      if (!r[key]) {
        r[key] = o.count;
      } else {
        r[key] += o.count;
      }
      return r;
    }, {});
  }
  return grouped;
}

/**
 * Aggregate FlowMap data by grouping the flows on their ODs and attributes.
 * If no list of attributes is provided, flows are grouped by ODs only.
 *
 * @param {FlowMapData} flowmap_data flowmap data
 * @param {string[]} attributes list of attributes used to group flows. If not provided, group only on ODs.
 */
function aggregateFlowMapData(flowmap_data: FlowMapData, attributes?: string[]): FlowMapData {
  let flows = flowmap_data.flows;

  var helper = {};
  // group by origin, destination & custom attributes
  flows = flows.reduce(function (acc, flow) {
    // new key with all kept attributes to aggregate rows
    var key = flow.origin + "-" + flow.dest;
    if (attributes) {
      key += "-" + attributes.map(attribute => flow[attribute]).join("-");
    }

    // add flow count to the key count
    if (!helper[key]) {
      helper[key] = Object.assign({}, flow); // create a copy of o
      acc.push(helper[key]);
    } else {
      helper[key].count += flow.count;
    }
    return acc;
  }, []);

  // return a copy with the new flows, same locations
  return {
    locations: flowmap_data.locations,
    flows
  };
}

/**
 * Get total count of FlowMap flows.
 * @param flowmap_flows
 * @returns
 */
function flowmapFlowsSum(flowmap_flows): number {
  return flowmap_flows.reduce(function (a, b) {
    return a + b.count;
  }, 0);
}

/**
 * Round flows volume
 *
 * @param {number} value - Flows raw volume
 * @param {string} language - i18 locale
 * @param {number} round - Number of decimals
 *
 * @returns string
 */
function roundFlows(value, language, round = 1) {
  let volume = value.toLocaleString(language, { maximumFractionDigits: round, minimumFractionDigits: round });
  if (value == 0) {
    volume = "0";
  } else if (value < Math.pow(10, -round)) {
    volume = "< " + Math.pow(10, -round).toString();
  }
  return volume;
}

export {
  filterStarlingData,
  filterFlowmapData,
  describeUsers,
  emptyFlowsFilters,
  attributesOfFlowsData,
  filtersFromFlowView,
  attributesFromFilters,
  sumFlowsByAttributeValues,
  computeFlowMapSummaryTable,
  aggregateFlowMapData,
  getFlowsPlot,
  starlingStats,
  flowmapStats,
  roundFlows,
  FlowMapData,
  StarlingFlowsData,
  FlowsFilters
};
