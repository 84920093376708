<!--
  Data tables with buffer time per direction
-->

<template>
  <kpi-card title="Battement" :suffix="titleSuffixWithDates" :subtitle="subtitle">
    <v-card-actions class="mb-5" v-if="!emptyData">
      <v-row>
        <v-col cols="3">
          <v-select label="Unité" prepend-icon="tune" v-model="unit" :items="units" @change="updateChart()"></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            label="Représentation"
            v-model="representation"
            :items="representations"
            item-text="text"
            item-value="value"
            @change="updateChart"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <div v-if="representation == 'graphic'">
            <v-select
              label="Direction"
              v-model="direction"
              :items="directions"
              item-text="text"
              item-value="value"
              @change="updateChart"
            ></v-select>
          </div>
        </v-col>
      </v-row>
    </v-card-actions>
    <div v-if="representation == 'table' && !emptyData" align="center">
      <v-row>
        <v-col align="center" md="6">
          <capucine-table
            :headers="headers"
            :items="data['table']['0']"
            :loading="loading"
            :title="oneWayName"
            card-width="50vh"
            :exportFileName="export_table_filename_with_context({ direction: '0' })"
          >
          </capucine-table>
        </v-col>
        <v-col align="center" md="6">
          <capucine-table
            :headers="headers"
            :items="data['table']['1']"
            :loading="loading"
            :title="returnName"
            card-width="50vh"
            :exportFileName="export_table_filename_with_context({ direction: '1' })"
          >
          </capucine-table>
        </v-col>
      </v-row>
    </div>
    <div v-if="representation == 'graphic' && !emptyData" align="center">
      <v-card elevation="0">
        <v-overlay opacity="0.5" color="grey lighten-1" absolute :value="loading">
          <div class="d-flex flex-column align-center">
            <h1>Chargement</h1>
            <v-progress-linear color="primary" indeterminate></v-progress-linear>
          </div>
        </v-overlay>
        <apexchart height="600" ref="chart_buffer_time" :options="chartOptions" :series="series"></apexchart>
      </v-card>
    </div>
    <div v-if="emptyData"><b>Pas de données</b></div>
  </kpi-card>
</template>

<script lang="ts">
import Vue from "vue";
import AnalysisKpiMixin from "./analysis_kpi_mixin.vue";

export default Vue.component("buffer_time", {
  mixins: [AnalysisKpiMixin],
  data: () => ({
    kpi_name: "buffer_time",
    representation: "graphic",
    representations: [
      { text: "Tableau", value: "table" },
      { text: "Histogramme", value: "graphic" }
    ],
    unit: "Pourcentage",
    units: ["Pourcentage", "Minutes"],
    table: [],
    series: [
      {
        name: "Théorique",
        type: "bar",
        data: []
      },
      {
        name: "Réalisé",
        type: "bar",
        data: []
      }
    ],
    chartOptions: {
      chart: {
        type: "bar",
        toolbar: {
          show: true
        }
      },
      colors: ["#3D6482", "#85C287"],
      title: {
        align: "center",
        style: {
          fontSize: "25px"
        }
      },
      xaxis: {
        categories: []
      }
    }
  }),
  mounted() {
    if (this.data !== undefined) {
      if (Object.keys(this.data).includes("graphic")) {
        this.updateChart();
      }
    }
  },
  watch: {
    data() {
      this.updateChart();
    }
  },
  computed: {
    // check for empty data for hiding graphic, table and controls
    emptyData() {
      if (this.series !== undefined) {
        if (this.series[0]["data"].length > 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    headers() {
      let format = "";
      let prefix = "";
      if (this.unit == "Pourcentage") {
        format = "%";
        prefix = "taux_";
      }
      let headers = [
        { text: "Tranche horaire", value: "période", align: "left", width: "30%" },
        { text: "Théorique", value: prefix + "battement_théorique", align: "right", width: "20%", format },
        { text: "Réalisé", value: prefix + "battement_réalisé", align: "right", width: "20%", format },
        { text: "Réalisé - Théorique", value: prefix + "écart", align: "right", width: "20%", format: "-" }
      ];
      return headers;
    }
  },
  methods: {
    updateChart() {
      if (this.representation == "graphic") {
        let series = this.series;
        if (this.data !== undefined) {
          if (!Object.keys(this.data).includes("graphics")) {
            series[0].data = this.data[this.representation][this.direction][this.headers[1].value];
            series[1].data = this.data[this.representation][this.direction][this.headers[2].value];
            let chartOptions = this.chartOptions;
            chartOptions["xaxis"]["categories"] = this.data[this.representation][this.direction]["période"];
            if (this.direction == "0") {
              this.title = this.oneWayName;
            } else {
              this.title = this.returnName;
            }
            chartOptions["title"]["text"] = this.title;
            let suffix = "";
            if (this.unit == "Pourcentage") {
              suffix = " %";
            }
            chartOptions["yaxis"] = {
              labels: {
                formatter: function (value) {
                  return value + suffix;
                }
              },
              title: {
                text: this.unit,
                style: {
                  fontSize: "15px"
                }
              }
            };
            if (this.$refs.chart_buffer_time !== undefined) {
              this.$refs.chart_buffer_time.updateSeries(series);
              this.$refs.chart_buffer_time.updateOptions(chartOptions);
            }
          }
        } else {
          this.series = [
            { name: "Théorique", type: "bar", data: [] },
            { name: "Réalisé", type: "bar", data: [] }
          ];
          this.$refs.chart_buffer_time.updateSeries(this.series);
        }
      }
    }
  }
});
</script>
