import store from "./store";
import i18n from "./plugins/lang";
import { getInstance } from "@/api/index";
import { KITE_CONTACT } from "@/global";
import { FlowsView, NetworkView, MapViewItem, DatabaseLayerView, CustomLayerView } from "./models";

class ViewItemError extends Error {
  name: string;
  type: string;
  mode: string;
  constructor(name: string, type: string, mode: string, message?: string) {
    if (message == undefined) {
      let type_readable = i18n.t("map_view.item_types." + type).toString();
      message = i18n.t("map_view.errors.item_error." + mode, { type: type_readable, name: name }).toString();
    }

    super(message);
    this.name = name;
    this.type = type;
    this.mode = mode;
  }
}

async function newMapViewFromCurrentStore(save_to_project = false) {
  let whale = getInstance();

  let map_content = [];

  // get view and their corresponding layer ids
  let zList = store.state.layers.zList;
  let allViews = store.state.layers.allViews;
  let currentFlowsView = store.state.flows.currentFlowsView;
  let flowsLayerId;
  if (currentFlowsView != null) {
    flowsLayerId = currentFlowsView.correspondingLayers()[0];
  }
  let currentNetworkView = store.state.network.currentNetworkView;
  let networkLayerId;
  if (currentNetworkView) {
    networkLayerId = currentNetworkView.correspondingLayers()[0];
  }

  let alert_displayed = {
    FLOWS: false,
    NETWORK: false,
    LAYERS: false
  };
  let view;
  let feature;
  let layer_ids = [];
  // we don't really need to browse the zList anymore
  // now that the layer order is evaluated in a second loop below
  for (const layer_id of zList) {
    view = undefined;
    feature = undefined;
    // get view corresponding to the layer
    if (layer_id == flowsLayerId) {
      view = currentFlowsView;
      feature = "FLOWS";
    } else if (layer_id == networkLayerId) {
      view = currentNetworkView;
      feature = "NETWORK";
    } else if (layer_id in allViews) {
      view = allViews[layer_id];
      feature = "LAYERS";
    }

    if (view) {
      if (whale.runIfHasAccess(feature).access) {
        // create map view item and add it to map view content
        let map_view_item = await view.toMapViewItem(save_to_project);
        if (map_view_item) {
          // add map view item to map content
          map_content.push(map_view_item);
          // store layers corresponding to the view
          layer_ids.push(view.correspondingLayers());
        }
      } else {
        // only display alerts once, and only for the map view preview
        if (!save_to_project && !alert_displayed[feature]) {
          alert(noAccessMessage(view.getViewType()));
          alert_displayed[feature] = true;
        }
      }
    }
  }

  // add order value to each map view item layer options
  layer_ids = layer_ids.flat().sort((layer_id_a, layer_id_b) => {
    let index_a = zList.indexOf(layer_id_a);
    let index_b = zList.indexOf(layer_id_b);

    if (index_a < index_b) {
      return 1;
    } else if (index_b < index_a) {
      return -1;
    } else {
      return 0;
    }
  });
  map_content.forEach(map_view_item => {
    if (map_view_item.layer_options) {
      for (const layer_id in map_view_item.layer_options) {
        let index = layer_ids.indexOf(layer_id);
        if (index == -1) {
          throw new Error("Error while fetching item order");
        }
        map_view_item.layer_options[layer_id].order = index;
      }
    }
  });

  // get map settings (bbox, center, pitch, baseLayer)
  let map = store.state.layers.map;
  let map_view = {
    name: i18n.t("map_view.add.new_map"),
    bbox: {
      zoom: map.getZoom(),
      center: map.getCenter(),
      pitch: map.getPitch()
    },
    base_layer: store.state.layers.baseLayer,
    base_layer_opacity: store.state.layers.baseLayerOpacity,
    content: map_content
  };

  return map_view;
}

async function loadMapView(mapView) {
  // hide existing user layers
  store.dispatch("layers/hideUserLayers");

  // set base layer
  let map = store.state.layers.map;
  store.commit("layers/CHANGE_BASE_LAYER", mapView.base_layer);
  let opacity = mapView.base_layer_opacity;
  if (opacity === undefined) {
    opacity = 1;
  }
  store.commit("layers/CHANGE_BASE_LAYER_OPACITY", opacity);

  // zoom to saved position
  map.flyTo({
    pitch: mapView.bbox.pitch,
    zoom: mapView.bbox.zoom,
    center: mapView.bbox.center
  });

  let promise = new Promise(resolve => {
    map.once("idle", async () => {
      // create views from items
      let added_items = [];
      console.log(mapView.content);
      for (let view_item of mapView.content) {
        let type = view_item.type;
        try {
          switch (type) {
            case "database_layer":
              await DatabaseLayerView.addFromMapViewItem(view_item);
              break;
            case "custom_layer":
              await CustomLayerView.addFromMapViewItem(view_item);
              break;
            case "flows":
              await FlowsView.addFromMapViewItem(view_item);
              break;
            case "network":
              NetworkView.addFromMapViewItem(view_item);
              break;
            default:
              throw new Error("Unknown view item type");
          }
          added_items.push(view_item);
        } catch (e) {
          console.log(e);
          let message = new ViewItemError(view_item.name, type, "load");
          alert({ message, type: "error" });
        }
      }

      // order layers corresponding to views
      let flatten_items = [];
      added_items.forEach(map_view_item => {
        if (map_view_item.layer_options) {
          for (const layer_id in map_view_item.layer_options) {
            let options = map_view_item.layer_options[layer_id];
            if ("order" in options) {
              flatten_items.push({
                layer_id,
                order: options.order
              });
            }
          }
        }
      });
      flatten_items = flatten_items.sort((a, b) => {
        let index_a = a.order;
        let index_b = b.order;

        if (index_a < index_b) {
          return -1;
        } else if (index_b < index_a) {
          return 1;
        } else {
          return 0;
        }
      });
      flatten_items.reverse().forEach(item => {
        let layer = store.getters["layers/getLayer"](item.layer_id);
        store.dispatch("layers/moveLayer", { layer, newIndex: 0 });
      });
      resolve(0);
    });
  });
  return promise;
}

function noAccessMessage(type) {
  let type_readable = i18n
    .t("map_view.item_types." + type)
    .toString()
    .toLowerCase();
  return i18n.t("map_view.errors.access_error", { type: type_readable, mail: KITE_CONTACT });
}

export { newMapViewFromCurrentStore, loadMapView, ViewItemError };
