<!--
  Statistics on flows (OD)
-->

<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :disable-pagination="true"
      hide-default-header
      hide-default-footer
      dense
    >
      <template v-slot:item.text="{ item }">
        {{ $t("statistics.flows." + item.text) }}
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.component("flows-statistics", {
  data: function () {
    return {
      headers: [
        {
          text: "Donnée",
          align: "left",
          sortable: false,
          value: "text"
        },
        { text: "Valeur", value: "value" }
      ]
    };
  },

  computed: {
    ...mapState("flows", ["statistics"]),
    items() {
      let items = Object.keys(this.statistics).map(key => {
        return { text: key, value: this.statistics[key] };
      });
      return items;
    }
  }
});
</script>
