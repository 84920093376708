/* traces.store.js */

export default {
  // State object
  namespaced: true,
  state: {
    agentTypeList: [],
    selectedAgent: null,
    movingAttributes: [],
    selectedMovingAttribute: "--",
    showMovingPoints: true,
    showStaticPoints: true,
    showStoppedPoints: true,
    activityShadow: false,
    checkFollowAgent: false,
    useStaticLayer: true,
    stoppedPointRefresh: 3600,
    currentClock: 0,
    animSpeed: 60,
    run: false,
    pos: [],
    dur: [],
    allPoints: null,
    movingPoints: null,
    stoppedPoints: null,
    mapIcons: [],
    icon_rules: {},
    pathsData: null
  },

  // Getter functions
  getters: {},
  // Actions
  actions: {
    /**
    async setTraceData(
      context,
      { geojson, show_users_local, use_static_layer, stopped_point_refresh, activity_shadow }
    ) {
      // data validation
      validateTrace(geojson);

      // get trace
      let version;
      if (version == undefined) {
        version = 0;
      } else {
        version = parseInt(geojson.version.toString().split(".")[0]);
      }

      // TODO : set paths data ? = raw data ?

      // TODO ? : set use_static_layer, stopped_point_refresh, activity_shadow ?

      // TODO : show trace commands

      // read trace data
      let { datas, moving_attributes, colored_icons } = read_trace_objects(geojson.features, version, show_users_local);

      // TODO : maybe don't change original data
      geojson.features = datas;

      // add the colored icons to the map
      await add_icons_to_map(this.map, colored_icons);

      // update attribute list
      moving_attributes = ["--", "agent_id"].concat(moving_attributes);
      let search_value = "position";
      let search_index = moving_attributes.indexOf(search_value);
      if (search_index >= 0) {
        moving_attributes.splice(search_index, 1);
      }

      // get polygon features
      // TODO : filter geojson data without polygons
      let area = get_area(geojson);

      // add data at beginning and end of traces
      geojson.features = add_first_last_data(geojson.features);

      if (use_static_layer) {
        // TODO filter but without setting map
        geojson = filter_static_objects(geojson, version);
      }

      // check number of moving agents
      if (geojson.features.length > MAX_MOVING_AGENTS) {
        throw new Error(`Trace cannot exceed ${MAX_MOVING_AGENTS} moving agents`);
      }

      // TODO : rest of menu trace

      // zoom on trace
      zoomOnTrace(map, geojson.features);

      // update store
      context.commit("UPDATE_ATTRIBUTE_LIST", moving_attributes);
    },
    */

    setTraceVariable(context, payload) {
      context.commit("SET_VARIABLE", payload);
    },

    addMapIcon(context, icon_id) {
      context.commit("ADD_MAP_ICON", icon_id);
    },

    setIconRule(context, payload) {
      context.commit("SET_ICON_RULE", payload);
    }
  },

  // Mutations
  mutations: {
    SET_VARIABLE(state, payload) {
      state[payload.key] = payload.value;
    },

    ADD_MAP_ICON(state, icon_id) {
      state.mapIcons.push(icon_id);
    },

    SET_ICON_RULE(state, payload) {
      state.icon_rules[payload.key] = payload.value;
    }
  }
};
