<!--
  Read Starling output trace file (geojson)
  Add data to mapbox layers 
-->
<template>
  <div>
    <tellae-menu :title="$t('trace.name')" icon="commute" type="button" plain>
      <v-list dense>
        <tellae-menu-item icon="cloud_upload" title="trace.file" @click="dialog = true" />
        <tellae-menu-item
          icon="moving"
          title="trace.paths.button"
          @click="paths_dialog = true"
          :disabled="agentTypeList.length == 0"
        />
        <tellae-menu-item
          icon="commute"
          title="trace.iconrules"
          @click="icons_dialog = true"
          :disabled="agentTypeList.length == 0"
        />
        <tellae-menu-item
          :icon="checkFollowAgentIcon"
          title="trace.follow"
          @click="checkFollowAgent = !checkFollowAgent"
        />
      </v-list>
    </tellae-menu>
    <kite-upload
      :dialog="dialog"
      @handleReturnReadResult="formatTraceFromFile"
      @handleUpdateDialog="dialog = $event"
      :externalFileInput="simulation_trace"
    >
      <template v-slot:beforeInput>
        <p>
          {{ $t("trace.dialog.intro1") }}
          <a href="https://starling.readthedocs.io/en/latest/run/io.html#visualisation-file">
            {{ $t("trace.dialog.intro2") }}
          </a>
        </p>
        <p>{{ $t("trace.dialog.intro3") }}</p>
      </template>
      <template v-slot:afterInput>
        <p>{{ $t("trace.dialog.choose") }}</p>
        <v-select
          class="mb-n2"
          :items="[3600, 9999999]"
          v-model="stoppedPointRefreshLocal"
          :label="$t('trace.dialog.interval')"
        ></v-select>
        <v-switch class="mb-n2" :label="$t('trace.dialog.users')" v-model="showUsersLocal"></v-switch>
        <v-switch class="mb-n2" :label="$t('trace.dialog.static_layer')" v-model="useStaticLayerLocal"></v-switch>
        <v-switch class="mb-n2" :label="$t('trace.activity')" v-model="activityShadowLocal"></v-switch>
      </template>
    </kite-upload>
    <paths-layers :dialog="paths_dialog" @handleUpdateDialog="paths_dialog = $event"></paths-layers>
    <relative-trace-icons
      :display="icons_dialog"
      :map="map"
      @handleUpdateDialog="icons_dialog = $event"
    ></relative-trace-icons>
  </div>
</template>

<script>
import { parse_json } from "@/io";
import { TABHEADERS, MAX_MOVING_AGENTS } from "@/global";
import KiteUpload from "../base/kite_upload.vue";
import PathsLayers from "./paths_layers.vue";
import Vue from "vue";
import {
  formatTester,
  majVisibility,
  add_first_last_data,
  read_trace_objects,
  add_icons_to_map,
  zoomOnTrace,
  get_area,
  filter_static_objects,
  compute_appear_disappear
} from "@/functions-tools";
import RelativeTraceIcons from "./relative_trace_icons.vue";
import { validateTrace } from "@/validation.ts";
import { mapState, mapActions } from "vuex";
import TellaeMenu from "@/components/base/menu.vue";
import TellaeMenuItem from "@/components/base/menu_item.vue";

export default Vue.component("menu-trace", {
  components: {
    KiteUpload,
    PathsLayers,
    RelativeTraceIcons,
    TellaeMenu,
    TellaeMenuItem
  },

  props: ["icon", "delay", "btn_class", "map"],

  data: function () {
    return {
      tabheaders: TABHEADERS,
      loading: false,
      version: {
        trace: 0
      },
      autozoom: true, // if true automatic zoom to agents on file loading,
      agent_type_count: null,
      dialog: false,
      paths_dialog: false,
      icons_dialog: false,

      // import options
      showUsersLocal: true,
      useStaticLayerLocal: true,
      stoppedPointRefreshLocal: 3600,
      activityShadowLocal: false
    };
  },
  computed: {
    ...mapState("traces", ["useStaticLayer", "stoppedPointRefresh", "run", "agentTypeList"]),
    ...mapState("simulations", ["simulation_trace"]),
    checkFollowAgentIcon: {
      get() {
        return this.checkFollowAgent ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline";
      }
    },
    checkFollowAgent: {
      get() {
        return this.$store.state.traces.checkFollowAgent;
      },
      set(newValue) {
        this.setTraceVariable({ key: "checkFollowAgent", value: newValue });
      }
    },
    showMovingPoints: {
      get() {
        return this.$store.state.traces.showMovingPoints;
      },
      set(newValue) {
        this.setTraceVariable({ key: "showMovingPoints", value: newValue });
      }
    },
    showStaticPoints: {
      get() {
        return this.$store.state.traces.showStaticPoints;
      },
      set(newValue) {
        this.setTraceVariable({ key: "showStaticPoints", value: newValue });
      }
    },
    showStoppedPoints: {
      get() {
        return this.$store.state.traces.showStoppedPoints;
      },
      set(newValue) {
        this.setTraceVariable({ key: "showStoppedPoints", value: newValue });
      }
    }
  },
  methods: {
    ...mapActions(["asyncStart", "asyncEnd"]),
    ...mapActions("traces", ["setTraceVariable"]),
    ...mapActions("layers", ["setLayersVisibility"]),
    // load trace file
    async formatTraceFromFile(readResult) {
      await this.asyncStart({ type: "overlay" });
      const processing = async () => {
        try {
          readResult = await readResult;
          if (readResult.length == 0) {
            throw new Error(this.$t("trace.error.no_file"));
          } else if (readResult.length > 1) {
            throw new Error(this.$t("trace.error.multiple_file"));
          }
          let filename = readResult[0].filename;

          if (!formatTester(filename, ["geojson", "json", "gz"])) {
            throw new Error(this.$t("trace.error.format"));
          }

          let file_text = readResult[0].data;
          let allPoints = parse_json(file_text);

          validateTrace(allPoints);

          // set the agentPathsLayer data
          let paths = JSON.parse(JSON.stringify(allPoints));
          this.$store.state.traces.pathsData = paths;

          // check for input file format
          let formatOK = true;
          this.version.trace = allPoints.version;
          if (this.version.trace == undefined) {
            this.version.trace = 0;
          } else {
            this.version.trace = parseInt(allPoints.version.toString().split(".")[0]);
          }
          // if (this.allPoints.features[0].geometry.type !== "MultiPolygon") {
          //   for (let key of TRACE_INPUT_KEYS[this.version.trace].keys) {
          //     if (!input_keys.includes(key)) {
          //       formatOK = false;
          //     }
          //   }
          // }
          console.log(this.version.trace);
          // run json parsing
          if (!formatOK) {
            this.loading = false;
            throw new Error("Format incorrect");
          } else {
            // show trace controls
            this.$store.commit("UPDATE_UX_DISPLAY", { ux: "animationCommands", value: true });
            this.setTraceVariable({ key: "useStaticLayer", value: this.useStaticLayerLocal });
            this.setTraceVariable({ key: "stoppedPointRefresh", value: this.stoppedPointRefreshLocal });
            this.setTraceVariable({ key: "activityShadow", value: this.activityShadowLocal });

            // read all objects
            let { datas, moving_attributes, colored_icons } = read_trace_objects(
              allPoints.features,
              this.version.trace,
              this.showUsersLocal
            );
            allPoints.features = datas;

            // add the colored icons to the map
            await add_icons_to_map(this.map, colored_icons);

            // update attribute list
            //moving_attributes.unshift("agent_id");
            moving_attributes = ["--", "agent_id"].concat(moving_attributes);
            // remove attribute position
            let search_value = "position";
            let search_index = moving_attributes.indexOf(search_value);
            if (search_index >= 0) {
              moving_attributes.splice(search_index, 1);
            }
            this.setTraceVariable({ key: "movingAttributes", value: moving_attributes });
            console.log("all init pt " + allPoints.features.length);
            // automatic zoom to agents

            zoomOnTrace(this.map, allPoints.features);
            allPoints = get_area(allPoints, this.map);
            allPoints.features = add_first_last_data(allPoints.features);

            if (this.useStaticLayer) {
              allPoints = filter_static_objects(allPoints, this.version.trace, this.map);
            }

            if (allPoints.features.length > MAX_MOVING_AGENTS) {
              throw new Error(`Trace cannot exceed ${MAX_MOVING_AGENTS} moving agents`);
            }

            // get unique points type
            let array = allPoints.features.map(d => d.properties.agent_type);
            this.setTraceVariable({ key: "agentTypeList", value: Array.from(new Set(array)).sort() });

            allPoints.features = compute_appear_disappear(allPoints.features, this.stoppedPointRefresh);

            // read dynamic positions and duration between positions
            let pos = [];
            let dur = [];
            for (let obj = 0; obj < allPoints["features"].length; obj++) {
              pos.push(allPoints["features"][obj]["properties"]["information"]["position"]["values"]);
              dur.push(allPoints["features"][obj]["properties"]["information"]["position"]["timestamps"]);
              // update object num
              allPoints.features[obj].properties.num = obj;
            }
            //
            for (let obj = 0; obj < allPoints["features"].length; obj++) {
              delete allPoints.features[obj].properties.information.position;
            }

            // set points variables
            this.setTraceVariable({ key: "allPoints", value: allPoints });
            this.setTraceVariable({ key: "pos", value: pos });
            this.setTraceVariable({ key: "dur", value: dur });
            this.setLayersVisibility({
              ids: ["area", "staticpoint", "stoppedmovingpoint", "movingpoint"],
              isVisible: true
            });
            // restart the timeline
            this.restartRun2();
            this.$store.commit("simulations/SET_SIMULATION_TRACE", null);
          }
        } catch (e) {
          console.log(e);
          alert({ e, type: "error" });
        } finally {
          await this.asyncEnd({ type: "overlay" });
        }
      };
      setTimeout(processing, 10);
      return false;
    },
    // mise à jour de l'affichage ou non des layers en fonction de la sélection des checkbox
    updateVisibilityTrace(event) {
      console.log(event);
      majVisibility(this.showMovingPoints, "movingpoint", this.map);
      majVisibility(this.showStaticPoints, "staticpoint", this.map);
      majVisibility(this.showStoppedPoints, "stoppedmovingpoint", this.map);
    },
    // init timeline
    restartRun2() {
      this.$store.commit("simulations/INCREMENT_TIMELINE_KEY");
    }
  }
});
</script>
