<template>
  <div>
    <v-data-table
      v-bind="$attrs"
      :headers="headers"
      :items="layerItems"
      disable-pagination
      hide-default-footer
      disable-sort
    >
      <template v-if="layerItems.length > 0" v-slot:body="props">
        <draggable :list="props.items" tag="tbody" @change="tableChange" forceFallback>
          <tr v-for="(view, index) in props.items" :key="index">
            <td style="text-align: center" v-if="!disableDrag">
              <v-icon small class="page__grab-icon"> mdi-arrow-all </v-icon>
            </td>

            <td>
              <v-tooltip slot="append" bottom :disabled="view.layer.getName().length <= max_name_length">
                <template v-slot:activator="{ on }">
                  <v-chip v-on="on" :color="getNameColor(view.layer)" dark>
                    <div v-if="view.layer.isCustomLayer()">
                      <kite-edit-text
                        :text="view.layer.getName()"
                        :display-text="truncateString(view.layer.getName(), max_name_length)"
                        :rules="name_rules"
                        :counter="name_max_size"
                        @save="view.rename($event)"
                      />
                    </div>
                    <div v-else>
                      {{ truncateString(view.layer.getName(), max_name_length) }}
                    </div>
                  </v-chip>
                </template>
                <span> {{ view.layer.getName() }} </span>
              </v-tooltip>
            </td>
            <td style="text-align: center">
              <v-progress-circular
                v-if="view.layer.isLoading()"
                class="mr-2"
                indeterminate
                :rotate="-90"
                :width="2"
                :size="20"
              ></v-progress-circular>
              <v-simple-checkbox
                v-else
                :value="view.isDisplayed()"
                color="#444444"
                on-icon="check_box"
                off-icon="check_box_outline_blank"
                :ripple="false"
                @click="view.updateViewDisplay(!view.layer.isVisible)"
              ></v-simple-checkbox>
            </td>
            <td style="text-align: center" v-if="actions.length > 0">
              <kite-save-button
                v-if="actions.includes('save')"
                @click="saveItem({ view, updater: $event })"
                :disabled="!view.layer.isCustomLayer() || !view.canBeSaved() || view.layer.isLoading()"
              />
              <kite-table-action
                v-if="actions.includes('paint')"
                icon="palette"
                :disabled="!view.layer.editable"
                @click="editItem(view)"
                :tooltip="$t('basic_dialogs.modify')"
              />
              <v-progress-circular
                v-if="actions.includes('download') && downloading == view.layer.id"
                class="mr-2"
                indeterminate
                :rotate="-90"
                :width="2"
                :size="20"
              ></v-progress-circular>
              <kite-table-action
                v-if="actions.includes('download') && downloading !== view.layer.id"
                :disabled="downloading !== null || !view.canBeDownloaded() || view.layer.isLoading()"
                icon="file_download"
                @click="download(view)"
                :tooltip="$t('basic_dialogs.download')"
              />
              <kite-table-action
                v-if="actions.includes('remove')"
                :disabled="view.layer.display_mode == 'builtin'"
                icon="clear"
                @click="view.removeFromStore()"
                :tooltip="$t('basic_dialogs.remove')"
              />
            </td>
          </tr>
        </draggable>
      </template>
      <!-- Empty items text -->
      <template v-slot:no-data>
        {{ $t("map_layers.layer_table.no_data") }}
      </template>
      <!-- Translated headers -->
      <template v-slot:[header_attribute(header_obj)]="{ header }" v-for="header_obj in headers">
        <div v-if="header_obj.text != 'drag'" :key="header_obj.text">
          {{ $t("map_layers.layer_table.headers." + header_obj.text) }}
        </div>
      </template>
    </v-data-table>
    <!-- Attribute edition dialog -->
    <edit-dialog
      :dialog="dialog_edit"
      :layer="editedItem.layer"
      :refreshAttributesAtDisplay="true"
      @handleUpdateDialog="dialog_edit = $event"
    ></edit-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import EditDialog from "./edit_dialog.vue";
import { CATEGORY_COLORS } from "@/kite_layers";
import { mapState, mapActions } from "vuex";
import draggable from "vuedraggable";
import { truncateString } from "@/functions-tools";
import { nameRequired, nameLengthRule, name_max_size } from "@/validation";
import KiteSaveButton from "@/components/projects/button_save.vue";
import { downloadData } from "@/io";

const max_name_length = 30;

export default Vue.component("layer-table", {
  components: {
    EditDialog,
    draggable,
    KiteSaveButton
  },

  props: {
    layerItems: {
      type: Array,
      required: true
    },
    disableDrag: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default() {
        return ["save", "paint", "download", "remove"];
      }
    }
  },

  data: function () {
    return {
      max_name_length,
      editedIndex: null,
      editedItem: {
        layer: {
          editAttributes: {
            color: "#000000",
            size: 1
          },
          editAttributesConstraints: undefined
        }
      },
      dialog_edit: false,
      name_rules: [nameRequired, nameLengthRule],
      name_max_size,
      downloading: null
    };
  },
  computed: {
    ...mapState(["locale"]),
    headers() {
      let headers = [
        { text: "name", value: "name", width: "35%" },
        { text: "display", value: "layer.isVisible", width: "10%", align: "center" }
      ];
      if (!this.disableDrag) {
        headers.unshift({ text: "drag", width: "5%" });
      }
      if (this.actions.length > 0) {
        headers.push({ text: "actions", value: "actions", width: "20%", align: "center" });
      }
      return headers;
    }
  },
  methods: {
    ...mapActions("layers", ["moveLayer", "setLayersVisibility"]),
    truncateString,
    editItem(view) {
      this.editedIndex = this.layerItems.indexOf(view);
      this.editedItem = Object.assign({}, view);
      this.dialog_edit = true;
    },
    header_attribute(header_obj) {
      return "header." + header_obj.value;
    },
    getNameColor(layer) {
      let layerCategory = layer.category;
      if (layerCategory in CATEGORY_COLORS) {
        return CATEGORY_COLORS[layerCategory];
      } else {
        return CATEGORY_COLORS.other;
      }
    },
    tableChange(event) {
      if ("moved" in event) {
        let layer = event.moved.element.layer;
        this.moveLayer({ layer, newIndex: event.moved.newIndex });
      }
    },
    saveItem(arg) {
      let access = this.$whale.runIfHasAccess("LAYERS", () => {
        arg.view.saveToProject(arg.updater);
      }).access;
      if (!access) {
        arg.updater(100);
      }
    },
    async download(view) {
      this.downloading = view.layer.id;
      view
        .downloadView()
        .catch(e => {
          console.log(e);
          alert({ message: this.$t("map_layers.layer_table.errors.download", { error: e.message }), type: "error" });
        })
        .finally(() => {
          this.downloading = null;
        });
    }
  }
});
</script>
