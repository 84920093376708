<template>
  <v-row>
    <v-col md="8">
      <v-select
        v-model="model"
        :items="projectAttributeData"
        @change="$emit('change', $event)"
        :label="$t('project.selector.label')"
        item-text="display_name"
        return-object
        v-bind="$attrs"
      />
    </v-col>
    <v-col v-if="addFileButton">
      <v-file-input id="fileUpload" :accept="addFileExtension" hide-input prepend-icon="" @change="newFileInput" />
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn x-small fab v-on="on" color="primary" @click="document.getElementById('fileUpload').click()">
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </template>
        <span> {{ $t("project.selector.import") }} </span>
      </v-tooltip>
      <whale-upload
        :displayed="false"
        store="projects"
        auto-upload
        :model="project"
        :property="attribute"
        :externalFileInput="file_input"
        @uploadSuccess="projectFileAdded"
      />
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";
import { getBinaryName } from "@/functions-tools";
import WhaleUpload from "@/components/projects/whale_upload.vue";

export default Vue.component("project-data-selector", {
  components: {
    WhaleUpload
  },

  props: ["value", "attribute", "filter", "addFileButton", "addFileExtension"],

  data: function () {
    return {
      document,
      file_input: null
    };
  },

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    projectAttributeData() {
      // get binaries of the attribute
      let data = (this.project[this.attribute] || []).map((item, index) => {
        // add index and name props
        return { ...item, index, display_name: getBinaryName(item, false) };
      });

      // apply eventual filter
      if (this.filter) {
        data = data.filter(obj => {
          return this.filter(obj);
        });
      }
      return data;
    },
    project() {
      return this.$whale.project;
    }
  },

  methods: {
    newFileInput(file) {
      this.file_input = file;
    },
    projectFileAdded(info) {
      // add gtfs to project list if not already in
      let index = this.project[this.attribute].map(item => item.hash).indexOf(info.hash);
      if (index == -1) {
        this.project[this.attribute].push(info);
        index = this.project[this.attribute].length - 1;
      }

      // set selector model
      this.model = this.projectAttributeData[index];

      // signal input change
      this.$emit("change", this.model);
    }
  }
});
</script>
