<!--
  Main network page
-->

<template>
  <div>
    <kite-drawer-content>
      <v-row class="mt-2 ml-2" justify="center">
        <v-col>
          <add-network-dialog />
        </v-col>
        <v-col>
          <road-isochrones />
        </v-col>
      </v-row>
      <v-card class="mt-2 overflow-auto pa-2" elevation="0" height="calc(100vh - 195px)">
        <v-card-text class="pa-0">
          <v-tabs v-model="tab" background-color="primary" class="elevation-2" dark grow hide-slider>
            <v-tab :key="0" :href="`#tab-0`">
              {{ $t("network.networks") }}
            </v-tab>
            <v-tab-item :key="0" :value="'tab-0'">
              <v-data-table
                :headers="headers"
                :items="networkViews"
                :no-data-text="$t('network.table.no_data')"
                disable-pagination
                hide-default-footer
                disable-sort
                :loading="async.gtfsDraw > 0"
              >
                <template v-for="header in headers" v-slot:[`header.${header.value}`]>
                  {{ $t("network.table.headers." + header.text) }}
                </template>
                <template v-slot:[`item.selection`]="{ item }">
                  <v-simple-checkbox
                    :value="item.id == currentNetworkViewId"
                    color="black"
                    on-icon="radio_button_checked"
                    off-icon="radio_button_unchecked"
                    :ripple="false"
                    @click="networkViewSelection(item)"
                  />
                </template>
                <template v-slot:item.name="{ item }">
                  <kite-edit-text
                    :text="item.name"
                    :rules="name_rules"
                    :counter="name_max_size"
                    @save="item.rename($event)"
                  >
                    {{ truncateString(item.name, 34) }}
                  </kite-edit-text>
                </template>
                <template v-slot:item.date="{ item }">
                  {{ item.date.text }}
                </template>
                <template v-slot:item.has_all_stops="{ item }">
                  <v-simple-checkbox
                    v-model="item.has_all_stops"
                    color="black"
                    on-icon="check_box"
                    off-icon="check_box_outline_blank"
                    :ripple="false"
                    :disabled="true"
                  ></v-simple-checkbox>
                </template>
                <template v-slot:item.actions="{ item }">
                  <kite-table-action
                    icon="clear"
                    @click="removeNetworkView(item)"
                    :tooltip="$t('basic_dialogs.remove')"
                  />
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <v-card-text>
          <v-divider />
        </v-card-text>
        <v-card-text>
          <v-expansion-panels multiple>
            <v-expansion-panel :disabled="networkRouteData.statistics.length == 0">
              <v-expansion-panel-header :disable-icon-rotate="true">{{
                $t("flows.plots.title")
              }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <network-plots />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel :disabled="statistics.items.network.length == 0">
              <v-expansion-panel-header :disable-icon-rotate="true">
                {{ $t("statistics.title") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <network-statistics />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </kite-drawer-content>
    <dialog-variants></dialog-variants>
    <pt-isochrones />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { truncateString, parseDate } from "@/functions-tools";
import { nameRequired, nameLengthRule, name_max_size } from "@/validation";
import AddNetworkDialog from "./add_network_dialog.vue";
import DialogVariants from "./dialog_variants.vue";
import PtIsochrones from "./pt_isochrones.vue";
import NetworkStatistics from "./network_statistics.vue";
import NetworkPlots from "./network_plots.vue";
import RoadIsochrones from "./road_isochrones.vue";

export default Vue.component("network-page", {
  components: {
    DialogVariants,
    PtIsochrones,
    NetworkStatistics,
    NetworkPlots,
    AddNetworkDialog,
    RoadIsochrones
  },

  data: function () {
    return {
      tab: 0,
      headers: [
        { value: "selection", text: "selection", align: "center", width: "10%" },
        { value: "name", text: "name" },
        { value: "date", text: "date", width: "15%", align: "center" },
        { value: "has_all_stops", text: "has_all_stops", width: "15%", align: "center" },
        { value: "actions", text: "actions", align: "center", width: "10%" }
      ],
      name_rules: [nameRequired, nameLengthRule],
      name_max_size
    };
  },

  computed: {
    ...mapState(["language", "async"]),
    ...mapState("network", [
      "dialog",
      "networkViews",
      "currentNetworkView",
      "networkRouteData",
      "statistics",
      "isochrones"
    ]),
    currentNetworkViewId() {
      if (this.currentNetworkView) {
        return this.currentNetworkView.id;
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapActions("network", ["selectNetworkview", "removeNetworkView"]),
    truncateString,
    parseDate,
    networkViewSelection(network_view) {
      if (network_view.id == this.currentNetworkViewId) {
        network_view.updateViewDisplay(false);
      } else {
        network_view.updateViewDisplay(true);
      }
    }
  }
});
</script>
