<!--
  Dialog to select route variants (network)
-->

<template>
  <kite-dialog v-model="dialog" persistent no-click-animation>
    <v-card>
      <v-overlay opacity=".9" color="grey lighten-1" absolute :value="async.overlay" z-index="12">
        <div class="d-flex flex-column align-center">
          <h1>{{ $t("visualisation.loading") }}</h1>
          <v-progress-circular :width="6" color="primary" indeterminate />
        </div>
      </v-overlay>
      <v-card-title class="headline grey lighten-2">{{ $t("network.dialog_variants.title") }}</v-card-title>
      <v-card-text>
        <v-data-table
          v-bind="$attrs"
          :headers="headers"
          :items="variantsTable"
          disable-pagination
          hide-default-footer
          class="elevation-2 mt-4 mb-n4"
        >
          <template v-slot:[`item.selected`]="{ item }">
            <v-simple-checkbox
              :value="item == selectedVariants[item.direction_id]"
              color="black"
              on-icon="radio_button_checked"
              off-icon="radio_button_unchecked"
              :ripple="false"
              @click="selectItem(item)"
            ></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancel()"> {{ $t("basic_dialogs.cancel") }}</v-btn>
        <v-btn
          color="primary"
          text
          @click="validate()"
          :loading="async.gtfsRouteData > 0"
          :disabled="selectedVariants[0] == null || selectedVariants[1] == null"
        >
          {{ $t("basic_dialogs.validate") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </kite-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions } from "vuex";

export default Vue.component("dialog-variants", {
  components: {},
  data: function () {
    return {
      // TODO: translate headers using a template and translations in network.dialog_variants
      headers: [
        { text: "Direction", value: "direction_id" },
        { text: "Départ", value: "start_stop_name" },
        { text: "Arrivée", value: "end_stop_name" },
        { text: "# Arrêts", value: "num_stops" },
        { text: "# Courses", value: "num_trips" },
        { text: "Sélection", value: "selected", align: "center" }
      ],
      selectedVariants: {
        0: null,
        1: null
      }
    };
  },
  computed: {
    ...mapState("network", ["dialog", "selectedRouteId", "variantsTable"]),
    ...mapState(["async"]),
    dialog: {
      get() {
        return this.$store.state.network.dialog.variants;
      },
      set(value) {
        this.$store.commit("network/UPDATE_DIALOG", { dialog: "variants", value: value });
      }
    }
  },

  methods: {
    ...mapActions("network", ["selectRouteVariants", "selectRoute"]),
    selectItem(item) {
      this.selectedVariants[item.direction_id] = item;
    },
    cancel() {
      this.selectRoute(null);
      this.selectedVariants = {
        0: null,
        1: null
      };
      this.closeDialog();
    },
    async validate() {
      await this.selectRouteVariants(this.selectedVariants);
      this.closeDialog();
    },
    closeDialog() {
      this.$store.commit("network/UPDATE_DIALOG", { dialog: "variants", value: false });
    }
  }
});
</script>
