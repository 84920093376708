/* capucine_analysis.store.js */

import { getInstance } from "@/api/index";
import {
  computeSectionsPairs,
  DAY_TYPES,
  ALL_DAYS,
  ALL_ROUTES,
  get_route_item,
  routeOneWayName,
  routeReturnName,
  routeTitleSuffixWithDates,
  routeSurveyDate,
  get_route_data_batch_items,
  getDefaultTravelTimeFilter,
  getDefaultTravelTimeBuffers,
  ANALYSIS_KPIS
} from "@/capucine_utils";
import { isEqual } from "lodash";

const KPI_KEYS = ANALYSIS_KPIS.map(item => item.key);

function evaluateFilterModifState(filter, saved_filter, default_filter) {
  let modif_state;
  if (isEqual(filter, saved_filter)) {
    modif_state = "saved";
  } else if (isEqual(filter, default_filter)) {
    modif_state = "default";
  } else {
    modif_state = "changed";
  }
  return modif_state;
}

function convertFilterToDict(filter) {
  return {
    below_median: filter.below_median.activate ? filter.below_median.value : null,
    above_median: filter.above_median.activate ? filter.above_median.value : null,
    number_std: filter.number_std.activate ? filter.number_std.value : null
  };
}

export default {
  // State object
  namespaced: true,
  state: {
    // navigation
    page: "analysis",
    tab: "presentation",
    // available lots and routes to browse
    data_batches_table: {},
    lots: [],
    routes: [],
    route_data_batches: [],
    // current analysis selection
    lot: {},
    route_id: ALL_ROUTES,
    data_batch_id: null,
    day_type: ALL_DAYS,
    // additional data on selection
    lot_description: {
      geojson: {
        type: "FeatureCollection",
        features: []
      },
      plan_urls: [],
      timetable_urls: []
    },
    first_last: false,
    data: {
      buffer_time: null,
      commercial_speed: null,
      commercial_speed_theoretical: null,
      kcc_loss: null,
      robustness: null,
      serpentary: null,
      traffic: null,
      travel_time: null,
      travel_time_points: null,
      vehicle_load: null,
      cumulative_travel_time: null,
      conformity: null,
      conformity_first_last_only: null,
      production: null,
      production_first_last_only: null,
      analysis_synthesis: null
    },
    travel_time: {
      direction: "0",
      section: {
        origin: "BG",
        destination: "TO"
      },
      filter: {
        current_filter: {
          "0": getDefaultTravelTimeFilter("LAVHV"),
          "1": getDefaultTravelTimeFilter("LAVHV")
        },
        route_data_saved_filters: [],
        saved_filter: {
          "0": null,
          "1": null
        }
      },
      buffers: getDefaultTravelTimeBuffers(),
      plots: {
        mesures: null,
        mesures_non: null,
        theo_contractuel: null,
        theo: null,
        realise: null,
        realise_median: null,
        buffered_theo: null,
        buffered_realise: null,
        buffered_std: null,
        buffered_pX: null
      }
    },
    loading: {
      lot: false,
      analysis_synthesis: false,
      data_setup: false,
      kpis: 0,
      buffer_time: false,
      commercial_speed: false,
      kcc_loss: false,
      robustness: false,
      serpentary: false,
      traffic: false,
      travel_time: false,
      vehicle_load: false,
      cumulative_travel_time: false,
      conformity: false,
      conformity_first_last_only: false,
      production: false,
      production_first_last_only: false
    }
  },

  // Getter functions
  getters: {
    route: state => {
      return get_route_item(state.routes, state.route_id, state.data_batch_id);
    },
    day_types: (_, getters) => {
      return getters.route.day_types || DAY_TYPES;
    },
    sections: (_, getters) => {
      return getters.route.sections.order;
    },
    sectionsNames: (_, getters) => {
      return getters.route.sections.names;
    },
    getLotItem: state => id => {
      let corresponding_lots = state.lots.filter(item => {
        return item.id == id;
      });
      if (corresponding_lots.length > 1) {
        throw new Error("Several lots corresponding to the following id : " + id);
      } else if (corresponding_lots.length == 0) {
        throw new Error("No lot corresponding to the following id : " + id);
      } else {
        return corresponding_lots[0];
      }
    },
    segmentsPairs: (_, getters) => {
      return {
        0: computeSectionsPairs(getters.sections, 0),
        1: computeSectionsPairs(getters.sections, 1)
      };
    },
    cumulativeSegmentPairs: (_, getters) => {
      return {
        0: computeSectionsPairs(getters.sections, 0, true),
        1: computeSectionsPairs(getters.sections, 1, true)
      };
    },
    oneWayName: (_, getters) => {
      return routeOneWayName(getters.route);
    },
    returnName: (_, getters) => {
      return routeReturnName(getters.route);
    },
    surveyDate: (_, getters) => {
      return routeSurveyDate(getters.route);
    },
    titleSuffixWithDates: (_, getters) => {
      return routeTitleSuffixWithDates(getters.route);
    },
    // global color mapping { route_id : { color, text_color } }
    routeColors: (state, _, rootState) => {
      // fetch routes of both analysis and simulation stores
      let routes = state.routes.concat(rootState.capucine_simulation.routes);
      let colors = Object.fromEntries(
        routes.map(route => {
          return [
            route.id,
            {
              color: route.color,
              text_color: route.text_color
            }
          ];
        })
      );
      colors.Lot = {
        color: "#FFFFFF",
        text_color: "#000000"
      };
      return colors;
    },
    routeNames: state => {
      let route_names = Object.fromEntries(
        state.routes.map(route_item => {
          return [route_item.id, route_item.name];
        })
      );
      route_names.Lot = state.lot.type;
      return route_names;
    },
    lotGeojson: state => {
      let features = state.lot_description.geojson.features.filter(feature => {
        return feature.properties.data_batch == state.data_batch_id && feature.geometry;
      });

      return {
        type: "FeatureCollection",
        features: features
      };
    },
    currentFilterModifState: (state, getters) => {
      let current_filter = state.travel_time.filter.current_filter[state.travel_time.direction];
      let saved_filter = state.travel_time.filter.saved_filter[state.travel_time.direction];
      let default_filter = getters.defaultTravelTimeFilter;
      return evaluateFilterModifState(current_filter, saved_filter, default_filter);
    },
    defaultTravelTimeFilter: state => {
      return getDefaultTravelTimeFilter(state.day_type);
    }
  },
  // Actions
  actions: {
    /**
     * Get the summary of available lots and their corresponding routes.
     * @param context
     */
    async getLotSummary(context) {
      let whale = getInstance();
      let lots = await whale.getCapucineLotsSummary();
      context.commit("SET_LOT_SUMMARY", lots.result);
    },

    async getDataBatches(context) {
      let whale = getInstance();
      let response = await whale.getCapucineDataBatches();
      let data_batches_dict = {};
      for (let data_batch of response.result) {
        data_batches_dict[data_batch.id] = data_batch;
      }
      context.commit("SET_DATA_BATCHES_TABLE", data_batches_dict);
    },

    /**
     * Change the selected lot
     * @param context
     * @param lot
     * @returns boolean indicating change success
     */
    async changeLot(context, lot) {
      let whale = getInstance();

      let lot_information;
      // set loader on
      context.commit("SET_LOADING", { kpi: "lot", value: true });
      try {
        // if lot id is provided, fetch lot item
        if (typeof lot == "string") {
          lot = context.getters.getLotItem(lot);
        } else if (lot.id == undefined) {
          console.log("No id found when changing lot");
          throw new Error();
        }

        // get general information
        lot_information = await whale.getCapucineLotInfo(lot.id, true);
      } catch (e) {
        let message = "Erreur lors de la sélection du réseau / lot";
        alert({ message, type: "error" });
        return false;
      } finally {
        // set loader off
        context.commit("SET_LOADING", { kpi: "lot", value: false });
      }

      // update store variables
      context.commit("CHANGE_LOT", {
        lot,
        routes: lot_information.routes,
        description: lot_information.description
      });

      // save lot in user preferences
      whale.updateCapucinePreferences({ analysis: { lot: lot.id } });

      // change the route to ALL_ROUTES
      context.dispatch("changeRoute", ALL_ROUTES);

      // get the KPIs synthesis for the lot
      context.dispatch("getKpiSynthesis");

      // change tab to presentation
      context.dispatch("setTab", "presentation");

      return true;
    },

    /**
     * Change the selected route
     * @param context
     * @param route
     */
    async changeRoute(context, route_id) {
      context.commit("CHANGE_ROUTE", route_id);
      context.dispatch("routeDataHaschanged");
    },

    /**
     * Change the selected data batch
     * @param context
     * @param data_batch_id
     */
    changeDataBatch(context, data_batch_id) {
      console.log("data batch id");
      console.log(data_batch_id);
      context.commit("CHANGE_DATA_BATCH", data_batch_id);

      context.dispatch("routeDataHaschanged");
    },

    async routeDataHaschanged(context) {
      let route_id = context.state.route_id;
      let route = context.getters.route;

      // if the route is ALL_ROUTES, set the day_type to ALL_DAYS
      if (route_id == ALL_ROUTES) {
        context.dispatch("changeDayType", ALL_DAYS);
      } else {
        // get saved travel time filters for selected route data
        await context.dispatch("getRouteDataTravelTimeFilters");

        // check if current day type available for new route, otherwise set to ALL_DAYS
        let day_types = route.day_types;
        if (day_types && !day_types.includes(context.state.day_type)) {
          context.dispatch("changeDayType", ALL_DAYS);
        } else {
          // no need to update day type, just update content
          context.dispatch("displayedDataUpdate");
        }
      }
    },

    /**
     * Change selected day type
     * @param context
     * @param day_type
     */
    changeDayType(context, day_type) {
      // update day type
      context.commit("CHANGE_DAY_TYPE", { day_type });

      // update analysis KPIs
      context.dispatch("displayedDataUpdate");
    },

    displayedDataUpdate(context) {
      if (context.state.day_type != ALL_DAYS) {
        // reset sections OD to terminus
        let sections = context.getters.sections;
        context.commit("SET_TRAVEL_TIME_SECTION", { origin: sections[0], destination: sections[sections.length - 1] });

        // update travel time filters from database saves
        context.dispatch("updateSavedFilter");

        // update KPIs
        context.dispatch("getAnalysisKpis");
      }
    },

    setTravelTimeFilter(context, { direction, filter }) {
      context.commit("SET_TRAVEL_TIME_FILTER", { direction, filter });
    },

    /**
     * Get the
     * @param context
     */
    async getRouteDataTravelTimeFilters(context) {
      let whale = getInstance();
      let filters = [];
      try {
        filters = await whale.getTravelTimeFilters(context.getters.route.route_data_id);
      } catch (e) {
        console.log(e);
        alert({
          message: "Une erreur est survenue lors de la récupération des filtres sauvgardés",
          type: "error"
        });
      }
      context.commit("SET_ROUTE_DATA_SAVED_FILTERS", filters);
    },

    async saveTravelTimeFilter(context, filter) {
      let whale = getInstance();
      let success = false;
      try {
        // add or update database row for this context
        await whale.saveTravelTimeFilter(
          context.getters.route.route_data_id,
          context.state.day_type,
          context.state.travel_time.direction,
          context.state.travel_time.section.origin,
          context.state.travel_time.section.destination,
          filter
        );

        // update list of saved travel time filters
        await context.dispatch("getRouteDataTravelTimeFilters");

        // update saved filter for current context
        context.dispatch("updateSavedFilter");

        alert({
          message: "Sauvegarde des filtres effectuée",
          type: "success"
        });
        success = true;
      } catch (e) {
        alert({
          message: "Erreur lors de la sauvegarde des filtres",
          type: "error"
        });
      }
      return success;
    },

    updateSavedFilter(context, set_filter = true) {
      let day_type = context.state.day_type;
      let origin_stop = context.state.travel_time.section.origin;
      let destination_stop = context.state.travel_time.section.destination;

      for (let direction of ["0", "1"]) {
        let relevant_saved_filters = context.state.travel_time.filter.route_data_saved_filters.filter(save => {
          return (
            save.direction == direction &&
            save.day_type == day_type &&
            save.origin_stop == origin_stop &&
            save.destination_stop == destination_stop
          );
        });

        let saved_filter = null;
        if (relevant_saved_filters.length == 0) {
          saved_filter = null;
        } else if (relevant_saved_filters.length == 1) {
          saved_filter = relevant_saved_filters[0].filter;
        } else {
          console.log("Several saved filters were found in direction " + direction);
          alert({
            message: "Une erreur est survenue",
            type: "error"
          });
        }
        // update saved filter for this direction
        context.commit("SET_SAVED_FILTER", { direction, filter: saved_filter });

        // if asked, update current filter for this direction
        if (set_filter) {
          // new filter is saved filter if one is available, otherwhise default
          let new_filter = saved_filter || context.getters.defaultTravelTimeFilter;

          context.dispatch("setTravelTimeFilter", { direction, filter: new_filter });
        }
      }
    },

    /**
     * Get the all analysis KPIs
     * @param context
     */
    getAnalysisKpis(context) {
      let route_data = context.getters.route.route_data_id;
      let day_type = context.state.day_type;
      console.log("Fetch KPI data from Capucine-API: route_data=" + route_data + ", day_type=" + day_type);

      let whale = getInstance();

      // evaluate if KPIs get should be called with specific travel time params
      let filters_by_direction = context.state.travel_time.filter.current_filter;
      let default_filter_converted = convertFilterToDict(context.getters.defaultTravelTimeFilter);
      let travel_time_params;
      if (
        isEqual(convertFilterToDict(filters_by_direction["0"]), default_filter_converted) &&
        isEqual(convertFilterToDict(filters_by_direction["1"]), default_filter_converted)
      ) {
        // if both direction filters are default, don't provide travel time params
        travel_time_params = undefined;
      } else {
        let tt_store = context.state.travel_time;
        // otherwise, provide filter and buffer params (origin and destination stay default)
        travel_time_params = {
          filter_params: {
            "0": convertFilterToDict(tt_store.filter.current_filter["0"]),
            "1": convertFilterToDict(tt_store.filter.current_filter["1"])
          },
          optimal_buffer_params: tt_store.buffers
        };
      }

      // get analysis KPIs
      let response = whale.getCapucineKpis(route_data, day_type, travel_time_params);

      // read KPI data from response
      let kpis = [
        "kcc_loss",
        "buffer_time",
        "robustness",
        "commercial_speed",
        "commercial_speed_theoretical",
        "cumulative_travel_time",
        "conformity",
        "conformity_first_last_only",
        "production",
        "production_first_last_only",
        "traffic",
        "vehicle_load",
        "serpentary",
        "travel_time",
        "travel_time_points"
      ];
      context.dispatch("capucineKpiCallback", {
        kpis,
        response
      });
    },

    /**
     * Get the travel time KPI
     * @param context
     */
    getTravelTime(context) {
      let route_data = context.getters.route.route_data_id;
      let day_type = context.state.day_type;
      let whale = getInstance();

      let tt_store = context.state.travel_time;

      let response = whale.getCapucineTravelTime(route_data, day_type, {
        origin: tt_store.section.origin,
        destination: tt_store.section.destination,
        filter_params: {
          "0": convertFilterToDict(tt_store.filter.current_filter["0"]),
          "1": convertFilterToDict(tt_store.filter.current_filter["1"])
        },
        optimal_buffer_params: tt_store.buffers
      });

      context.dispatch("capucineKpiCallback", {
        kpis: ["travel_time", "travel_time_points"],
        response
      });
    },

    /**
     * Process the Capucine API response containing KPIs data.
     * Set loading states of the KPIs, and call the callback function on the results (default is to set the KPI data).
     * @param context
     * @param param1
     */
    capucineKpiCallback(
      context,
      {
        kpis,
        response,
        callback = (kpi, result) => {
          context.commit("SET_KPI_DATA", { key: kpi, value: result });
        }
      }
    ) {
      if (!Array.isArray(kpis)) {
        kpis = [kpis];
      }
      for (let i = 0; i < kpis.length; i++) {
        let kpi = kpis[i];
        context.commit("SET_LOADING", { kpi, value: true });
        response
          .then(result => {
            callback(kpi, result[kpi]);
          })
          .catch(e => {
            console.log(e);
          })
          .finally(() => {
            context.commit("SET_LOADING", { kpi, value: false });
          });
      }
    },

    /**
     * Get the synthesis KPIs (lot and route levels)
     * @param context
     */
    async getKpiSynthesis(context) {
      let whale = getInstance();
      let lot = context.state.lot.id;
      console.log("Fetch synthesis data from Capucine-API: lot=" + lot);

      context.commit("SET_LOADING", { kpi: "analysis_synthesis", value: true });
      try {
        let synthesis = await whale.getCapucineSynthesis(lot);
        context.commit("SET_KPI_DATA", { key: "analysis_synthesis", value: synthesis });
      } catch {
      } finally {
        context.commit("SET_LOADING", { kpi: "analysis_synthesis", value: false });
      }
    },

    setPage(context, page) {
      context.commit("SET_PAGE", page);
    },

    setTab(context, tab) {
      context.commit("SET_TAB", tab);
    }
  },

  // Mutations
  mutations: {
    SET_KPI_DATA(state, payload) {
      state.data[payload.key] = payload.value;
    },
    SET_LOADING(state, payload) {
      state.loading[payload.kpi] = payload.value;
      // count total of loading kpis
      if (KPI_KEYS.includes(payload.kpi)) {
        if (payload.value) {
          state.loading.kpis += 1;
        } else {
          state.loading.kpis -= 1;
        }
      }
    },
    SET_PAGE(state, page) {
      state.page = page;
    },
    SET_TAB(state, tab) {
      state.tab = tab;
    },
    SET_DATA_BATCHES_TABLE(state, data_batches) {
      state.data_batches_table = data_batches;
    },
    CHANGE_LOT(state, { lot, routes, description }) {
      // set new lot
      state.lot = lot;

      // update list of routes
      state.routes = routes;

      // set lot description (geojson, plan and timetable URLs)
      if (description) {
        state.lot_description = description;
      } else {
        state.lot_description = {
          timetable_urls: {},
          plan_urls: {}
        };
      }
    },
    CHANGE_ROUTE(state, route_id) {
      // set route id
      state.route_id = route_id;

      // get list of available data batches
      let data_batches = get_route_data_batch_items(state.routes, route_id, state.lot.data_batches);

      // set current date batches in store
      state.route_data_batches = data_batches;

      // update selected data batch
      if (!data_batches.map(el => el.id).includes(state.data_batch_id)) {
        state.data_batch_id = data_batches[0].id;
      }
    },
    CHANGE_DATA_BATCH(state, data_batch_id) {
      state.data_batch_id = data_batch_id;
    },
    CHANGE_DAY_TYPE(state, { day_type }) {
      state.day_type = day_type;
    },
    SET_LOT_SUMMARY(state, lots) {
      state.lots = lots;
    },
    SET_DATA_BATCHES(state, data_batches) {
      state.data_batches = data_batches;
    },
    SET_TRAVEL_TIME_DIRECTION(state, direction) {
      state.travel_time.direction = direction;
    },
    SET_TRAVEL_TIME_SECTION(state, { origin, destination }) {
      if (origin !== undefined) {
        state.travel_time.section.origin = origin;
      }
      if (destination !== undefined) {
        state.travel_time.section.destination = destination;
      }
    },
    SET_ROUTE_DATA_SAVED_FILTERS(state, filters) {
      state.travel_time.filter.route_data_saved_filters = filters;
    },
    SET_TRAVEL_TIME_FILTER(state, { direction, filter }) {
      state.travel_time.filter.current_filter[direction] = filter;
    },
    SET_SAVED_FILTER(state, { direction, filter }) {
      state.travel_time.filter.saved_filter[direction] = filter;
    },
    SET_TRAVEL_TIME_BUFFERS(state, buffers) {
      state.travel_time.buffers = buffers;
    },
    SET_FIRST_LAST(state, value) {
      state.first_last = value;
    },
    SET_TRAVEL_TIME_PLOT(state, payload) {
      for (let plot_key in payload) {
        state.travel_time.plots[plot_key] = payload[plot_key];
      }
    }
  }
};
