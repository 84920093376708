<!--
  Data table with kcc loss 
-->

<template>
  <kpi-card title="Pertes KCC" :suffix="titleSuffixWithDates" :subtitle="subtitle">
    <div align="center">
      <capucine-table
        :headers="headers"
        :items="data"
        :loading="loading"
        card-width="60vh"
        :exportFileName="export_table_filename"
      >
      </capucine-table>
    </div>
  </kpi-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { SUBTITLES } from "@/capucine_utils";
import AnalysisKpiMixin from "./analysis_kpi_mixin.vue";

export default Vue.component("kcc-loss", {
  mixins: [AnalysisKpiMixin],
  data: () => ({
    kpi_name: "kcc_loss",
    headers: [
      { text: "KCC théorique (km)", value: "distance_théorique", align: "center" },
      { text: "KCC réalisé (km)", value: "distance_réalisé", align: "center" },
      { text: "Réalisé - Théorique (km)", value: "kilomètres", align: "center" },
      { text: "Réalisé - Théorique (%)", value: "pourcentage", align: "center", format: "%" }
    ]
  }),

  computed: {
    subtitle() {
      return SUBTITLES.kcc_loss + " Les valeurs sont des moyennes jounalières.";
    }
  }
});
</script>
