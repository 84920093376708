<!--
Persistent dialog with no overlay that sits on the left of the map
-->

<template>
  <v-tooltip slot="append" bottom :disabled="!tooltip">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" @click="$emit('click')" v-bind="$attrs" color="#444444"> {{ icon }} </v-icon>
    </template>
    <span> {{ tooltip }} </span>
  </v-tooltip>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.component("kite-table-action", {
  components: {},
  props: {
    icon: {
      type: String,
      required: true
    },
    tooltip: String
  },

  data() {
    return {};
  },
  methods: {}
});
</script>
