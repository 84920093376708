<!--
  Component representing a data table and its pagination.
-->

<template>
  <v-card min-height="90vh">
    <kite-card-title :title="$t('map_view.manager.title')" :tooltip="$t('map_view.manager.tooltip')" />
    <v-card-text class="pt-5">
      <v-btn color="primary" rounded large right dark @click="newMap">
        <v-icon left>mdi-plus</v-icon>{{ $t("map_view.manager.save") }}
      </v-btn>
    </v-card-text>
    <v-card-text>
      <v-card>
        <v-expand-transition>
          <div v-if="!focused_map_view">
            <v-data-table
              :headers="headers"
              :items="mapsTable"
              :items-per-page="-1"
              :no-data-text="'Pas de données'"
              hide-default-footer
              :loading="async.mapView > 0"
              sort-by="_creationDate"
              :sort-desc="true"
            >
              <template v-for="header in headers" v-slot:[`header.${header.value}`]>
                {{ $t("basic_headers." + header.text) }}
              </template>
              <template v-slot:[`item.name`]="props">
                <v-edit-dialog @open="newName = props.item.name" @save="saveName(props.item)">
                  {{ props.item.name }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="newName"
                      :rules="[nameRule]"
                      :label="$t('flows.manager.table.edit_name')"
                      :hint="$t('flows.manager.table.edit_confirm')"
                      single-line
                      class="pa-3"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item._creationDate`]="{ item }">
                {{ new Date(item._creationDate).toLocaleString(language, { dateStyle: "short" }) }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <kite-table-action icon="launch" @click="loadMap(item)" :tooltip="$t('basic_dialogs.load')" />
                <kite-table-action
                  icon="zoom_in"
                  @click="focusMap(item)"
                  tooltip="Information"
                  :loading="loading_focused"
                />
                <kite-table-action
                  icon="share"
                  :tooltip="$t('basic_dialogs.share')"
                  @click="shareMap(item)"
                  :loading="loading_share"
                />
                <kite-delete-button
                  @confirmDelete="removeMap(item)"
                  :confirm-text="$t('basic_dialogs.confirm_delete_map')"
                />
              </template>
            </v-data-table>
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <v-card v-if="focused_map_view">
            <v-card-title>
              {{ truncateString(focused_map_view.name, 40) }}
              <kite-table-action
                right
                icon="launch"
                @click="loadMap(focused_map_view)"
                :tooltip="$t('basic_dialogs.load')"
              />
              <v-spacer />
              <v-btn @click="focused_map_view = null" icon class="hidden-xs-only ml-7">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-subtitle>
              {{
                focused_map_view._creatorUser.displayName +
                ", " +
                new Date(focused_map_view._creationDate).toLocaleString(language, {
                  dateStyle: "short",
                  timeStyle: "short"
                })
              }}
            </v-card-subtitle>
            <v-card-text>
              <map-view-recap :map-view="focused_map_view" />
            </v-card-text>
          </v-card>
        </v-expand-transition>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { newMapViewFromCurrentStore, loadMapView } from "@/map_view";
import { truncateString } from "@/functions-tools";
import MapViewRecap from "./map_view_recap.vue";

export default Vue.component("map-manager", {
  components: {
    MapViewRecap
  },

  props: {},

  data: function () {
    return {
      newName: "",
      headers: [
        {
          text: "name",
          align: "start",
          value: "name",
          width: "30%"
        },
        {
          text: "creator",
          align: "start",
          value: "_creatorUser.displayName",
          width: "30%"
        },
        {
          text: "date",
          align: "center",
          value: "_creationDate",
          width: "20%"
        },
        { text: "actions", value: "actions", align: "center", width: "20%", sortable: false }
      ],
      loading_focused: false,
      loading_share: false,
      focused_map_view: null
    };
  },
  computed: {
    ...mapState(["language", "async"]),
    mapsTable() {
      return this.$whale.project._mapViews;
    }
  },
  methods: {
    ...mapActions(["asyncStart", "asyncEnd"]),
    truncateString,
    focusMap(item) {
      this.loading_focused = true;
      this.$whale
        .getMapView(item.uuid)
        .then(map_view => {
          this.focused_map_view = map_view;
        })
        .catch(() => {
          let message = this.$t("map_view.errors.load_error");
          alert({ message, type: "error" });
        })
        .finally(() => {
          this.loading_focused = false;
        });
    },
    shareMap(map_view) {
      this.loading_share = true;
      // restrain access ?
      // call whale to get share token
      this.$whale
        .shareMapView(map_view.uuid)
        .then(async token => {
          token = await token.text();
          // TODO : do better for fetching base url
          let url = window.location.href.replace("/#/", "") + `?preset=${token}`;
          // put url in clipboard and alert user
          navigator.clipboard.writeText(url);
          let message = this.$t("map_view.share.copied");
          alert({ message, type: "success" });
        })
        .finally(() => {
          this.loading_share = false;
        });
    },
    async newMap() {
      let new_map_view = await newMapViewFromCurrentStore(false);
      this.$emit("newMapView", new_map_view);
    },
    removeMap(item) {
      this.asyncStart("mapView");
      this.$whale
        .deleteMapView(item.uuid)
        .then(() => {
          const index = this.mapsTable.indexOf(item);
          if (index > -1) {
            this.mapsTable.splice(index, 1); // 2nd parameter means remove one item only
            this.$whale.project._mapViews = this.mapsTable;
          } else {
            throw new Error("Cannot find deleted map");
          }
        })
        .catch(() => {
          let message = this.$t("map_view.errors.delete_error");
          alert({ message, type: "error" });
        })
        .finally(() => {
          this.asyncEnd("mapView");
        });
    },
    async loadMap(item) {
      this.$whale
        .getMapView(item.uuid)
        .then(map_view => {
          loadMapView(map_view);
          this.closeDialog();
        })
        .catch(() => {
          let message = this.$t("map_view.errors.load_error");
          alert({ message, type: "error" });
        });
    },
    saveName(item) {
      this.$whale
        .updateMapView(item.uuid, { name: this.newName })
        .then(() => {
          item.name = this.newName;
        })
        .catch(() => {
          let message = this.$t("map_view.errors.renaming_error");
          alert({ message, type: "error" });
        });
    },
    nameRule(name) {
      let response = name.length < 30;
      return response;
    },
    closeDialog() {
      this.$emit("updateMenuDialog", false);
    }
  }
});
</script>
